/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';

import logo from '../../../assets/logo.svg';

import styles from './PageLoader.module.css';

const PageLoader: React.FC = () => {
  return (
    <div className={styles.wrapper}>
      <img className={styles.logo} src={logo} alt="Company logo" data-testid="company-logo" />
    </div>
  );
};

export default PageLoader;
