import { ChartOptions } from 'chart.js';
import deepmerge from 'deepmerge';

import { getCssVariable } from './style';

export const createChartOptions = (customChartOptions?: ChartOptions): ChartOptions => {
  const colorContrast7 = getCssVariable('--contrast-7');
  const colorContrast8 = getCssVariable('--contrast-8');
  const fontFamily = getCssVariable('--font-family');

  const chartOptions: ChartOptions = {
    defaultColor: colorContrast7,
    legend: {
      position: 'bottom',
      labels: {
        usePointStyle: true,
        boxWidth: 8,
        fontSize: 13,
        padding: 20,
      },
    },
    tooltips: {
      titleFontFamily: fontFamily,
      footerFontFamily: fontFamily,
      bodyFontFamily: fontFamily,
      backgroundColor: colorContrast8,
      xPadding: 12,
      yPadding: 12,
      cornerRadius: 4,
      caretSize: 0,
      caretPadding: 15,
      footerMarginTop: 0,
      titleMarginBottom: 0,
      callbacks: {
        label: () => '',
      },
    },
    scale: {
      pointLabels: {
        fontSize: 11,
        fontFamily,
      },
    },
    title: {
      fontColor: colorContrast8,
      fontFamily,
      fontStyle: 'normal',
      fontSize: 16,
    },
  };

  return customChartOptions ? deepmerge(chartOptions, customChartOptions) : chartOptions;
};
