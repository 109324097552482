import { FormElement, QdaSampleResult } from '@bloodhound/common/dist/models';
import { QdaSample } from '@bloodhound/common/dist/models/sensoryTest';
import { ChartData, ChartDataSets } from 'chart.js';

import { average } from 'utils/math/basic';
import { getCssVariable } from 'utils/style';
import { isLineScaleFormElement } from 'utils/typeGuards';

export const getSampleProgress = (sampleId: number, sampleResults: QdaSampleResult[]) => {
  let completedCount = 0;
  let inProgressCount = 0;

  sampleResults.forEach((sampleResult) => {
    if (sampleResult.sampleId !== sampleId) {
      return;
    }
    if (sampleResult.isCompleted) {
      completedCount += 1;
      return;
    }
    if (sampleResult.progressRatio > 0 && !sampleResult.isCompleted) {
      inProgressCount += 1;
      return;
    }
  });

  return {
    inProgress: inProgressCount,
    completed: completedCount,
  };
};

export const getOuterValueBoundaries = (formElements: FormElement[]) => {
  return formElements
    .filter(isLineScaleFormElement)
    .reduce<{ min: number | undefined; max: number | undefined }>(
      (boundaries, lineScaleFormElement) => ({
        min:
          boundaries.min === undefined ||
          lineScaleFormElement.valueBoundaries.minimum < boundaries.min
            ? lineScaleFormElement.valueBoundaries.minimum
            : boundaries.min,
        max:
          boundaries.max === undefined ||
          lineScaleFormElement.valueBoundaries.maximum > boundaries.max
            ? lineScaleFormElement.valueBoundaries.maximum
            : boundaries.max,
      }),
      { min: undefined, max: undefined },
    );
};

const createDataSetStyles = (sampleIndex: number): ChartDataSets => {
  const sampleColor = getCssVariable(`--qda-sample-${sampleIndex + 1}-2`);

  return {
    backgroundColor: 'transparent',
    borderColor: sampleColor,
    pointRadius: 0,
    pointHitRadius: 5,
    pointHoverBorderWidth: 4,
    pointHoverBorderColor: sampleColor,
    pointBackgroundColor: sampleColor,
    pointHoverBackgroundColor: sampleColor,
    borderJoinStyle: 'round',
    borderCapStyle: 'round',
  };
};

type ResultParameterValueObject = {
  [parameterId: string]: number[];
};

export const createLineScaleRadarChartData = (
  formElements: FormElement[],
  samples: QdaSample[],
  sampleResults: QdaSampleResult[],
): ChartData => {
  const lineScaleParameters = formElements.filter((parameter) => parameter.type === 'line');
  const labels = lineScaleParameters.map((parameter) => parameter.dataLabel || parameter.name);
  const parameterIds = lineScaleParameters.map((parameter) => parameter.id);

  const datasets = samples.map<ChartDataSets>((sample, index) => {
    const parameterResults: ResultParameterValueObject = {};

    sampleResults
      .filter((sampleResult) => sampleResult.sampleId === sample.id)
      .forEach((sampleResult) => {
        sampleResult.formElementResults
          .filter((result) => result.type === 'line')
          .forEach((result) => {
            if (!parameterResults[result.formElementId]) {
              parameterResults[result.formElementId] = [result.value as number];
            } else {
              parameterResults[result.formElementId].push(result.value as number);
            }
          });
      });

    const parameterValueAverages = parameterIds.map((id) => {
      if (!parameterResults[id]) {
        return undefined;
      }

      const rawAverage = average(parameterResults[id]);
      return Math.round(rawAverage * 100) / 100;
    });

    return {
      data: parameterValueAverages,
      label: sample.name,
      ...createDataSetStyles(index),
    };
  });

  return {
    labels,
    datasets,
  };
};
