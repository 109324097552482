import React from 'react';
import { SensoryTestType } from '@bloodhound/common/dist/models/sensoryTest';
import clsx from 'clsx';

import styles from './SampleIcon.module.css';

type Props = {
  id: number | string;
  sensoryTestType: SensoryTestType;
  index: number;
  className?: string;
};

const SampleIcon: React.FC<Props> = ({ id, sensoryTestType, index, className }: Props) => {
  const containerStyles = clsx(styles.sampleIconContainer, className);

  return (
    <div
      className={containerStyles}
      style={{ backgroundColor: `var(--${sensoryTestType}-sample-${index + 1}-1)` }}
    >
      <span
        className={styles.sampleId}
        style={{ color: `var(--${sensoryTestType}-sample-${index + 1}-2)` }}
      >
        {id}
      </span>
    </div>
  );
};

export default SampleIcon;
