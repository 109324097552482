import React from 'react';
import { Link } from 'react-router-dom';
import { Form, Formik, FormikProps } from 'formik';
import { FormField, Input } from 'ventura';
import Button from 'ventura/dist/components/Button/Button';
import * as Yup from 'yup';

import { FormErrors } from 'components/molecules';
import { useAuthentication } from 'services/userService';
import logo from '../../assets/logo.svg';

import styles from './Login.module.css';

type FormValues = {
  emailAddress: string;
  password: string;
};

const formValidationSchema = Yup.object().shape<FormValues>({
  emailAddress: Yup.string().label('email address').email().required(),
  password: Yup.string().label('password').required(),
});

const defaultFormValues: FormValues = {
  emailAddress: '',
  password: '',
};

const Login: React.FC = () => {
  const {
    signIn,
    signInWithGoogle,
    error: firebaseError,
    isLoading: isAuthLoading,
  } = useAuthentication();
  const [method, setMethod] = React.useState<'emailAndPassword' | 'google'>();

  const handleSignIn = async (formValues: FormValues) => {
    setMethod('emailAndPassword');
    await signIn(formValues.emailAddress, formValues.password);
  };

  const handleGoogleSignIn = async () => {
    setMethod('google');
    await signInWithGoogle();
  };

  return (
    <div className={styles.page}>
      <Formik
        initialValues={defaultFormValues}
        onSubmit={handleSignIn}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {({ values, errors, handleChange, handleBlur, isSubmitting }: FormikProps<FormValues>) => (
          <Form className={styles.form}>
            <img className={styles.logo} src={logo} alt="Company logo" />
            <div className={styles.inputFields}>
              <FormErrors messages={firebaseError ? [firebaseError.message] : []} />
              <FormField errorMessage={errors.emailAddress}>
                <Input
                  name="emailAddress"
                  placeholder="Email address"
                  type="email"
                  value={values.emailAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(errors.emailAddress)}
                />
              </FormField>
              <FormField errorMessage={errors.password}>
                <Input
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={values.password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  isInvalid={Boolean(errors.password)}
                />
              </FormField>
            </div>
            <div className={styles.loginAndForgotPasswordContainer}>
              {/* TODO Forget password */}
              {/* <Link href="/" color="neutral">
                Forgot Password
              </Link> */}
              <Button
                size="large"
                type="secondary"
                name="login"
                className={styles.loginButton}
                isLoading={isSubmitting || (isAuthLoading && method === 'emailAndPassword')}
                htmlType="submit"
              >
                Sign in with email
              </Button>
              <hr className={styles.spacer} />
              <Button
                size="large"
                className={styles.googleButton}
                onClick={handleGoogleSignIn}
                isLoading={isAuthLoading && method === 'google'}
              >
                <img className={styles.googleIcon} src="/googleIcon.png" alt="Google" />
                Sign in with Google
              </Button>
            </div>
            <div className={styles.registerContainer}>
              <p className={styles.registerText}>
                Don't have an account yet?{' '}
                <Link to="/register" color="neutral">
                  Register here
                </Link>
              </p>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Login;
