import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './PageLayoutMain.module.css';
type Props = {
  className?: string;
  children?: ReactNode;
};

const PageLayoutMain: React.FC<Props> = ({ className, children }: Props) => {
  const allClassNames = clsx(styles.main, className);
  return (
    <section className={allClassNames}>
      <div className={styles.content}>{children}</div>
    </section>
  );
};

export default PageLayoutMain;
