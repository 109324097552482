import React from 'react';
import { Tag, Workspace } from '@bloodhound/common/dist/models';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as uuid from 'uuid';
import { Button, FormField, Input, Modal, Radio } from 'ventura';
import * as Yup from 'yup';

import { FormErrors } from 'components/molecules';
import { useWorkspaceEditor } from 'services/workspaceService';
import { TagColors } from 'utils/workspace';

import styles from './SensoryTestTagCreationDialog.module.css';

interface Props {
  isOpen: boolean;
  onClose: (id?: string) => void;
  workspace: any;
}

type FormValues = {
  color: string;
  name: string;
};

const formValidationSchema = Yup.object().shape<FormValues>({
  color: Yup.string().label('Color').required(),
  name: Yup.string().label('Name').required(),
});

const defaultFormValues: FormValues = {
  color: '',
  name: '',
};

const SensoryTestTagCreationDialog: React.FC<Props> = ({ isOpen, onClose, workspace }: Props) => {
  const { editWorkspace, error } = useWorkspaceEditor();

  const setColor = (color: string, formik: FormikProps<FormValues>) => {
    formik.values.color = color;
  };
  const handleCreateTag = async (
    formValues: FormValues,
    formikHelpers: FormikHelpers<FormValues>,
  ) => {
    const newTag: Tag = {
      id: uuid.v4(),
      name: formValues.name,
      color: formValues.color,
    };

    var tags = workspace.tags;
    if (tags === undefined) {
      tags = [];
    }

    tags.push(newTag);
    const updatedWorkspace: Workspace = {
      ...workspace,
      tags: tags,
    };

    const updated = await editWorkspace(updatedWorkspace);
    if (updated) {
      formikHelpers.resetForm();
      onClose(newTag.id);
    }
  };

  const handleCreateTagCancel = (resetForm: () => void) => {
    resetForm();
    onClose();
  };

  const firebaseErrors = error ? [error.message] : [];

  const MemoizedRadioItem = React.memo(
    ({ tag }: { tag: string }) => (
      <Radio.Item key={tag} className={`${styles[`tagColor${tag}`]}`} value={tag} label="" />
    ),
    (prevProps, nextProps) => prevProps.tag === nextProps.tag,
  );

  return (
    <Formik
      initialValues={defaultFormValues}
      onSubmit={handleCreateTag}
      validationSchema={formValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik: FormikProps<FormValues>) => (
        <Modal title="New tag" isOpen={isOpen} className={styles.modal}>
          <Form className={styles.form}>
            <div className={styles.firebaseErrors}>
              <FormErrors messages={firebaseErrors} />
            </div>
            <FormField
              className={styles.colorField}
              label="Color"
              errorMessage={formik.errors.color}
            >
              <Radio.Group
                name="classes1"
                className={styles.radioGroupColor}
                value={formik.values.color}
                onChange={(e) => {
                  setColor(e.target.value, formik);
                  formik.handleChange(e);
                }}
              >
                {TagColors &&
                  TagColors.length &&
                  TagColors.map((tag) => <MemoizedRadioItem tag={tag} key={tag} />)}
              </Radio.Group>
            </FormField>
            <FormField label="Name" errorMessage={formik.errors.name}>
              <Input
                name="name"
                placeholder="Tag name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.errors.name)}
              />
            </FormField>
            <Modal.Footer>
              <Button type="secondary" onClick={() => handleCreateTagCancel(formik.resetForm)}>
                Cancel
              </Button>
              <Button isLoading={formik.isSubmitting} htmlType="submit">
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default SensoryTestTagCreationDialog;
