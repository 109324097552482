import React, { useEffect, useMemo } from 'react';
import {
  FormElement,
  FormElementResult,
  LineScaleFormElement,
} from '@bloodhound/common/dist/models/formElement';
import { Form, Formik, FormikProps } from 'formik';
import debounce from 'just-debounce-it';
import { Button, ChevronLeft, ChevronRight, FormField, Radio, Slider, TextArea } from 'ventura';
import * as Yup from 'yup';

import { FormNavigation } from 'components/molecules';
import {
  isCategoryScaleFormElement,
  isCommentFormElement,
  isLineScaleFormElement,
} from 'utils/typeGuards';

import styles from './FormPage.module.css';

interface FormValues {
  [key: string]: number | string;
}

const generateDefaultFormValues = (
  elements: FormElement[],
  results?: FormElementResult[],
): FormValues => {
  return elements.reduce<{ [key: string]: number | string }>((acc, formElement) => {
    const formElementResult = results?.find((result) => result.formElementId === formElement.id);

    if (formElementResult) {
      acc[formElement.id] = formElementResult.value;
    } else if (isLineScaleFormElement(formElement)) {
      acc[formElement.id] = (formElement as LineScaleFormElement).valueBoundaries.default;
    } else if (isCommentFormElement(formElement)) {
      acc[formElement.id] = '';
    }
    return acc;
  }, {});
};

const getFormValidationSchema = (formElements: FormElement[]) => {
  const formElementsSchema = formElements.reduce<Yup.ObjectSchemaDefinition<FormValues>>(
    (acc, formElement) => {
      if (isLineScaleFormElement(formElement)) {
        return {
          ...acc,
          [formElement.id]: Yup.number()
            .max(formElement.valueBoundaries.minimum)
            .max(formElement.valueBoundaries.maximum)
            .label(formElement.name)
            .required(),
        };
      }
      if (isCategoryScaleFormElement(formElement)) {
        return {
          ...acc,
          [formElement.id]: Yup.string()
            .oneOf(formElement.options)
            .label(formElement.name)
            .required(`Please select an option`),
        };
      }
      if (isCommentFormElement(formElement)) {
        return {
          ...acc,
          [formElement.id]: formElement.isRequired
            ? Yup.string().label(formElement.name).required()
            : Yup.string(),
        };
      }
      return acc;
    },
    {},
  );

  return Yup.object().shape<FormValues>(formElementsSchema);
};

type Props = {
  name: string;
  description: string;
  currentPageIndex: number;
  pageCount: number;
  elements: FormElement[];
  results?: FormElementResult[];
  onSave: (values: FormElementResult[], isAutoSave: boolean) => Promise<void>;
  onPrevious: () => void;
  onNext: () => void;
  isReadOnly?: boolean;
};

const FormPage: React.FC<Props> = ({
  name,
  description,
  currentPageIndex,
  pageCount,
  elements,
  results,
  onSave,
  onPrevious,
  onNext,
  isReadOnly = false,
}: Props) => {
  // Form results should not be included in the dependency array
  // https://github.com/kodiak-packages/bloodhound/issues/520
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultFormValues = useMemo(() => generateDefaultFormValues(elements, results), [elements]);

  const defaultValidationSchema = useMemo(() => getFormValidationSchema(elements), [elements]);

  const saveFormValues = async (formValues: FormValues, isAutoSave: boolean) => {
    const resultsToSubmit = Object.keys(formValues).map((formElementId) => ({
      formElementId,
      type: elements.find((formElement) => formElementId === formElement.id)!.type,
      value: formValues[formElementId],
    }));
    await onSave(resultsToSubmit, isAutoSave);
  };

  const saveFormValuesDebounced = useMemo(
    () => debounce((formValues: FormValues) => saveFormValues(formValues, true), 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [elements],
  );

  const handleSubmit = async (formValues: FormValues) => {
    if (!isReadOnly) {
      await saveFormValues(formValues, false);
    }

    if (pageCount !== 0 && currentPageIndex + 1 !== pageCount) {
      onNext();
    }
  };

  useEffect(() => {
    return saveFormValuesDebounced.cancel;
  }, [saveFormValuesDebounced]);

  const handleChange = (
    formik: FormikProps<FormValues>,
    fieldName: string,
    value: React.ChangeEvent | number,
  ) => {
    let valueToSave: number | string;
    if ((value as React.ChangeEvent).target) {
      formik.handleChange(value);
      valueToSave = (value as React.ChangeEvent<HTMLInputElement | HTMLSelectElement>).target.value;
    } else {
      formik.setFieldValue(fieldName, value);
      valueToSave = value as number;
    }

    saveFormValuesDebounced({ ...formik.values, [fieldName]: valueToSave });
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultFormValues}
      validationSchema={defaultValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize // rerender when initialValues change
    >
      {(formik: FormikProps<FormValues>) => (
        <Form className={styles.form}>
          {pageCount !== 0 && (
            <FormNavigation
              currentPageIndex={currentPageIndex}
              pageCount={pageCount}
              title={name}
              description={description}
              onPrevious={onPrevious}
              onNext={() => formik.submitForm()}
              className={styles.formNavigation}
            />
          )}
          {elements.map((formElement) => {
            if (isLineScaleFormElement(formElement)) {
              return (
                <FormField
                  key={formElement.id}
                  label={formElement.name}
                  className={styles.formElementInput}
                  errorMessage={formik.errors[formElement.id]}
                >
                  <Slider
                    value={
                      (formik.values[formElement.id] as number | undefined) ??
                      formElement.valueBoundaries.default
                    }
                    onChange={(value) => handleChange(formik, formElement.id, value)}
                    valueBoundaries={[
                      formElement.valueBoundaries.minimum,
                      formElement.valueBoundaries.maximum,
                    ]}
                    stepSize={0.1}
                    showTooltip={false}
                    textBoundaries={[
                      formElement.textBoundaries?.minimum,
                      formElement.textBoundaries?.maximum,
                    ]}
                    isDisabled={isReadOnly}
                  />
                </FormField>
              );
            }
            if (isCategoryScaleFormElement(formElement)) {
              return (
                <FormField
                  key={formElement.id}
                  label={formElement.name}
                  className={styles.formElementInput}
                  errorMessage={formik.errors[formElement.id]}
                >
                  <Radio.Group
                    name={formElement.id}
                    value={formik.values[formElement.id] as string | undefined}
                    onChange={(event) => handleChange(formik, formElement.id, event)}
                  >
                    {formElement.options.map((option) => (
                      <Radio.Item
                        key={option}
                        label={option}
                        value={option}
                        isDisabled={isReadOnly}
                      />
                    ))}
                  </Radio.Group>
                </FormField>
              );
            }
            if (isCommentFormElement(formElement)) {
              return (
                <FormField
                  key={formElement.id}
                  label={`${formElement.name}${formElement.isRequired ? '' : ' (optional)'}`}
                  className={styles.formElementInput}
                  errorMessage={formik.errors[formElement.id]}
                >
                  <TextArea
                    value={formik.values[formElement.id] as string}
                    name={formElement.id}
                    onChange={(event) => handleChange(formik, formElement.id, event)}
                    placeholder="Write your text here"
                    isDisabled={isReadOnly}
                  />
                </FormField>
              );
            }
            return undefined;
          })}
          {elements.length === 0 && (
            <p className={styles.formElementsPlaceholder}>There are no parameters to rate.</p>
          )}

          <div className={styles.buttons}>
            {pageCount !== 0 && (
              <Button
                onClick={onPrevious}
                className={styles.leftButton}
                prefixIcon={<ChevronLeft />}
                isDisabled={currentPageIndex === 0}
              >
                Previous
              </Button>
            )}
            {elements.length !== 0 && (
              <Button
                name="submit"
                htmlType="submit"
                isLoading={formik.isSubmitting}
                className={styles.rightButton}
                suffixIcon={
                  currentPageIndex + 1 === pageCount || pageCount === 0 ? undefined : (
                    <ChevronRight />
                  )
                }
                isDisabled={isReadOnly && (currentPageIndex + 1 === pageCount || pageCount === 0)}
              >
                {currentPageIndex + 1 >= pageCount ? 'Submit' : 'Next'}
              </Button>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default FormPage;
