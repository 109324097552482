import React from 'react';
import { QdaTest, SensoryTest } from '@bloodhound/common/dist/models/sensoryTest';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, Button, FormField, Input, Modal } from 'ventura';
import * as Yup from 'yup';

import { useSensoryTestCreator } from 'services/sensoryTestService';
import { generateAmountOfSampleIds } from 'utils/random';
import { isQdaTest } from 'utils/typeGuards';

import styles from './CopyDialog.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  sensoryTest: SensoryTest;
};

type FormValues = {
  name: string;
};

const formValidationSchema = Yup.object().shape<FormValues>({
  name: Yup.string().label('sensory test name').required(),
});

const CopyDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  sensoryTest: sensoryTestToBeCopied,
}: Props) => {
  const { createSensoryTest, error } = useSensoryTestCreator();

  const onSubmit = async (formValues: FormValues) => {
    const { id, dateCreated, ...partialSensoryTestToBeCopied } = sensoryTestToBeCopied;
    const sensoryTest: Omit<SensoryTest, 'id' | 'dateCreated'> = {
      ...partialSensoryTestToBeCopied,
      name: formValues.name,
    };

    if (isQdaTest(sensoryTestToBeCopied)) {
      const newSampleIds = generateAmountOfSampleIds(sensoryTestToBeCopied.samples.length);

      (sensoryTest as Omit<QdaTest, 'id' | 'dateCreated'>).samples = (sensoryTest as Omit<
        QdaTest,
        'id' | 'dateCreated'
      >).samples.map((sample, index) => ({ ...sample, id: newSampleIds[index] }));
    }

    const isSuccess = await createSensoryTest(sensoryTest);

    if (isSuccess) {
      onClose();
    }
  };

  const initialFormValues: FormValues = {
    name: `${sensoryTestToBeCopied.name} - Copy`,
  };

  return (
    <Modal title="Copy sensory test" isOpen={isOpen}>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialFormValues}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik: FormikProps<FormValues>) => (
          <Form>
            {error && <Alert intent="error" message={error.message} className={styles.alert} />}
            <FormField label="Sensory test name" errorMessage={formik.errors.name}>
              <Input
                name="name"
                placeholder="Sensory test name"
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                isInvalid={Boolean(formik.errors.name)}
              />
            </FormField>
            <Modal.Footer>
              <Button type="secondary" onClick={onClose} name="cancel">
                Cancel
              </Button>
              <Button isLoading={formik.isSubmitting} htmlType="submit" name="submit">
                Create
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default CopyDialog;
