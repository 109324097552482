import React from 'react';
import { TriangleTest } from '@bloodhound/common/dist/models/sensoryTest';
import { FormikProps } from 'formik';
import { Checkbox, FormField, Input, TextArea } from 'ventura';
import * as Yup from 'yup';

import { SampleIcon } from 'components/atoms';

import styles from './TriangleTestForm.module.css';

export type TriangleTestFormValues = Omit<
  TriangleTest,
  | 'workspaceId'
  | 'name'
  | 'type'
  | 'id'
  | 'dateCreated'
  | 'sampleIdsA'
  | 'sampleIdsB'
  | 'significanceLevel'
> & { significanceLevel: string };

export const triangleTestDefaultFormValues: TriangleTestFormValues = {
  sampleCharA: 'A',
  sampleNameA: '',
  sampleCharB: 'B',
  sampleNameB: '',
  significanceLevel: '0.05',
  instructions: 'After all samples have been tasted, you can taste again in any order.',
  purpose: '',
  settings: {
    usesSimplifiedSampleIds: false,
    isCommentFieldRequired: false,
  },
};

export const triangleTestFormValidationSchema = Yup.object().shape<Partial<TriangleTestFormValues>>(
  {
    sampleCharA: Yup.string().when('type', { is: 'triangle', then: Yup.string().required() }),
    sampleNameA: Yup.string().when('type', {
      is: 'triangle',
      then: Yup.string().label('First sample name').required(),
    }),
    sampleCharB: Yup.string().when('type', { is: 'triangle', then: Yup.string().required() }),
    sampleNameB: Yup.string().when('type', {
      is: 'triangle',
      then: Yup.string().label('Second sample name').required(),
    }),
    // @ts-ignore
    significanceLevel: Yup.number().label('Significance level').required().moreThan(0).lessThan(1),
  },
);

interface Props {
  formik: FormikProps<TriangleTestFormValues>;
}

const TriangleTestForm: React.FC<Props> = ({ formik }: Props) => {
  const { handleChange, handleBlur, values, errors } = formik;

  return (
    <div className={styles.container}>
      <div className={styles.formGroup}>
        <FormField label="Samples" errorMessage={errors.sampleNameB} className={styles.formField}>
          <div className={styles.sample}>
            <SampleIcon sensoryTestType="triangle" index={0} id="A" className={styles.sampleIcon} />
            <Input
              name="sampleNameA"
              placeholder="Sample name"
              value={values.sampleNameA}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={Boolean(errors.sampleNameA)}
              className={styles.sampleNameInput}
            />
          </div>
          <div className={styles.sample}>
            <SampleIcon sensoryTestType="triangle" index={1} id="B" className={styles.sampleIcon} />
            <Input
              name="sampleNameB"
              placeholder="Sample name"
              value={values.sampleNameB}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={Boolean(errors.sampleNameB)}
              className={styles.sampleNameInput}
            />
          </div>
        </FormField>

        <FormField
          label="Significance level (ɑ)"
          errorMessage={errors.significanceLevel}
          className={styles.formField}
        >
          <Input
            type="number"
            name="significanceLevel"
            placeholder="Level"
            value={values.significanceLevel}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(errors.significanceLevel)}
            className={styles.significanceLevelInput}
          />
        </FormField>
      </div>
      <FormField
        className={styles.formField}
        label="Participant instructions"
        errorMessage={errors.instructions as string}
      >
        <TextArea
          name="instructions"
          placeholder="Instructions to help your panelists"
          isInvalid={Boolean(errors.instructions)}
          value={values.instructions}
          onChange={handleChange}
          onBlur={handleBlur}
          className={styles.instructions}
        />
      </FormField>
      <FormField
        className={styles.formField}
        label="The purpose of this test (optional)"
        errorMessage={errors.purpose as string}
      >
        <TextArea
          name="purpose"
          placeholder=""
          isInvalid={Boolean(errors.purpose)}
          value={values.purpose}
          onChange={handleChange}
          onBlur={handleBlur}
          className={styles.purpose}
        />
      </FormField>
      <div className={styles.checkGroup}>
        <FormField
          className={styles.formField}
          errorMessage={errors.settings?.usesSimplifiedSampleIds}
        >
          <Checkbox
            name="settings.usesSimplifiedSampleIds"
            value={values.settings.usesSimplifiedSampleIds}
            onChange={handleChange}
            label="Simplified sample codes"
            description="Sensly will only use 4 unique samples codes for all panelists. Each panelist will still see 3 different sample codes. This reduces the amount of sample codes you have to work with."
          />
        </FormField>
        <FormField
          className={styles.formField}
          errorMessage={errors.settings?.usesSimplifiedSampleIds}
        >
          <Checkbox
            name="settings.isCommentFieldRequired"
            value={values.settings.isCommentFieldRequired}
            onChange={handleChange}
            label="Comment field is required"
            description="When checked, panelists are required to fill in the comment field."
          />
        </FormField>
      </div>
    </div>
  );
};

export default TriangleTestForm;
