export const TagColors: string[] = [
  'RED',
  'ORANGE',
  'YELLOW',
  'OLIVE',
  'GREEN',
  'TEAL',
  'CYAN',
  'BLUE',
  'PURPLE',
  'PINK',
];

export const TagColorValues: Record<string, string> = {
  RED: '#813636',
  ORANGE: '#66471A',
  YELLOW: '#66611A',
  OLIVE: '#40661A',
  GREEN: '#1C661A',
  TEAL: '#196654',
  CYAN: '#1A5966',
  BLUE: '#1A3366',
  PURPLE: '#391966',
  PINK: '#661A66',
};

export const TagColorBackground: Record<string, string> = {
  RED: '#FFEBEB',
  ORANGE: '#FFF3E0',
  YELLOW: '#FFFCD6',
  OLIVE: '#EBFFD6',
  GREEN: '#E1FFE0',
  TEAL: '#D6FFF5',
  CYAN: '#D6F8FF',
  BLUE: '#EBF2FF',
  PURPLE: '#F3EBFF',
  PINK: '#FFEBFF',
};

export const TagColorSortOrder: Record<string, number> = {
  RED: 0,
  ORANGE: 1,
  YELLOW: 2,
  OLIVE: 3,
  GREEN: 4,
  TEAL: 5,
  CYAN: 6,
  BLUE: 7,
  PURPLE: 8,
  PINK: 9,
  UNTAGGED: 10,
};
