import { FormElement, FormElementResult } from '@bloodhound/common/dist/models/formElement';
import { QdaSample } from '@bloodhound/common/dist/models/sensoryTest';
import { QdaSampleResult } from '@bloodhound/common/dist/models/sensoryTestParticipant';

import { isPageFormElement } from 'utils/typeGuards';

interface FormPage {
  id: string;
  name: string;
  description: string;
  formElements: FormElement[];
  results: FormElementResult[];
}

export const getFormElementsAndResultsByPage = (
  formElements: FormElement[],
  results?: FormElementResult[],
): FormPage[] => {
  const pages = formElements.reduce<FormPage[]>((accumulator, formElement) => {
    if (isPageFormElement(formElement)) {
      return [
        ...accumulator,
        {
          id: formElement.id,
          name: formElement.name,
          description: formElement.description,
          formElements: [],
          results: [],
        },
      ];
    }

    if (accumulator.length === 0) {
      return accumulator;
    }

    accumulator[accumulator.length - 1].formElements.push(formElement);
    return accumulator;
  }, []);

  const pagesWithFormElements = pages.filter((page) => page.formElements.length > 0);

  if (pagesWithFormElements.length === 0 || !results) {
    return pagesWithFormElements;
  }

  return pagesWithFormElements.map((page) => {
    const formElementIds = page.formElements.map((formElement) => formElement.id);
    const formElementResults = results.filter((result) =>
      formElementIds.includes(result.formElementId),
    );

    return { ...page, results: formElementResults };
  });
};

export const createSampleResultCompletedMap = (
  formElements: FormElement[],
  sampleResults: QdaSampleResult[],
  samples: QdaSample[],
) => {
  const formElementsWithPossibleResults = formElements.filter(isPageFormElement);
  return samples.reduce<Record<string, boolean>>((accumulator, sample) => {
    let isCompleted = false;
    const sampleResult = sampleResults.find((result) => result.sampleId === sample.id);

    if (sampleResult) {
      isCompleted =
        formElementsWithPossibleResults.length === sampleResult.formElementResults.length;
    }

    return {
      ...accumulator,
      [sample.id]: isCompleted,
    };
  }, {});
};
