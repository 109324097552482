import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
  className?: string;
}

export const SensoryTestFormLoginLoader: React.FC<Props> = ({ className }) => (
  <ContentLoader
    viewBox="0 0 375 350"
    width="375"
    height="350"
    uniqueKey="SensoryTestFormLogin"
    className={className}
  >
    <rect x="28" y="0" rx="4" ry="4" width="20" height="20" />
    <rect x="28" y="44" rx="4" ry="4" width="320" height="18" />
    <rect x="28" y="68" rx="4" ry="4" width="320" height="18" />
    <rect x="28" y="130" rx="4" ry="4" width="320" height="34" />
  </ContentLoader>
);
