import React from 'react';
import { Button, ChevronLeft, ChevronRight } from 'ventura';

import styles from './FormNavigation.module.css';

type Props = {
  currentPageIndex: number;
  pageCount: number;
  title: string;
  description?: string;
  onPrevious?: () => void;
  onNext?: () => void;
  className?: string;
};

const FormNavigation: React.FC<Props> = ({
  currentPageIndex,
  title,
  description,
  pageCount,
  onPrevious,
  onNext,
  className,
}: Props) => {
  return (
    <div className={className}>
      <nav className={styles.formNavigation}>
        {Boolean(onPrevious) && (
          <Button
            className={styles.navItem}
            onClick={() => onPrevious?.()}
            name="previous"
            isDisabled={currentPageIndex === 0}
          >
            <ChevronLeft className={styles.icon} />
          </Button>
        )}

        <span className={styles.pageIndex}>{currentPageIndex + 1}</span>
        <div className={styles.text}>
          <span className={styles.title}>{title}</span>
          <span className={styles.step}>
            Page {currentPageIndex + 1} of {pageCount}
          </span>
        </div>

        {Boolean(onNext) && (
          <Button
            className={styles.navItem}
            onClick={() => onNext?.()}
            name="next"
            isDisabled={!onNext || currentPageIndex + 1 === pageCount}
          >
            <ChevronRight className={styles.icon} />
          </Button>
        )}
      </nav>
      {Boolean(description) && <p className={styles.description}>{description}</p>}
    </div>
  );
};

export default FormNavigation;
