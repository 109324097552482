import React from 'react';
import { SensoryTestType } from '@bloodhound/common/dist/models/sensoryTest';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, Button, FormField, Input } from 'ventura';
import * as Yup from 'yup';

import samplesTriangle from 'assets/panelistScreens/samplesTriangle.svg';
import samplesTriangleSmall from 'assets/panelistScreens/samplesTriangleSmall.svg';
import samplesTwoFive from 'assets/panelistScreens/samplesTwoFive.svg';
import samplesTwoFiveSmall from 'assets/panelistScreens/samplesTwoFiveSmall.svg';

import styles from './SampleCodesForm.module.css';

export interface SampleCodesFormValues {
  firstSampleCode: string;
  secondSampleCode: string;
  thirdSampleCode: string;
  fourthSampleCode?: string;
  fifthSampleCode?: string;
}

const defaultFormValues: SampleCodesFormValues = {
  firstSampleCode: '',
  secondSampleCode: '',
  thirdSampleCode: '',
  fourthSampleCode: '',
  fifthSampleCode: '',
};

interface Props {
  sensoryTestType: SensoryTestType;
  onSubmit: (formValues: SampleCodesFormValues) => void;
  error?: string;
  companyLogoSrc?: string;
}

const SampleCodesForm: React.FC<Props> = ({ onSubmit, sensoryTestType, error }) => {
  const formValidationSchema = Yup.object().shape<SampleCodesFormValues>({
    firstSampleCode: Yup.string()
      .length(3, 'Sample codes have three digits')
      .required('All sample codes are required'),
    secondSampleCode: Yup.string()
      .length(3, 'Sample codes have three digits')
      .required('All sample codes are required'),
    thirdSampleCode: Yup.string()
      .length(3, 'Sample codes have three digits')
      .required('All sample codes are required'),
    ...(sensoryTestType === 'twoFive'
      ? {
          fourthSampleCode: Yup.string()
            .length(3, 'Sample codes have three digits')
            .required('All sample codes are required'),
          fifthSampleCode: Yup.string()
            .length(3, 'Sample codes have three digits')
            .required('All sample codes are required'),
        }
      : {}),
  });

  return (
    <>
      <img
        src={sensoryTestType === 'triangle' ? samplesTriangle : samplesTwoFive}
        alt="Illustration of samples"
        className={styles.samplesIllustration}
      />
      <img
        src={sensoryTestType === 'triangle' ? samplesTriangleSmall : samplesTwoFiveSmall}
        alt="Illustration of samples"
        className={styles.samplesIllustrationSmall}
      />

      <h3 className={styles.title}>
        Enter the label numbers that are on the {sensoryTestType === 'triangle' ? 3 : 5} samples.
      </h3>

      <p className={styles.description}>
        A label is attached to each sample and displaying a 3 digit number. These numbers identify
        which samples you are testing.
      </p>

      {error && <Alert className={styles.error} intent="error" message={error} />}

      <Formik
        onSubmit={onSubmit}
        initialValues={defaultFormValues}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik: FormikProps<SampleCodesFormValues>) => (
          <Form>
            <FormField
              errorMessage={
                formik.errors.firstSampleCode ||
                formik.errors.secondSampleCode ||
                formik.errors.thirdSampleCode ||
                formik.errors.fourthSampleCode ||
                formik.errors.fifthSampleCode
              }
            >
              <div className={styles.inputsContainer}>
                <Input
                  name="firstSampleCode"
                  maxLength={6}
                  onChange={formik.handleChange}
                  value={formik.values.firstSampleCode}
                  className={styles.sampleCodeInput}
                />
                <Input
                  name="secondSampleCode"
                  maxLength={6}
                  onChange={formik.handleChange}
                  value={formik.values.secondSampleCode}
                  className={styles.sampleCodeInput}
                />
                <Input
                  name="thirdSampleCode"
                  maxLength={6}
                  onChange={formik.handleChange}
                  value={formik.values.thirdSampleCode}
                  className={styles.sampleCodeInput}
                />
                {sensoryTestType === 'twoFive' && (
                  <>
                    <Input
                      name="fourthSampleCode"
                      maxLength={6}
                      onChange={formik.handleChange}
                      value={formik.values.fourthSampleCode}
                      className={styles.sampleCodeInput}
                    />
                    <Input
                      name="fifthSampleCode"
                      maxLength={6}
                      onChange={formik.handleChange}
                      value={formik.values.fifthSampleCode}
                      className={styles.sampleCodeInput}
                    />
                  </>
                )}

                <div>
                  <Button
                    className={styles.button}
                    htmlType="submit"
                    isDisabled={
                      !formik.values.firstSampleCode ||
                      !formik.values.secondSampleCode ||
                      !formik.values.thirdSampleCode ||
                      (sensoryTestType === 'twoFive' &&
                        (!formik.values.fourthSampleCode || !formik.values.fifthSampleCode))
                    }
                    isLoading={formik.isSubmitting}
                    name="continue"
                  >
                    Continue
                  </Button>
                </div>
              </div>
            </FormField>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default SampleCodesForm;
