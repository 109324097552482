import React, { useState } from 'react';
import { QdaSample } from '@bloodhound/common/dist/models';
import { Button, Maximize2, MessageSquare, Modal } from 'ventura';

import { SampleIcon } from 'components/atoms';

import styles from './CommentResult.module.css';

export interface CommentResultType {
  participantId: string;
  value: string;
  sampleId: number;
}

type Props = {
  name: string;
  comments: CommentResultType[];
  samples: QdaSample[];
  className?: string;
};

const CommentResult: React.FC<Props> = ({ name, comments, samples, className }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const sortedComments = comments.reverse();
  const areThereResults = comments.length > 0;

  return (
    <>
      <div className={className}>
        <div className={styles.header}>
          <div className={styles.title}>
            <MessageSquare />
            <span className={styles.formElementName}>{name}</span>
            <span className={styles.formElementType}>Comment</span>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>
            <Maximize2 />
          </Button>
        </div>
        {areThereResults ? (
          <ul className={styles.commentGroups}>
            {samples.map(({ id: sampleId }, index) => (
              <li className={styles.commentGroup} key={sampleId}>
                {sortedComments
                  .filter((comment) => comment.sampleId === sampleId)
                  .slice(0, 2)
                  .map((comment) => (
                    <div className={styles.commentLine} key={comment.participantId}>
                      <div
                        className={styles.barStartPoint}
                        style={{ background: `var(--qda-sample-${index + 1}-2)` }}
                      />
                      <span className={styles.value}>{comment.value}</span>
                    </div>
                  ))}
              </li>
            ))}
          </ul>
        ) : (
          <span className={styles.placeholder}>No results yet</span>
        )}
      </div>

      <Modal isOpen={isModalOpen} title="Parameter results" className={styles.modal}>
        <div className={styles.title}>
          <MessageSquare />
          <span className={styles.formElementName}>{name}</span>
          <span className={styles.formElementType}>Comment</span>
        </div>
        <h4 className={styles.subTitle}>Answers</h4>
        {samples.map((sample, index) => {
          const commentsForSample = comments.filter((comment) => comment.sampleId === sample.id);

          return (
            <div className={styles.sampleComments} key={sample.id}>
              <div className={styles.sampleTitle}>
                <span className={styles.sample}>Sample: </span>
                <SampleIcon id={sample.id} index={index} sensoryTestType="qda" />
                <span className={styles.sampleName}>{sample.name}</span>
              </div>
              <ul className={styles.answers}>
                {commentsForSample.map((answer) => (
                  <li key={answer.participantId}>
                    <div
                      className={styles.barStartPoint}
                      style={{ background: `var(--qda-sample-${index + 1}-2)` }}
                    />
                    <span>{answer.value}</span>
                  </li>
                ))}
                {!areThereResults && (
                  <p className={styles.noResultsText}>No answers for this sample</p>
                )}
              </ul>
            </div>
          );
        })}
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CommentResult;
