import React, { useState } from 'react';
import { Tag, Workspace } from '@bloodhound/common/dist/models';
import { Button, Plus } from 'ventura';

import { SensoryTestTagsDisplay } from 'components/molecules';
import { SensoryTestTagCreationDialog } from 'components/organisms';

import styles from './SensoryTestTags.module.css';

export interface Props {
  workspace?: Workspace;
  checkBoxMode?: boolean;
  selectedTags?: Tag[];
  tagsChecked?: Tag[];
  tagsUndetermined?: Tag[];
  updateTagsChecked?: (tag: Tag) => void;
  updateSelectedTags?: (tag: Tag) => void;
  updateSensoryTagsHook?: (update: boolean) => void;
}

const TagEditor: React.FC<Props> = ({
  workspace,
  checkBoxMode,
  selectedTags,
  tagsChecked,
  tagsUndetermined,
  updateTagsChecked,
  updateSelectedTags,
  updateSensoryTagsHook,
}) => {
  const [isNewTagModalOpen, setIsNewTagModalOpen] = useState(false);

  const openNewTagModal = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsNewTagModalOpen(true);
  };

  const handleCreateTagCancel = (id?: string) => {
    if (id) {
      //setSelectedTags([]);
    }
    setIsNewTagModalOpen(false);
  };

  const tags: Tag[] = workspace?.tags || [];

  // Check if the "Untagged" tag already exists in the tags array
  const untaggedTagExists = tags.some((tag) => tag.id === 'untagged');

  // If the "Untagged" tag doesn't exist, add it to the tags array
  if (!untaggedTagExists) {
    const unTag: Tag = {
      id: 'untagged',
      color: 'UNTAGGED',
      name: 'Untagged',
    };
    tags.push(unTag);
  }

  return (
    <section className={styles.tagsContainer}>
      <div className={styles.title}>
        {selectedTags && selectedTags.length > 0 ? `${selectedTags.length} tags selected` : 'Tags'}
      </div>
      <div className={styles.tagsInner}>
        <div className={styles.tagsListContainer}>
          <SensoryTestTagsDisplay
            workspace={workspace}
            tags={tags}
            selectedTags={selectedTags}
            checkBoxMode={checkBoxMode}
            updateSelectedTags={updateSelectedTags}
            tagsChecked={tagsChecked}
            tagsUndetermined={tagsUndetermined}
            updateTagsChecked={updateTagsChecked}
            updateSensoryTagsHook={updateSensoryTagsHook}
          ></SensoryTestTagsDisplay>
        </div>
        <div className={styles.newTagContainer}>
          <Button
            className={styles.newTagButton}
            type="secondary"
            name="new_tag"
            prefixIcon={<Plus />}
            onClick={openNewTagModal}
          >
            New tag
          </Button>
        </div>
      </div>
      <SensoryTestTagCreationDialog
        isOpen={isNewTagModalOpen}
        onClose={handleCreateTagCancel}
        workspace={workspace}
      />
    </section>
  );
};

export default TagEditor;
