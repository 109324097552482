import React from 'react';
import ContentLoader from 'react-content-loader';

const SensoryTestDetailContentLoader: React.FC = () => (
  <ContentLoader viewBox="0 0 780 400" width="780" height="400" uniqueKey="TriangleTestDetail">
    <rect x="0" y="0" rx="4" ry="4" width="400" height="28" />
    <rect x="0" y="48" rx="4" ry="4" width="600" height="28" />

    <rect x="0" y="140" rx="4" ry="4" width="780" height="24" />

    <rect x="0" y="179" rx="4" ry="4" width="120" height="18" />
    <rect x="135" y="179" rx="4" ry="4" width="120" height="18" />
    <rect x="270" y="179" rx="4" ry="4" width="120" height="18" />
    <rect x="405" y="179" rx="4" ry="4" width="120" height="18" />
    <rect x="540" y="179" rx="4" ry="4" width="120" height="18" />
    <rect x="675" y="179" rx="4" ry="4" width="105" height="18" />

    <rect x="0" y="207" rx="4" ry="4" width="120" height="18" />
    <rect x="135" y="207" rx="4" ry="4" width="120" height="18" />
    <rect x="270" y="207" rx="4" ry="4" width="120" height="18" />
    <rect x="405" y="207" rx="4" ry="4" width="120" height="18" />
    <rect x="540" y="207" rx="4" ry="4" width="120" height="18" />
    <rect x="675" y="207" rx="4" ry="4" width="105" height="18" />

    <rect x="0" y="235" rx="4" ry="4" width="120" height="18" />
    <rect x="135" y="235" rx="4" ry="4" width="120" height="18" />
    <rect x="270" y="235" rx="4" ry="4" width="120" height="18" />
    <rect x="405" y="235" rx="4" ry="4" width="120" height="18" />
    <rect x="540" y="235" rx="4" ry="4" width="120" height="18" />
    <rect x="675" y="235" rx="4" ry="4" width="105" height="18" />
  </ContentLoader>
);

export default SensoryTestDetailContentLoader;
