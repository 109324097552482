import React, { useEffect, useState } from 'react';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Link } from 'react-router-dom';
import { useWindowWidth } from '@react-hook/window-size';
import clsx from 'clsx';
import { Button } from 'ventura';

import { SvgIcon } from 'components/atoms';
import { NavItem } from 'components/molecules';
import logo from '../../../assets/logo.svg';

import styles from './Navigation.module.css';

type Props = {};

const MOBILE_WINDOW_WIDTH = 900;
const PANE_WIDTH = 260;
const PANE_POSITION_VISIBLE = 0;
const PANE_POSITION_HIDDEN = -PANE_WIDTH;
const TRANSITION_DURATION_IN_MS = 200;

const Navigation: React.FC<Props> = () => {
  const windowWith = useWindowWidth({ initialWidth: 0, wait: 0 });
  const isMobile = windowWith <= MOBILE_WINDOW_WIDTH;
  const [isMenuVisible, setIsMenuVisible] = useState(!isMobile);
  const position = isMenuVisible ? PANE_POSITION_VISIBLE : PANE_POSITION_HIDDEN;
  const [positionOnDrag, setPositionOnDrag] = useState(position);
  const [isTransitionEnabled, setIsTransitionEnabled] = useState(false); // transition can not always be set because this creates delay on the drag action
  const positionOnDragRatio = (PANE_WIDTH + positionOnDrag) / PANE_WIDTH;

  useEffect(() => {
    setIsMenuVisible(!isMobile);

    if (isMobile) {
      setPositionOnDrag(PANE_POSITION_HIDDEN);
    }
  }, [isMobile]);

  const animatePane = (shouldOpen: boolean) => {
    setIsMenuVisible(shouldOpen);
    setIsTransitionEnabled(true);
    setPositionOnDrag(shouldOpen ? PANE_POSITION_VISIBLE : PANE_POSITION_HIDDEN);
    setTimeout(() => {
      setIsTransitionEnabled(false);
    }, TRANSITION_DURATION_IN_MS);
  };

  const handleOnStopDragging = (e: DraggableEvent, data: DraggableData) => {
    const deltaX = isMenuVisible ? Math.abs(data.x) : PANE_WIDTH - Math.abs(data.x);
    if (deltaX > PANE_WIDTH / 4) {
      animatePane(!isMenuVisible);
    }
  };

  const handleOnDrag = (e: DraggableEvent, data: DraggableData) => {
    setPositionOnDrag(data.x);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Escape') animatePane(false);
  };

  return (
    <>
      <div className={styles.topBar}>
        <Button
          onClick={() => animatePane(true)}
          type="secondary"
          className={styles.menuButton}
          name="openMenu"
        >
          <SvgIcon icon="menu" className={styles.menuIcon} />
        </Button>
        <Link to="/sensoryTests" className={styles.mobileLogo}>
          <img className={styles.logoImg} src={logo} alt="Sensly logo" />
        </Link>
      </div>
      <div
        className={positionOnDrag !== PANE_POSITION_HIDDEN ? styles.overlay : ''}
        style={{
          opacity: positionOnDragRatio / 2 + 0.5,
          backdropFilter: `blur(${positionOnDragRatio * 10}px)`,
          WebkitBackdropFilter: `blur(${positionOnDragRatio * 10}px)`,
        }}
        onClick={() => animatePane(false)}
        role="button"
        tabIndex={-1}
        aria-label="Close menu"
        onKeyUp={handleKeyPress}
        data-testid="overlay"
      />
      <Draggable
        axis="x"
        disabled={!isMobile}
        bounds={{ left: -PANE_WIDTH, right: 0, top: 0, bottom: 0 }}
        position={{ x: position, y: 0 }}
        onStop={handleOnStopDragging}
        onDrag={handleOnDrag}
        cancel=".disableDrag"
      >
        <div
          className={`${styles.draggable} ${isTransitionEnabled ? styles.dragTransition : ''}`}
          data-testid="draggable"
        >
          <div className={styles.pane}>
            <div className={styles.menuTop}>
              <Link to="/sensoryTests" className={clsx(styles.logo, 'disableDrag')}>
                <img className={styles.logoImg} src={logo} alt="Sensly logo" />
              </Link>
              <NavItem
                to="/sensoryTests"
                label="Sensory tests"
                icon="flask"
                className="disableDrag"
              />
              {/* <NavItem to="/panelists" label="Panelists" icon="people" className="disableDrag" /> */}
            </div>
            <div className={styles.menuBottom}>
              {/* TODO Account page */}
              {/* <Link to="/me" className={clsx(styles.link, 'disableDrag')}>
                My account
              </Link> */}
              <Link to="/logout" className={clsx(styles.link, 'disableDrag')}>
                Logout
              </Link>
            </div>
          </div>
        </div>
      </Draggable>
    </>
  );
};

export default Navigation;
