import React from 'react';
import { Form, Formik } from 'formik';
import { Button, FormField, Input, Modal, TextArea } from 'ventura';
import * as Yup from 'yup';

import { PreviewBox } from 'components/atoms';
import { FormNavigation } from 'components/molecules';

import styles from './PageFormElementDialogContent.module.css';

export interface PageFormElementPreloadedState {
  name: string;
  description: string;
}

const formValidationSchema = Yup.object().shape<PageFormElementPreloadedState>({
  name: Yup.string().label('Page name').required(),
  description: Yup.string<string>().label('Description'),
});

const initialState: PageFormElementPreloadedState = {
  name: '',
  description: 'Rate the sample on the parameters below.',
};

interface Props {
  preloadedState?: PageFormElementPreloadedState;
  currentPageIndex: number;
  pageCount: number;
  onCancel: () => void;
  onSubmit: (pageProps: PageFormElementPreloadedState) => void;
}

const PageFormElementDialogContent: React.FC<Props> = ({
  preloadedState,
  currentPageIndex,
  pageCount,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <>
      <p className={styles.description}>
        A page allows you to group form elements together. Each group is displayed on a separate
        page for a panelist.
      </p>
      <Formik
        onSubmit={onSubmit}
        initialValues={preloadedState ?? initialState}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form className={styles.form}>
            <FormField label="Name" errorMessage={formik.errors.name}>
              <Input
                name="name"
                placeholder="Page name"
                isInvalid={Boolean(formik.errors.name)}
                value={formik.values.name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormField>
            <FormField
              label="Description or instructions (optional)"
              errorMessage={formik.errors.description as string}
            >
              <TextArea
                name="instructions"
                placeholder="Description or instructions to help your panelists"
                isInvalid={Boolean(formik.errors.description)}
                value={formik.values.description}
                onChange={(event) => formik.setFieldValue('description', event.target.value)}
                onBlur={formik.handleBlur}
              />
            </FormField>

            <PreviewBox>
              <FormNavigation
                currentPageIndex={currentPageIndex}
                title={formik.values.name || 'Page name'}
                description={formik.values.description}
                pageCount={pageCount}
                onPrevious={() => {}}
                onNext={() => {}}
              />
            </PreviewBox>

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={onCancel}>
                {preloadedState ? 'Cancel' : 'Back'}
              </Button>
              <Button name="save" htmlType="submit">
                {preloadedState ? 'Save' : 'Create'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default PageFormElementDialogContent;
