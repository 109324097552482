import React from 'react';
import { TriangleTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';

import styles from './TriangleTestParticipantFormResult.module.css';

interface Props {
  participant: TriangleTestParticipant;
}

const TriangleTestParticipantFormResult: React.FC<Props> = ({ participant }) => {
  const isCorrect = participant.selectedSampleId === participant.uniqueSampleId;
  const hasPurpose =
    participant.sensoryTest?.purpose !== undefined &&
    participant.sensoryTest?.purpose !== null &&
    participant.sensoryTest?.purpose !== '';
  return (
    <>
      <h2 className={styles.title}>
        {isCorrect
          ? 'Well done, you correctly identified the different sample.'
          : `The different sample was not identified. The correct sample was: ${participant.uniqueSampleId}`}
      </h2>
      <h3 className={styles.subTitle}>Thank you for participating!</h3>
      {hasPurpose && (
        <>
          <div className={styles.purposeTitle}>The purpose of this sensory test:</div>
          <div className={styles.purpose}>{participant.sensoryTest.purpose}</div>
        </>
      )}
    </>
  );
};

export default TriangleTestParticipantFormResult;
