import React from 'react';

import { ReactComponent as FlaskIcon } from '../../../assets/icons/flask.svg';
import { ReactComponent as MenuIcon } from '../../../assets/icons/menu.svg';
import { ReactComponent as PeopleIcon } from '../../../assets/icons/people.svg';
import { ReactComponent as QdaTestIcon } from '../../../assets/icons/sensoryTests/qda.svg';
import { ReactComponent as TriangleTestIcon } from '../../../assets/icons/sensoryTests/triangle.svg';
import { ReactComponent as TwoFiveIcon } from '../../../assets/icons/sensoryTests/twoFive.svg';

const svgIcons = {
  flask: FlaskIcon,
  people: PeopleIcon,
  menu: MenuIcon,
  'sensoryTests/triangle': TriangleTestIcon,
  'sensoryTests/qda': QdaTestIcon,
  'sensoryTests/twoFive': TwoFiveIcon,
};

export type SvgIconName = keyof typeof svgIcons;

type Props = {
  icon: SvgIconName;
  className?: string;
};

const Icon: React.FC<Props> = ({ icon, className }: Props) => {
  const SvgIcon = svgIcons[icon];
  return <SvgIcon className={className} />;
};

export default Icon;
