import React from 'react';
import { Alert } from 'ventura';

type Props = {
  messages: string[];
};

const FormErrors: React.FC<Props> = ({ messages }: Props) => {
  return (
    <div>
      {messages.map((message) => (
        <Alert key={btoa(message)} message={message} intent="error" />
      ))}
    </div>
  );
};

export default FormErrors;
