import React from 'react';
import { Form, Formik } from 'formik';
import { Alert, Button, FormField, Input, Modal, Slider } from 'ventura';
import * as Yup from 'yup';

import { PreviewBox } from '../../atoms';

import styles from './LineScaleFormElementDialogContent.module.css';

export interface LineScaleFormElementPreloadedState {
  name: string;
  dataLabel?: string;
  minimumValue: number;
  maximumValue: number;
  defaultValue: number;
  leftText?: string;
  rightText?: string;
}

const formValidationSchema = Yup.object().shape<LineScaleFormElementPreloadedState>({
  name: Yup.string().label('Parameter name').required(),
  minimumValue: Yup.number()
    .label('Minimum value')
    .required()
    .when('defaultValue', (defaultValue: number, schema: Yup.NumberSchema) =>
      schema.max(defaultValue),
    ),
  dataLabel: Yup.string().label('Chart label'),
  maximumValue: Yup.number()
    .label('Maximum value')
    .required()
    .when('defaultValue', (defaultValue: number, schema: Yup.NumberSchema) =>
      schema.moreThan(defaultValue),
    ),
  defaultValue: Yup.number().label('Default value').required(),
});

const initialState: LineScaleFormElementPreloadedState = {
  name: '',
  minimumValue: 0,
  maximumValue: 10,
  defaultValue: 5,
  leftText: '',
  rightText: '',
};

interface Props {
  preloadedState?: LineScaleFormElementPreloadedState;
  onCancel: () => void;
  onSubmit: (sliderProps: LineScaleFormElementPreloadedState) => void;
  isEditLimited: boolean;
}

const LineScaleFormElementDialogContent: React.FC<Props> = ({
  preloadedState,
  onCancel,
  onSubmit,
  isEditLimited,
}: Props) => {
  return (
    <>
      <p className={styles.description}>
        Allow panelists to change a slider position to rate the samples for this parameter. Edit the
        values of the slider below.
      </p>
      {isEditLimited && (
        <Alert
          intent="warning"
          message="Editing is limited because some panelists already have submitted their answers."
          className={styles.editWarning}
        />
      )}
      <Formik
        onSubmit={onSubmit}
        initialValues={preloadedState ?? initialState}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form className={styles.form}>
            <div className={styles.nameAndGraphLabelInputsContainer}>
              <FormField
                label="Name"
                errorMessage={formik.errors.name}
                className={styles.nameFormField}
              >
                <Input
                  name="name"
                  placeholder="Parameter name"
                  isInvalid={Boolean(formik.errors.name)}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
              <FormField
                label="Chart label (optional)"
                errorMessage={formik.errors.dataLabel}
                className={styles.graphLabelFormField}
              >
                <Input
                  name="dataLabel"
                  placeholder="Chart label"
                  isInvalid={Boolean(formik.errors.dataLabel)}
                  value={formik.values.dataLabel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
            </div>
            <FormField
              label="Value boundaries"
              errorMessage={[
                formik.errors.minimumValue,
                formik.errors.maximumValue,
                formik.errors.defaultValue,
              ]}
            >
              <div className={styles.multipleInputs}>
                <Input
                  name="minimumValue"
                  type="number"
                  placeholder="Minimum"
                  isInvalid={Boolean(formik.errors.minimumValue)}
                  value={String(formik.values.minimumValue)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isDisabled={isEditLimited}
                />
                <Input
                  name="defaultValue"
                  type="number"
                  placeholder="Default"
                  isInvalid={Boolean(formik.errors.defaultValue)}
                  value={String(formik.values.defaultValue)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isDisabled={isEditLimited}
                />
                <Input
                  name="maximumValue"
                  type="number"
                  placeholder="Maximum"
                  isInvalid={Boolean(formik.errors.maximumValue)}
                  value={String(formik.values.maximumValue)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isDisabled={isEditLimited}
                />
              </div>
            </FormField>
            <FormField
              label="Text boundaries"
              errorMessage={[formik.errors.rightText as string, formik.errors.leftText as string]}
            >
              <div className={styles.multipleInputs}>
                <Input
                  name="leftText"
                  placeholder="Not present"
                  isInvalid={Boolean(formik.errors.leftText)}
                  value={String(formik.values.leftText)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isDisabled={isEditLimited}
                />
                <Input
                  name="rightText"
                  placeholder="Very noticeable"
                  isInvalid={Boolean(formik.errors.rightText)}
                  value={String(formik.values.rightText)}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isDisabled={isEditLimited}
                />
              </div>
            </FormField>

            <PreviewBox>
              <FormField label={formik.values.name || 'Parameter name'}>
                <Slider
                  value={formik.values.defaultValue}
                  isDisabled
                  valueBoundaries={
                    (formik.values.minimumValue || 0) < (formik.values.maximumValue || 0)
                      ? [formik.values.minimumValue || 0, formik.values.maximumValue || 0]
                      : [(formik.values.maximumValue || 1) - 1, formik.values.maximumValue || 1]
                  }
                  textBoundaries={[formik.values.leftText, formik.values.rightText]}
                />
              </FormField>
            </PreviewBox>

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={onCancel}>
                {preloadedState ? 'Cancel' : 'Back'}
              </Button>
              <Button name="save" htmlType="submit">
                {preloadedState ? 'Save' : 'Create'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LineScaleFormElementDialogContent;
