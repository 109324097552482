export interface Env {
  NODE_ENV: 'production' | 'development' | 'test';
  REACT_APP_VERSION: string;
  REACT_APP_HOSTING_ENV: 'production' | 'staging';
  REACT_APP_FIREBASE_PROJECT_ID: string;
  REACT_APP_FIREBASE_APP_ID: string;
  REACT_APP_FIREBASE_DATABASE_URL: string;
  REACT_APP_FIREBASE_STORAGE_BUCKET: string;
  REACT_APP_FIREBASE_LOCATION_ID: 'europe-west';
  REACT_APP_FIREBASE_API_KEY: string;
  REACT_APP_FIREBASE_AUTH_DOMAIN: string;
  REACT_APP_FIREBASE_MESSAGING_SENDER_IDx: string;
  REACT_APP_FIREBASE_MEASUREMENT_ID: string;
}

export const getEnvValue = (envKey: keyof Env): Env[keyof Env] | undefined => {
  return process.env[envKey];
};
