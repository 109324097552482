import React from 'react';
import { SensoryTestType } from '@bloodhound/common/dist/models/sensoryTest';
import clsx from 'clsx';

import styles from './ProgressBar.module.css';

type Props = {
  data?: number;
  subTitle?: React.ReactNode;
  legend?: React.ReactNode;
  className?: string;
  sensoryTestType: SensoryTestType;
};

const ProgressBar: React.FC<Props> = ({
  data,
  subTitle,
  legend,
  className,
  sensoryTestType,
}: Props) => {
  const containerStyles = clsx(styles.container, className);

  return (
    <div className={containerStyles}>
      <div className={styles.frame}>
        {typeof data !== 'undefined' && (
          <div
            className={styles.bar}
            style={{
              width: `${data * 100}%`,
              background: `linear-gradient(90deg, var(--${sensoryTestType}-1) 0%, var(--${sensoryTestType}-3) 100%)`,
            }}
          />
        )}
        {typeof data === 'undefined' && <span className={styles.noResults}>No results</span>}
      </div>
      <div className={styles.sub}>
        {subTitle && <span className={styles.subText}>{subTitle}</span>}
        {legend && <span className={styles.subText}>{legend}</span>}
      </div>
    </div>
  );
};

export default ProgressBar;
