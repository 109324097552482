import React from 'react';
import { Panelist } from '@bloodhound/common/dist/models/panelist';
import { User } from '@bloodhound/common/dist/models/user';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, FormField, Input, Modal } from 'ventura';
import * as Yup from 'yup';

import { FormErrors } from 'components/molecules';
import { usePanelistCreator } from 'services/panelistService';
import { useAuthentication } from 'services/userService';

import styles from './PanelistCreationDialog.module.css';

type FormValues = Omit<Panelist, 'id' | 'workspaceId' | 'dateCreated'>;

const defaultFormValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
};

interface Props {
  isOpen: boolean;
  onClose: (id?: string) => void;
  existingEmails: string[];
}

const PanelistCreationDialog: React.FC<Props> = ({ isOpen, onClose, existingEmails }: Props) => {
  const { createPanelist, error, clearError } = usePanelistCreator();
  const { user } = useAuthentication();

  const handleSubmit = async (formValues: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    const panelist: Omit<Panelist, 'id' | 'dateCreated'> = {
      workspaceId: (user as User).workspaceId,
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      email: formValues.email,
    };

    const panelistId = await createPanelist(panelist);

    if (panelistId) {
      formikHelpers.resetForm();
      onClose();
    }
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    clearError();
    onClose();
  };

  const firebaseErrors = error ? [error.message] : [];

  const formValidationSchema = Yup.object().shape<FormValues>({
    firstName: Yup.string().label('First name').required(),
    lastName: Yup.string().label('Last name').required(),
    email: Yup.string()
      .email('Not a valid email format')
      .label('Panelist email')
      .test(
        'emailExists',
        'This email has already been added',
        (value) => !existingEmails.includes(value?.toLowerCase() ?? ''),
      )
      .required(),
  });

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultFormValues}
      validationSchema={formValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik: FormikProps<FormValues>) => (
        <Modal isOpen={isOpen} title="New panelist">
          <Form>
            <div className={styles.firebaseErrors}>
              <FormErrors messages={firebaseErrors} />
            </div>

            <div className={styles.name}>
              <FormField
                label="First name"
                errorMessage={formik.errors.firstName as string}
                className={styles.nameField}
              >
                <Input
                  name="firstName"
                  placeholder="First name"
                  isInvalid={Boolean(formik.errors.firstName)}
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
              <div className={styles.spacer} />
              <FormField
                label="Last name"
                errorMessage={formik.errors.lastName as string}
                className={styles.nameField}
              >
                <Input
                  name="lastName"
                  placeholder="Last name"
                  isInvalid={Boolean(formik.errors.lastName)}
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
            </div>
            <FormField label="Email" errorMessage={formik.errors.email as string}>
              <Input
                name="email"
                placeholder="Email"
                isInvalid={Boolean(formik.errors.email)}
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
              />
            </FormField>

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={() => handleCancel(formik.resetForm)}>
                Cancel
              </Button>

              <Button name="create" isLoading={formik.isSubmitting} htmlType="submit">
                Create
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default PanelistCreationDialog;
