import React from 'react';

import spilledFlasks from 'assets/spilledFlasks.svg';
import { Placeholder } from 'components/atoms';
import { PageLayout } from 'components/templates';

import styles from './NotFound.module.css';

const NotFound: React.FC = () => {
  return (
    <PageLayout>
      <PageLayout.Main>
        <div className={styles.placeholderContainer}>
          <Placeholder
            image={spilledFlasks}
            title="Oops! This was not what we expected."
            subTitle="The page you requested was not found"
          />
        </div>
      </PageLayout.Main>
    </PageLayout>
  );
};

export default NotFound;
