import React from 'react';
import { Printer } from 'ventura';

import { ConfirmDialog } from 'components/molecules';
import { generateSampleCodeLabelsPdf } from 'utils/pdf/sample-codes';
import a4LabelsIllustration from '../../../assets/a4Labels.svg';

import styles from './PrintSampleCodes.module.css';

type Props = {
  isOpen: boolean;
  sampleCodeSets: number[][];
  onClose: () => void;
};

const PrintSampleCodesDialog: React.FC<Props> = ({ isOpen, sampleCodeSets, onClose }: Props) => {
  const openSampleCodesPdf = async () => {
    const blobUrl = await generateSampleCodeLabelsPdf(sampleCodeSets);
    window.open(blobUrl);
    onClose();
  };

  return (
    <ConfirmDialog
      isOpen={isOpen}
      cancelButtonOnClick={onClose}
      confirmButtonOnClick={openSampleCodesPdf}
      message={
        <div className={styles.modalContent}>
          <img
            className={styles.illustration}
            src={a4LabelsIllustration}
            alt="Preview of the A4 paper with the labels"
          />
          <div className={styles.paperInfo}>
            <span className={styles.title}>Recommended label paper</span>
            <p className={styles.description}>
              The following label paper is recommended to correctly outline text on the labels.
            </p>
            <span>Avery L4731-25 25,4 x 10 mm (189 labels A4)</span>
          </div>
        </div>
      }
      title="Print the sample codes"
      confirmButtonLabel={
        <>
          <Printer className={styles.printerIcon} />
          Print
        </>
      }
    />
  );
};

export default PrintSampleCodesDialog;
