export const factorial = (value: number) => {
  let factorialResult = 1;
  for (let i = 1; i <= value; i += 1) {
    factorialResult *= i;
  }

  return factorialResult;
};

export const sum = (numbers: number[]) =>
  numbers.reduce((accumulator, value) => value + accumulator, 0);

export const average = (numbers: number[]) => numbers.length && sum(numbers) / numbers.length;

export const median = (numbers: number[]) => {
  const sortedNumbers = [...numbers].sort();

  if (sortedNumbers.length === 0) {
    return 0;
  }

  sortedNumbers.sort((a, b) => a - b);

  const half = Math.floor(sortedNumbers.length / 2);

  if (sortedNumbers.length % 2) {
    return sortedNumbers[half];
  }

  return (sortedNumbers[half - 1] + sortedNumbers[half]) / 2.0;
};
