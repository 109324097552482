import React from 'react';
import { DiscriminativeTest } from '@bloodhound/common/dist/models/sensoryTest';
import clsx from 'clsx';
import { CheckCircle } from 'ventura';

import { createProbabilityString } from 'utils/math/statistics/basic';

import styles from './SignificanceResult.module.css';

interface Props {
  discriminationTestType: DiscriminativeTest['type'];
  probability: number | undefined;
  significanceLevel: number;
  className?: string;
}

const isSignificant = (probability: number | undefined, significanceLevel: number) =>
  (probability ?? 1) <= significanceLevel;

const getAppropriateText = (probability: number | undefined, significanceLevel: number) => {
  const isSignificantBoolean = isSignificant(probability, significanceLevel);

  if (!probability && probability !== 0) {
    return {
      title: 'Not enough panelists passed to determine significance.',
      description: 'Have panelists take the test to see results.',
    };
  }
  if (isSignificantBoolean) {
    return {
      title: `Significant difference detected for given significance level of ${significanceLevel}.`,
      description: `${createProbabilityString(probability)} ≤ ${significanceLevel} (p ≤ ⍺)`,
    };
  }
  return {
    title: `No significant difference detected for given significance level of ${significanceLevel}.`,
    description: `${
      probability !== undefined ? createProbabilityString(probability) : 'p'
    } ≰ ${significanceLevel} (p ≰ ɑ)`,
  };
};

const SignificanceResult: React.FC<Props> = ({
  discriminationTestType,
  probability,
  significanceLevel,
  className,
}) => {
  const isSignificantBoolean = isSignificant(probability, significanceLevel);
  const { title, description } = getAppropriateText(probability, significanceLevel);

  return (
    <div
      className={clsx(
        styles.significanceResult,
        {
          [styles.isSignificant]: isSignificantBoolean,
          [styles.triangleTest]: discriminationTestType === 'triangle',
          [styles.twoFiveTest]: discriminationTestType === 'twoFive',
        },
        className,
      )}
    >
      {isSignificantBoolean && (
        <CheckCircle
          className={styles.icon}
          style={{ color: isSignificantBoolean ? `var(--${discriminationTestType}-4)` : 'initial' }}
        />
      )}
      <dl className={styles.text}>
        <dt
          className={styles.title}
          style={{ color: isSignificantBoolean ? `var(--${discriminationTestType}-4)` : 'initial' }}
        >
          {title}
        </dt>
        <dd className={styles.description}>{description}</dd>
      </dl>
    </div>
  );
};

export default SignificanceResult;
