import React, { MouseEventHandler } from 'react';
import { Button, Modal } from 'ventura';

type Props = {
  title: string;
  isOpen: boolean;
  message: React.ReactNode;
  confirmButtonOnClick: MouseEventHandler<HTMLButtonElement>;
  cancelButtonOnClick: MouseEventHandler<HTMLButtonElement>;
  confirmButtonLabel?: string | React.ReactNode;
  cancelButtonLabel?: string | React.ReactNode;
  isLoading?: boolean;
  className?: string;
};

const ConfirmDialog: React.FC<Props> = ({
  title,
  isOpen,
  message,
  confirmButtonLabel = 'Confirm',
  cancelButtonLabel = 'Cancel',
  confirmButtonOnClick,
  cancelButtonOnClick,
  isLoading = false,
  className,
}: Props) => {
  const onConfirm = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (confirmButtonOnClick) {
      confirmButtonOnClick(e);
    }
  };

  const onCancel = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (cancelButtonOnClick) {
      cancelButtonOnClick(e);
    }
  };

  return (
    <Modal title={title} isOpen={isOpen} className={className}>
      {message}
      <Modal.Footer>
        <Button type="secondary" onClick={onCancel} name="cancel">
          {cancelButtonLabel}
        </Button>
        <Button isLoading={isLoading} onClick={onConfirm} name="confirm">
          {confirmButtonLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
