import { jsPDF as JsPdf } from 'jspdf';

// Label paper dimensions
const labelWidth = 25.4;
const labelHeight = 10;
const leftPageMargin = 9;
const topPageMargin = 15;
const columnGap = 2.5;
const maxLabelRowsPerPage = 27;
const maxLabelColumnsPerPage = 7;

const generateLabelPageData = (sampleCodeSets: number[][]): (number | undefined)[][][] => {
  const maxSetLength = sampleCodeSets.reduce((maxCount, set) => Math.max(maxCount, set.length), 0);
  const pages: (number | undefined)[][][] = [];

  if (maxSetLength <= 3) {
    const sampleSetsPerPage = maxLabelRowsPerPage * 2;
    for (let i = 0; i < sampleCodeSets.length; i += sampleSetsPerPage) {
      const sampleCodeSetsFirstColumn = sampleCodeSets.slice(i, i + maxLabelRowsPerPage);
      const sampleCodeSetsSecondColumn = sampleCodeSets.slice(
        i + maxLabelRowsPerPage,
        i + maxLabelRowsPerPage * 2,
      );
      pages.push(
        sampleCodeSetsFirstColumn.map((sampleCodeSet, index) => [
          ...sampleCodeSet,
          undefined,
          ...(sampleCodeSetsSecondColumn[index] ?? []),
        ]),
      );
    }
  } else if (maxSetLength <= 7) {
    for (let i = 0; i < sampleCodeSets.length; i += maxLabelRowsPerPage) {
      const sampleCodeSetsForPage = sampleCodeSets.slice(i, i + maxLabelRowsPerPage);
      pages.push(sampleCodeSetsForPage);
    }
  } else {
    for (let i = 0; i < sampleCodeSets.length; i += maxLabelRowsPerPage) {
      const sampleCodeSetsForPageUnlimitedColumns = sampleCodeSets.slice(
        i,
        i + maxLabelRowsPerPage,
      );
      for (
        let j = 0;
        j < sampleCodeSetsForPageUnlimitedColumns[i / maxLabelRowsPerPage].length;
        j += maxLabelColumnsPerPage
      ) {
        const sampleCodeSetsForPage = sampleCodeSetsForPageUnlimitedColumns.map((sampleCodeSet) =>
          sampleCodeSet.slice(j, j + maxLabelColumnsPerPage),
        );
        pages.push(sampleCodeSetsForPage);
      }
    }
  }

  return pages;
};

export const generateSampleCodeLabelsPdf = async (sampleCodeSets: number[][]): Promise<string> => {
  const doc = new JsPdf({ unit: 'mm' });
  doc.setProperties({ title: 'Sample code labels', creator: 'Sensly' });
  doc.setFontSize(14);

  const labelPageData = generateLabelPageData(sampleCodeSets);

  for (let pageIndex = 0; pageIndex < labelPageData.length; pageIndex++) {
    if (pageIndex !== 0) {
      doc.addPage();
    }

    for (let rowIndex = 0; rowIndex < labelPageData[pageIndex].length; rowIndex++) {
      const offsetTop = topPageMargin + rowIndex * labelHeight + labelHeight / 2;

      for (
        let columnIndex = 0;
        columnIndex < labelPageData[pageIndex][rowIndex].length;
        columnIndex++
      ) {
        let offsetLeft = leftPageMargin + columnIndex * (labelWidth + columnGap) + labelWidth / 2;
        doc.text(
          String(labelPageData[pageIndex][rowIndex][columnIndex] ?? ''),
          offsetLeft,
          offsetTop,
          {
            align: 'center',
          },
        );
      }
    }
  }

  return doc.output('bloburl').toString();
};
