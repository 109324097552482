import React from 'react';
import { QdaSample } from '@bloodhound/common/dist/models';
import clsx from 'clsx';

import { Card, ProgressCircle, SampleIcon } from '../../atoms';

import styles from './SampleResultsOverviewCard.module.css';

type Props = {
  sample: QdaSample;
  formsCompletedCount: number;
  formsInProgressCount: number;
  participantCount: number;
  sampleIndex: number;
  className?: string;
};

const SampleResultsOverviewCard: React.FC<Props> = ({
  sample,
  formsCompletedCount,
  formsInProgressCount,
  participantCount,
  sampleIndex,
  className,
}: Props) => {
  return (
    <Card className={clsx(className, styles.sampleCard)}>
      <div className={styles.sample}>
        <SampleIcon
          id={sample.id}
          index={sampleIndex}
          sensoryTestType="qda"
          className={styles.sampleIcon}
        />
        <span className={styles.sampleName}>{sample.name}</span>
      </div>
      <ProgressCircle
        className={styles.progressCircle}
        progressRatio={formsCompletedCount / participantCount || 0}
        progressRatioSecondary={
          (formsInProgressCount + formsCompletedCount) / participantCount || 0
        }
        sampleIndex={sampleIndex}
        sensoryTestType="qda"
      />
      <div className={styles.totals}>
        <span className={styles.totalNumber}>{formsCompletedCount}</span>
        <div className={styles.totalLegend}>
          <span className={styles.completedLegend}>Completed forms</span>
          <span className={styles.inProgressCount}>{formsInProgressCount} in progress</span>
        </div>
      </div>
    </Card>
  );
};

export default SampleResultsOverviewCard;
