import React from 'react';
import clsx from 'clsx';

import styles from './Step.module.css';

interface Props {
  number: number;
  title: string;
  className?: string;
}

const Step: React.FC<Props> = ({ title, number, className, children }) => {
  return (
    <div className={clsx(styles.step, className)}>
      <span className={styles.number}>{number}</span>
      <div className={styles.indented}>
        <h3 className={styles.title}>{title}</h3>
        {children}
      </div>
    </div>
  );
};

export default Step;
