import React, { ReactNode } from 'react';
import clsx from 'clsx';
import { Eye } from 'ventura';

import styles from './PreviewBox.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

const PreviewBox: React.FC<Props> = ({ children, className }: Props) => {
  const boxStyles = clsx(styles.box, className);

  return (
    <div className={boxStyles}>
      <span className={styles.text}>
        <Eye className={styles.icon} />
        View as panelist
      </span>
      {children}
    </div>
  );
};

export default PreviewBox;
