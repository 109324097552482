import React from 'react';
import clsx from 'clsx';

import styles from './Placeholder.module.css';

type Props = {
  image: string;
  title?: string;
  subTitle?: string;
  className?: string;
  imgSize?: 'small' | 'normal' | 'large';
};

const Placeholder: React.FC<Props> = ({
  image,
  title,
  subTitle,
  className,
  imgSize = 'normal',
}: Props) => {
  const containerStyles = clsx(styles.container, className);

  const imgStyles = clsx(styles.placeholderImage, {
    [styles.placeholderIconSmall]: imgSize === 'small',
    [styles.placeholderIconNormal]: imgSize === 'normal',
    [styles.placeholderIconLarge]: imgSize === 'large',
  });

  return (
    <div className={containerStyles}>
      <img className={imgStyles} src={image} alt="Not Found" />
      {title && <h1 className={styles.placeholderTitle}>{title}</h1>}
      {subTitle && <p className={styles.placeholderDescription}>{subTitle}</p>}
    </div>
  );
};

export default Placeholder;
