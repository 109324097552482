import 'firebase/firestore';
import { useEffect, useState } from 'react';
import { FirebaseError, FirestoreError } from '@bloodhound/common/dist/models/firebase';
import { SensoryTest } from '@bloodhound/common/dist/models/sensoryTest';

import { useFirebase } from 'components/providers/FirebaseProvider';
import { useAuthentication } from './userService';

export function useAllSensoryTests(): {
  sensoryTests: SensoryTest[];
  error?: FirebaseError;
  isLoading: boolean;
};

export function useAllSensoryTests<T extends SensoryTest>(
  sensoryTestType: T['type'],
): {
  sensoryTests: T[];
  error?: FirebaseError;
  isLoading: boolean;
};

export function useAllSensoryTests<T extends SensoryTest>(
  sensoryTestType?: T['type'],
): {
  sensoryTests: T[];
  error?: FirebaseError;
  isLoading: boolean;
} {
  const [sensoryTests, setSensoryTests] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();
  const { user, isLoading: isAuthLoading } = useAuthentication();

  useEffect(() => {
    setIsLoading(true);

    if (isAuthLoading) {
      return;
    }

    if (!user) {
      setIsLoading(false);
      setError(new FirebaseError('unauthenticated'));
      return;
    }

    let collection = firebase
      .firestore()
      .collection('sensoryTests')
      .where('workspaceId', '==', user.workspaceId)
      .orderBy('dateCreated', 'desc');

    if (sensoryTestType) {
      collection = collection.where('type', '==', sensoryTestType);
    }

    const subscription = collection.onSnapshot(
      (snapshot) => {
        const fetchedSensoryTests = snapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as T),
        );

        setSensoryTests(fetchedSensoryTests);
        setError(undefined);
        setIsLoading(false);
      },
      (e: Error) => {
        const exception = e as FirestoreError;

        setSensoryTests([]);
        setError(new FirebaseError(exception.code));
        setIsLoading(false);
      },
    );

    return subscription;
  }, [firebase, sensoryTestType, user, isAuthLoading]);

  return { sensoryTests, isLoading, error };
}

export const useSensoryTest = (
  id: string,
): {
  sensoryTest?: SensoryTest;
  isLoading: boolean;
  error?: FirebaseError;
} => {
  const [sensoryTest, setSensoryTest] = useState<SensoryTest>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();

  useEffect(() => {
    setIsLoading(true);

    const doc = firebase.firestore().collection('sensoryTests').doc(id);

    const subscription = doc.onSnapshot(
      (snapshot) => {
        setError(undefined);
        const snapshotData = snapshot.data();

        if (!snapshotData) {
          setError(new FirebaseError('not-found'));
        }

        const fetchedSensoryTest = snapshotData
          ? ({ ...snapshotData, id: snapshot.id } as SensoryTest)
          : undefined;
        setSensoryTest(fetchedSensoryTest);
        setIsLoading(false);
      },
      (e: Error) => {
        const exception = e as FirestoreError;

        setSensoryTest(undefined);
        setError(new FirebaseError(exception.code));
        setIsLoading(false);
      },
    );

    return subscription;
  }, [firebase, id]);

  return { sensoryTest, isLoading, error };
};

export const useSensoryTestCreator = (): {
  createSensoryTest: <T extends SensoryTest>(
    sensoryTest: Omit<T, 'id' | 'dateCreated'>,
  ) => Promise<string>;
  error?: FirebaseError;
  clearError: () => void;
  isCreatePending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isCreatePending, setIsCreatePending] = useState<boolean>(false);
  const firebase = useFirebase();

  const createSensoryTest = async <T extends SensoryTest>(
    sensoryTest: Omit<T, 'id' | 'dateCreated'>,
  ): Promise<string> => {
    setError(undefined);
    setIsCreatePending(true);

    const collection = firebase.firestore().collection('sensoryTests');

    try {
      const document = await collection.add({
        dateCreated: new Date(),
        ...sensoryTest,
      });
      firebase.analytics().logEvent('create_sensory_test', {
        id: document.id,
        name: sensoryTest.name,
        type: sensoryTest.type,
      });
      return document.id;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return '';
    } finally {
      setIsCreatePending(false);
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return { createSensoryTest, error, isCreatePending, clearError };
};

export const useSensoryTestEditor = (): {
  editSensoryTest: <T extends SensoryTest>(sensoryTest: T) => Promise<boolean>;
  error?: FirebaseError;
  clearError: () => void;
  isEditPending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isEditPending, setIsEditPending] = useState<boolean>(false);
  const firebase = useFirebase();

  const editSensoryTest = async <T extends SensoryTest>(sensoryTest: T): Promise<boolean> => {
    setError(undefined);
    setIsEditPending(true);
    const document = firebase.firestore().collection('sensoryTests').doc(sensoryTest.id);

    try {
      const { id, ...sensoryTestData } = sensoryTest;
      await document.update(sensoryTestData);
      return true;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return false;
    } finally {
      setIsEditPending(false);
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return { editSensoryTest, error, isEditPending, clearError };
};

export const useSensoryTestRemover = (): {
  removeSensoryTest: (sensoryTest: SensoryTest) => Promise<boolean>;
  error?: FirebaseError;
  isDeletePending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isDeletePending, setIsDeletePending] = useState<boolean>(false);
  const firebase = useFirebase();

  const removeSensoryTest = async (sensoryTest: SensoryTest): Promise<boolean> => {
    setIsDeletePending(true);
    setError(undefined);

    const collection = firebase.firestore().collection('sensoryTests');

    try {
      await collection.doc(sensoryTest.id).delete();
      return true;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return false;
    } finally {
      setIsDeletePending(false);
    }
  };

  return { removeSensoryTest, error, isDeletePending };
};
