import React from 'react';
import clsx from 'clsx';

import { median } from 'utils/math/basic';
import { calculateInterquartileRange } from 'utils/math/statistics/basic';
import { getCssVariable } from 'utils/style';

import styles from './BoxPlot.module.css';

type Props = {
  values: number[];
  minimumValue: number;
  maximumValue: number;
  colorIndex: number;
  sampleId: number;
  className?: string;
};

const BoxPlot: React.FC<Props> = ({
  values,
  colorIndex,
  sampleId,
  minimumValue,
  maximumValue,
  className,
}: Props) => {
  const medianValue = median(values);
  const [quartile1, quartile3] = calculateInterquartileRange(values);
  const interquartileRange = quartile3 - quartile1;
  const lineScaleRange = maximumValue - minimumValue;
  const sortedValues = [...values].sort((a, b) => a - b);
  const lowestValue = sortedValues[0];
  const highestValue = sortedValues[sortedValues.length - 1];
  const boxMinimumValue = Math.max(quartile1 - 1.5 * interquartileRange, lowestValue);
  const boxMaximumValue = Math.min(quartile3 + 1.5 * interquartileRange, highestValue);
  const outliers = Array.from(new Set(sortedValues)).filter(
    (value) => value < boxMinimumValue || value > boxMaximumValue,
  );

  return (
    <div className={clsx(styles.boxPlot, className)} key={sampleId}>
      <span className={styles.minimumValue}>{minimumValue}</span>
      <div className={styles.bar}>
        <div className={styles.line} />
        <div
          className={styles.whiskers}
          style={{
            left: `${((boxMinimumValue - minimumValue) / lineScaleRange) * 100}%`,
            width: `${((boxMaximumValue - boxMinimumValue) / lineScaleRange) * 100}%`,
            backgroundColor: getCssVariable(`--qda-sample-${colorIndex + 1}-1`),
          }}
        />
        <div
          className={styles.interquartileRange}
          style={{
            left: `${((quartile1 - minimumValue) / lineScaleRange) * 100}%`,
            width: `${(interquartileRange / lineScaleRange) * 100}%`,
            backgroundColor: getCssVariable(`--qda-sample-${colorIndex + 1}-2`),
          }}
        />
        {outliers.map((outlier) => (
          <div
            key={outlier}
            className={styles.outlier}
            style={{
              left: `${((outlier - minimumValue) / lineScaleRange) * 100}%`,
            }}
          />
        ))}
        {values.length > 0 ? (
          <>
            <div
              className={styles.medianLine}
              style={{
                left: `${((medianValue - minimumValue) / lineScaleRange) * 100}%`,
              }}
            />
            <div
              className={styles.medianValue}
              style={{
                left: `${((medianValue - minimumValue) / lineScaleRange) * 100}%`,
              }}
            >
              {medianValue.toPrecision(2)}
            </div>
          </>
        ) : (
          <span className={styles.noData}>No results yet</span>
        )}
      </div>
      <span className={styles.maximumValue}>{maximumValue}</span>
    </div>
  );
};

export default BoxPlot;
