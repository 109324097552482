import { factorial } from '../basic';

const calculateBinomialProbability = (
  correctCount: number,
  totalCount: number,
  correctnessChance: number,
) => {
  const incorrectCount = totalCount - correctCount;

  // n = total, correct = y, p = 1/3
  //
  //       n
  // ------------- * p^y * p^(n-y)
  // y! * (n - y)!
  const result =
    (factorial(totalCount) / (factorial(correctCount) * factorial(incorrectCount))) *
    correctnessChance ** correctCount *
    (1 - correctnessChance) ** incorrectCount;

  return result;
};

// Not using Binomial from lib-r-math.js because it's slow
// Custom function - test run in 4ms
// lib-r-math.js - test run in 505ms
export const calculateCumulativeBinomialProbability = (
  successCount: number,
  totalCount: number,
  correctnessChance: number,
) => {
  const cumulativeY = Array.from(new Array(successCount)).reduce((sum, empty, index) => {
    return sum + calculateBinomialProbability(index, totalCount, correctnessChance);
  }, 0);
  return 1 - cumulativeY;
};
