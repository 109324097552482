import React, { useState } from 'react';
import { Button, GitCommit, Maximize2, Modal, Table } from 'ventura';

import { BoxPlot as BoxPlotComponent, SampleIcon } from 'components/atoms';
import { average } from 'utils/math/basic';
import {
  calculateAnovaProbability,
  calculateTukeyHsdProbabilities,
  TukeyHsdResult,
} from 'utils/math/statistics/anova';
import { calculateStandardError, createProbabilityString } from 'utils/math/statistics/basic';
import { getCssVariable } from 'utils/style';

import styles from './LineScaleResult.module.css';

export interface BoxPlot {
  sampleId: number;
  sampleName: string;
  values: number[];
  colorIndex: number;
}

type Props = {
  name: string;
  minimumValue: number;
  maximumValue: number;
  boxPlots: BoxPlot[];
  significanceLevel: number;
  className?: string;
};

const LineScaleResult: React.FC<Props> = ({
  name,
  minimumValue,
  maximumValue,
  boxPlots,
  significanceLevel,
  className,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const areThereResults = boxPlots.flatMap(({ values }) => values).length > 0;
  const canCalculateAnova = areThereResults && boxPlots.length >= 2;

  let anovaProbability = 0,
    isSignificantDifference = false,
    tukeyHsdProbabilities: TukeyHsdResult[] = [],
    sampleCodes = boxPlots.map(({ sampleId }) => sampleId),
    standardErrorMean = average(boxPlots.map(({ values }) => calculateStandardError(values))) || 0;

  if (canCalculateAnova) {
    const values = boxPlots
      .map((boxPlotData) => boxPlotData.values)
      .filter((array) => array.length !== 0);
    anovaProbability = calculateAnovaProbability(...values);
    isSignificantDifference = anovaProbability < significanceLevel;

    if (isSignificantDifference) {
      tukeyHsdProbabilities = calculateTukeyHsdProbabilities(
        boxPlots.reduce(
          (accumulator, boxPlotData) => ({
            [String(boxPlotData.sampleId)]: boxPlotData.values,
            ...accumulator,
          }),
          {},
        ),
      );
    }
  }

  return (
    <>
      <div className={className}>
        <div className={styles.header}>
          <div className={styles.title}>
            <GitCommit />
            <span className={styles.formElementName}>{name}</span>
            <span className={styles.formElementType}>Line scale</span>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>
            <Maximize2 />
          </Button>
        </div>
        {areThereResults ? (
          boxPlots.map((boxPlotData) => (
            <BoxPlotComponent
              key={boxPlotData.sampleId}
              colorIndex={boxPlotData.colorIndex}
              values={boxPlotData.values}
              minimumValue={minimumValue}
              maximumValue={maximumValue}
              sampleId={boxPlotData.sampleId}
            />
          ))
        ) : (
          <span className={styles.placeholder}>No results yet</span>
        )}
        {canCalculateAnova && isSignificantDifference && (
          <span className={styles.differenceText}>
            <span className={styles.significanceBullet} />
            Significant difference between all samples (p=
            {createProbabilityString(anovaProbability)})
          </span>
        )}
        {canCalculateAnova && !isSignificantDifference && (
          <span className={styles.noDifferenceText}>
            No difference between all samples. (p=
            {createProbabilityString(anovaProbability)})
          </span>
        )}
      </div>

      <Modal isOpen={isModalOpen} title="Parameter results" className={styles.modal}>
        <div className={styles.title}>
          <GitCommit />
          <span className={styles.formElementName}>{name}</span>
          <span className={styles.formElementType}>Line scale</span>
        </div>
        <h4 className={styles.subTitle}>Distribution</h4>
        <div className={styles.distributionRow}>
          <p className={styles.text}>
            Answers from all panelists are shown in a <strong>box plot</strong> for each sample.
          </p>
          <div className={styles.legendItem}>
            <span className={styles.median}>Median</span>
          </div>
        </div>
        {boxPlots.map((boxPlotData) => (
          <div className={styles.distributionRow} key={boxPlotData.sampleId}>
            <div className={styles.sampleBoxPlot}>
              <div className={styles.sampleTitle}>
                <span className={styles.sample}>Sample: </span>
                <SampleIcon
                  id={boxPlotData.sampleId}
                  index={boxPlotData.colorIndex}
                  sensoryTestType="qda"
                />
                <span className={styles.sampleName}>{boxPlotData.sampleName}</span>
              </div>
              <BoxPlotComponent
                colorIndex={boxPlotData.colorIndex}
                values={boxPlotData.values}
                minimumValue={minimumValue}
                maximumValue={maximumValue}
                sampleId={boxPlotData.sampleId}
              />
            </div>
            <div className={styles.legendItem}>
              <span className={styles.interquartileRange}>
                <span
                  className={styles.bullet}
                  style={{
                    backgroundColor: getCssVariable(`--qda-sample-${boxPlotData.colorIndex + 1}-2`),
                  }}
                ></span>
                Interquartile range
              </span>
              <span className={styles.quartiles}>
                <span
                  className={styles.bullet}
                  style={{
                    backgroundColor: getCssVariable(`--qda-sample-${boxPlotData.colorIndex + 1}-1`),
                  }}
                ></span>
                Lower/Upper quartile
              </span>
            </div>
          </div>
        ))}
        <h4 className={styles.subTitle}>Significant difference</h4>
        {canCalculateAnova ? (
          <>
            <p className={styles.text}>
              The probability for a significant difference needs to be calculated in order to
              scientifically prove that there is a noticeable difference between the samples. The
              probability (p) is calculated using <strong>Analysis of Variance</strong> (ANOVA).
            </p>
            <Table>
              <Table.Header>
                <Table.Cell>Probability (p)</Table.Cell>
                <Table.Cell>Standard error mean</Table.Cell>
                <Table.Cell>Conclusion</Table.Cell>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell>{createProbabilityString(anovaProbability)}</Table.Cell>
                  <Table.Cell>{createProbabilityString(standardErrorMean)}</Table.Cell>
                  <Table.Cell>
                    {isSignificantDifference
                      ? 'Significant difference detected'
                      : 'No significant difference detected'}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
            {isSignificantDifference && (
              <>
                <h4 className={styles.subTitle}>Significantly different sample pairs</h4>
                <p className={styles.text}>
                  More tests are required to find out which samples significantly differ from each
                  other. Each sample combination is tested using the{' '}
                  <strong>Tuckey-Kramer post-hoc test</strong>.
                </p>
                <Table>
                  <Table.Header>
                    <Table.Cell>Sample pair</Table.Cell>
                    <Table.Cell>Probability (p)</Table.Cell>
                    <Table.Cell>Standard error mean</Table.Cell>
                  </Table.Header>
                  <Table.Body>
                    {tukeyHsdProbabilities?.map((tuckeyResult) => (
                      <Table.Row key={tuckeyResult.names.join('-')}>
                        <Table.Cell>
                          <SampleIcon
                            id={tuckeyResult.names[0]}
                            index={sampleCodes.indexOf(Number(tuckeyResult.names[0]))}
                            sensoryTestType="qda"
                            className={styles.sampleIcon}
                          />
                          <SampleIcon
                            id={tuckeyResult.names[1]}
                            index={sampleCodes.indexOf(Number(tuckeyResult.names[1]))}
                            sensoryTestType="qda"
                            className={styles.sampleIcon}
                          />
                        </Table.Cell>
                        <Table.Cell>
                          {tuckeyResult.probability < significanceLevel && (
                            <span className={styles.significanceBullet} />
                          )}
                          {tuckeyResult.probability > significanceLevel &&
                            tuckeyResult.probability - 0.05 < significanceLevel && (
                              <span className={styles.almostSignificanceBullet} />
                            )}
                          {createProbabilityString(tuckeyResult.probability)}
                        </Table.Cell>
                        <Table.Cell>
                          {createProbabilityString(tuckeyResult.standardErrorMean)}
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <ul className={styles.horizontalLegend}>
                  <li className={styles.diff}>Significantly different (p &lt; 0.050)</li>
                  <li className={styles.almost}>Almost significantly different (p &lt; 0.100)</li>
                </ul>
              </>
            )}
          </>
        ) : (
          <p className={styles.text}>
            The significant difference can not be calculated because there are not enough samples or
            results to compare.
          </p>
        )}
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default LineScaleResult;
