import React from 'react';
import { FieldArray, Form, Formik } from 'formik';
import { Alert, Button, FormField, Input, Modal, PlusCircle, Radio, Trash2 } from 'ventura';
import * as Yup from 'yup';

import { PreviewBox } from 'components/atoms';

import styles from './CategoryScaleFormElementDialogContent.module.css';

export interface CategoryScaleFormElementPreloadedState {
  name: string;
  dataLabel?: string;
  options: string[];
}

const formValidationSchema = Yup.object().shape<CategoryScaleFormElementPreloadedState>({
  name: Yup.string().label('Parameter name').required(),
  dataLabel: Yup.string().label('Chart label'),
  options: Yup.array<string>(Yup.string().required('Empty fields are not allowed'))
    .label('Options')
    .required('There should be at least 2 options'),
});

const initialState: CategoryScaleFormElementPreloadedState = {
  name: '',
  options: ['', ''],
};

interface Props {
  preloadedState?: CategoryScaleFormElementPreloadedState;
  onCancel: () => void;
  onSubmit: (sliderProps: CategoryScaleFormElementPreloadedState) => void;
  isEditLimited: boolean;
}

const CategoryScaleFormElementDialogContent: React.FC<Props> = ({
  preloadedState,
  onCancel,
  onSubmit,
  isEditLimited,
}: Props) => {
  return (
    <>
      <p className={styles.description}>
        This type of parameter allows the panelist to pick only one option that resembles the
        closest to the taste, odor or texture of the sample.
      </p>
      {isEditLimited && (
        <Alert
          intent="warning"
          message="Editing is limited because some panelists already have submitted their answers."
          className={styles.editWarning}
        />
      )}
      <Formik
        onSubmit={onSubmit}
        initialValues={preloadedState ?? initialState}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form className={styles.form}>
            <div className={styles.nameAndGraphLabelInputsContainer}>
              <FormField
                label="Name"
                errorMessage={formik.errors.name}
                className={styles.nameFormField}
              >
                <Input
                  name="name"
                  placeholder="Parameter name"
                  isInvalid={Boolean(formik.errors.name)}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
              <FormField
                label="Chart label"
                errorMessage={formik.errors.dataLabel}
                className={styles.graphLabelFormField}
              >
                <Input
                  name="dataLabel"
                  placeholder="Chart label (optional)"
                  isInvalid={Boolean(formik.errors.dataLabel)}
                  value={formik.values.dataLabel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
            </div>
            <FormField
              label="Multiple choice answers"
              errorMessage={
                typeof formik.errors.options === 'string' ? formik.errors.options : undefined
              }
              className={styles.optionsFormField}
            >
              <FieldArray
                name="options"
                render={(arrayHelpers) => (
                  <>
                    {formik.values.options.map((option, index) => {
                      const errorMessage = Array.isArray(formik.errors.options)
                        ? formik.errors.options?.[index]
                        : undefined;
                      return (
                        <FormField
                          // eslint-disable-next-line react/no-array-index-key
                          key={index}
                          errorMessage={errorMessage}
                          className={styles.optionRow}
                        >
                          <div className={styles.optionRowWrapper}>
                            <Input
                              name={`options.${index}`}
                              placeholder="Option"
                              isInvalid={Boolean(errorMessage)}
                              value={option}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              className={styles.optionInput}
                              isDisabled={isEditLimited}
                            />
                            {index > 1 && (
                              <Button
                                className={styles.deleteButton}
                                onClick={() => {
                                  arrayHelpers.remove(index);
                                }}
                                type="secondary"
                                name={`category-scale-option-${index}-delete`}
                              >
                                <Trash2 />
                              </Button>
                            )}
                          </div>
                        </FormField>
                      );
                    })}
                    <Button
                      prefixIcon={<PlusCircle />}
                      onClick={() => {
                        arrayHelpers.push('');
                      }}
                      isDisabled={isEditLimited}
                    >
                      Add option
                    </Button>
                  </>
                )}
              />
            </FormField>

            <PreviewBox>
              <FormField label={formik.values.name || 'Parameter name'}>
                <Radio.Group name="preview-options" className={styles.previewRadioGroup}>
                  {formik.values.options.map((option, index) => (
                    <Radio.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      label={option || 'Option'}
                      value={String(index)}
                      className={styles.previewRadioItem}
                    />
                  ))}
                </Radio.Group>
              </FormField>
            </PreviewBox>

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={onCancel}>
                {preloadedState ? 'Cancel' : 'Back'}
              </Button>
              <Button name="save" htmlType="submit">
                {preloadedState ? 'Save' : 'Create'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CategoryScaleFormElementDialogContent;
