import React, { useState } from 'react';
import {
  DiscriminativeTestSettings,
  SensoryTestType,
  sensoryTestTypeNames,
} from '@bloodhound/common/dist/models/sensoryTest';
import { TriangleTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, Button, FormField, Radio, TextArea } from 'ventura';
import * as Yup from 'yup';

import { Step, SvgIcon } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';
import { TriangleTestParticipantFormResult } from 'components/molecules';
import { useFirebase } from 'components/providers/FirebaseProvider';

import styles from './TriangleTestParticipantForm.module.css';

interface FormValues {
  selectedSampleId?: string;
  motivation?: string;
}

const defaultFormValues: FormValues = {
  selectedSampleId: undefined,
  motivation: '',
};

const getFormValidationSchema = (sampleIds: number[], isCommentFieldRequired: boolean) =>
  Yup.object().shape<FormValues>({
    selectedSampleId: Yup.string()
      .label('different sample')
      .oneOf(sampleIds.map((id) => String(id)))
      .required(),
    motivation: isCommentFieldRequired
      ? Yup.string().label('Motivation').required()
      : Yup.string().label('Motivation'),
  });

interface Props {
  participant: TriangleTestParticipant;
  instructions: string;
  onSubmit: (participant: TriangleTestParticipant) => Promise<boolean>;
  error?: string;
  settings: DiscriminativeTestSettings;
}

const TriangleTestParticipantForm: React.FC<Props> = ({
  participant,
  instructions,
  onSubmit,
  error,
  settings,
}) => {
  const [submittedParticipant, setUpdatedParticipant] = useState<TriangleTestParticipant>();
  const firebase = useFirebase();

  const handleSubmit = async (formValues: FormValues) => {
    const newParticipant: TriangleTestParticipant = {
      ...(participant as TriangleTestParticipant),
      motivation: formValues.motivation,
      selectedSampleId: Number(formValues.selectedSampleId),
    };

    const isSubmitSuccessful = await onSubmit(newParticipant);

    if (isSubmitSuccessful) {
      setUpdatedParticipant(newParticipant);
      firebase.analytics().logEvent('participant_form_submit', {
        participantId: participant.id,
        sensoryTestType: 'triangle' as SensoryTestType,
      });
    }
  };

  const sampleIds = [
    participant.firstSampleId,
    participant.secondSampleId,
    participant.thirdSampleId,
  ];

  return (
    <>
      <header className={styles.header}>
        <div className={styles.title}>
          <SvgIcon
            icon={`sensoryTests/${participant.sensoryTest.type}` as SvgIconName}
            className={styles.icon}
          />
          <h1>{sensoryTestTypeNames[participant.sensoryTest.type]}</h1>
        </div>
      </header>
      {error && <Alert className={styles.error} intent="error" message={error} />}
      {submittedParticipant ? (
        <TriangleTestParticipantFormResult
          participant={submittedParticipant as TriangleTestParticipant}
        />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultFormValues}
          validationSchema={getFormValidationSchema(sampleIds, settings.isCommentFieldRequired)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik: FormikProps<FormValues>) => (
            <Form className={styles.form}>
              <Step number={1} title="Taste the samples in the correct order">
                <ul className={styles.sampleIdsOrder}>
                  {sampleIds.map((id) => (
                    <li key={id} className={styles.id}>
                      {id}
                    </li>
                  ))}
                </ul>
                <p className={styles.info}>{instructions}</p>
              </Step>
              <Step number={2} title="Select the different sample">
                <FormField errorMessage={formik.errors.selectedSampleId}>
                  <Radio.Group
                    name="selectedSampleId"
                    value={formik.values.selectedSampleId}
                    onChange={formik.handleChange}
                  >
                    {sampleIds.map((id) => (
                      <Radio.Item key={id} label={id} value={String(id)} />
                    ))}
                  </Radio.Group>
                </FormField>
              </Step>
              <Step
                number={3}
                title={`Motivate your decision${
                  settings.isCommentFieldRequired ? '' : ' (optional)'
                }`}
              >
                <FormField errorMessage={formik.errors.motivation}>
                  <TextArea
                    name="motivation"
                    value={formik.values.motivation}
                    onChange={formik.handleChange}
                    className={styles.motivation}
                  />
                </FormField>
              </Step>
              <div className={styles.buttons}>
                <Button
                  name="submit"
                  htmlType="submit"
                  isLoading={formik.isSubmitting}
                  className={styles.submit}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default TriangleTestParticipantForm;
