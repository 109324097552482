import React, { useState } from 'react';
import { Tag } from '@bloodhound/common/dist/models';
import {
  QdaTest,
  SensoryTest,
  SensoryTestType,
  sensoryTestTypeNames,
  TriangleTest,
  TwoFiveTest,
} from '@bloodhound/common/dist/models/sensoryTest';
import { User } from '@bloodhound/common/dist/models/user';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, FormField, Input, Modal, Radio, XCircle } from 'ventura';
import * as Yup from 'yup';

import { SvgIcon } from 'components/atoms';
import {
  FormErrors,
  QdaTestForm,
  SensoryTestTagInput,
  triangleTestDefaultFormValues,
  TriangleTestForm,
  triangleTestFormValidationSchema,
  TriangleTestFormValues,
} from 'components/molecules';
import {
  qdaTestDefaultFormValues,
  qdaTestFormValidationSchema,
  QdaTestFormValues,
} from 'components/molecules/QdaTestForm/QdaTestForm';
import TwoFiveTestForm, {
  twoFiveTestDefaultFormValues,
  twoFiveTestFormValidationSchema,
  TwoFiveTestFormValues,
} from 'components/molecules/TwoFiveTestForm/TwoFiveTestForm';
import { useSensoryTestCreator } from 'services/sensoryTestService';
import { useAuthentication } from 'services/userService';
import { useWorkspace } from 'services/workspaceService';
import { generateAmountOfSampleIds } from 'utils/random';
import { TagColorBackground, TagColorValues } from 'utils/workspace';

import styles from './SensoryTestCreationDialog.module.css';

type SensoryTestFormValues = Omit<
  SensoryTest,
  'id' | 'workspaceId' | 'dateCreated' | 'significanceLevel' | 'tags'
>;
type FormValues = SensoryTestFormValues &
  TriangleTestFormValues &
  QdaTestFormValues &
  TwoFiveTestFormValues;

const defaultFormValues: FormValues = {
  type: 'triangle',
  name: '',
  ...triangleTestDefaultFormValues,
  ...qdaTestDefaultFormValues,
  ...twoFiveTestDefaultFormValues,
};

interface Props {
  isOpen: boolean;
  onClose: (id?: string) => void;
}

enum FormStep {
  TestType,
  TestDetails,
}

const SensoryTestCreationDialog: React.FC<Props> = ({ isOpen, onClose }: Props) => {
  const { createSensoryTest, error, clearError } = useSensoryTestCreator();
  const { user } = useAuthentication();
  const { workspace } = useWorkspace(user?.workspaceId);

  const [currentFormStep, setCurrentFormStep] = useState(FormStep.TestType);

  const submitTriangleTest = async (formValues: FormValues) => {
    const triangleTest: Omit<TriangleTest, 'id' | 'dateCreated'> = {
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      type: 'triangle',
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      sampleNameA: formValues.sampleNameA!,
      sampleCharA: formValues.sampleCharA!,
      sampleNameB: formValues.sampleNameB!,
      sampleCharB: formValues.sampleCharB!,
      significanceLevel: Number(formValues.significanceLevel),
      settings: {
        usesSimplifiedSampleIds: formValues.settings.usesSimplifiedSampleIds,
        isCommentFieldRequired: formValues.settings.isCommentFieldRequired,
      },
      sampleIdsA: [],
      sampleIdsB: [],
      tags: formValues.tags,
    };

    if (triangleTest.settings.usesSimplifiedSampleIds) {
      const [sampleId1, sampleId2, sampleId3, sampleId4] = generateAmountOfSampleIds(4);

      triangleTest.sampleIdsA = [sampleId1, sampleId2];
      triangleTest.sampleIdsB = [sampleId3, sampleId4];
    }

    return createSensoryTest<TriangleTest>(triangleTest);
  };

  const submitTwoFiveTest = async (formValues: FormValues) => {
    const twoFiveTest: Omit<TwoFiveTest, 'id' | 'dateCreated'> = {
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      type: 'twoFive',
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      sampleNameA: formValues.sampleNameA!,
      sampleCharA: formValues.sampleCharA!,
      sampleNameB: formValues.sampleNameB!,
      sampleCharB: formValues.sampleCharB!,
      significanceLevel: Number(formValues.significanceLevel),
      settings: {
        usesSimplifiedSampleIds: formValues.settings.usesSimplifiedSampleIds,
        isCommentFieldRequired: formValues.settings.isCommentFieldRequired,
      },
      sampleIdsA: [],
      sampleIdsB: [],
      tags: formValues.tags,
    };

    if (twoFiveTest.settings.usesSimplifiedSampleIds) {
      const [
        sampleId1,
        sampleId2,
        sampleId3,
        sampleId4,
        sampleId5,
        sampleId6,
      ] = generateAmountOfSampleIds(6);

      twoFiveTest.sampleIdsA = [sampleId1, sampleId2, sampleId3];
      twoFiveTest.sampleIdsB = [sampleId4, sampleId5, sampleId6];
    }

    return createSensoryTest<TwoFiveTest>(twoFiveTest);
  };

  const submitQdaTest = async (formValues: FormValues) => {
    const qdaTest: Omit<QdaTest, 'id' | 'dateCreated'> = {
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      type: 'qda',
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      samples: formValues.samples!,
      formElements: [],
      significanceLevel: Number(formValues.significanceLevel),
      tags: formValues.tags,
    };

    return createSensoryTest<QdaTest>(qdaTest);
  };

  const handleSubmit = async (formValues: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    let sensoryTestId = '';

    if (formValues.type === 'triangle') {
      const triangleTestId = await submitTriangleTest(formValues);
      sensoryTestId = triangleTestId;
    } else if (formValues.type === 'qda') {
      const qdaTestId = await submitQdaTest(formValues);
      sensoryTestId = qdaTestId;
    } else if (formValues.type === 'twoFive') {
      const fiveTwoTestId = await submitTwoFiveTest(formValues);
      sensoryTestId = fiveTwoTestId;
    }

    if (sensoryTestId) {
      formikHelpers.resetForm();
      onClose(sensoryTestId);
    }
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    clearError();
    onClose();
  };

  const firebaseErrors = error ? [error.message] : [];

  const formValidationSchema = Yup.object()
    .concat(triangleTestFormValidationSchema)
    .concat(qdaTestFormValidationSchema)
    .concat(twoFiveTestFormValidationSchema)
    .shape<SensoryTestFormValues>({
      type: Yup.string().oneOf(['triangle', 'qda', 'twoFive']).label('Test type').required(),
      name: Yup.string().label('Sensory test name').required(),
      instructions: Yup.string<string>().label('Participant instructions'),
      purpose: Yup.string<string>().label('The purpose of this test (optional)'),
    });

  const getModalTitle = (testType: SensoryTestType) => {
    if (currentFormStep === FormStep.TestType) {
      return 'New sensory test';
    }

    return sensoryTestTypeNames[testType];
  };

  const setDefaultInstructions = (
    sensoryTestType: SensoryTestType,
    formik: FormikProps<FormValues>,
  ) => {
    const qdaDefaultInstructions = '';
    const triangleAndTwoFiveDefaultInstructions =
      'After all samples have been tasted, you can taste again in any order.';

    if (
      (sensoryTestType === 'triangle' || sensoryTestType === 'twoFive') &&
      formik.values.instructions === qdaDefaultInstructions
    ) {
      formik.setFieldValue('instructions', triangleAndTwoFiveDefaultInstructions);
    } else if (
      sensoryTestType === 'qda' &&
      formik.values.instructions === triangleAndTwoFiveDefaultInstructions
    ) {
      formik.setFieldValue('instructions', qdaDefaultInstructions);
    }
  };

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={defaultFormValues}
      validationSchema={formValidationSchema}
      validateOnChange={false}
      validateOnBlur={false}
    >
      {(formik: FormikProps<FormValues>) => (
        <Modal isOpen={isOpen} title={getModalTitle(formik.values.type)}>
          <Form>
            <div className={styles.firebaseErrors}>
              <FormErrors messages={firebaseErrors} />
            </div>
            {currentFormStep === FormStep.TestType && (
              <>
                <FormField label="Test type">
                  <Radio.Group
                    name="type"
                    onChange={(e) => {
                      setDefaultInstructions(e.target.value as SensoryTestType, formik);
                      formik.handleChange(e);
                    }}
                    value={formik.values.type}
                  >
                    <Radio.Item
                      label={
                        <>
                          <SvgIcon
                            icon="sensoryTests/triangle"
                            className={styles.sensoryTestIcon}
                          />{' '}
                          {sensoryTestTypeNames.triangle}
                        </>
                      }
                      value="triangle"
                      description="Compare 2 samples to find out if there is a significant difference in taste or odor. Panelists have to pick the different sample out of 3."
                    />
                    <Radio.Item
                      label={
                        <>
                          <SvgIcon icon="sensoryTests/qda" className={styles.sensoryTestIcon} />
                          {sensoryTestTypeNames.qda}
                        </>
                      }
                      value="qda"
                      description="Compare multiple samples on a set of parameters. Parameters can be rated by the panelists on different scales."
                    />
                    <Radio.Item
                      label={
                        <>
                          <SvgIcon icon="sensoryTests/twoFive" className={styles.sensoryTestIcon} />
                          {sensoryTestTypeNames.twoFive}
                        </>
                      }
                      value="twoFive"
                      description="Compare 2 samples to find out if there is a significant difference in taste or odor. Panelists have to pick 2 equal samples out of 5 samples."
                    />
                  </Radio.Group>
                </FormField>
              </>
            )}
            {currentFormStep === FormStep.TestDetails && (
              <>
                <div className={styles.formGroup}>
                  <div>
                    <FormField
                      label="Sensory test name"
                      errorMessage={formik.errors.name as string}
                    >
                      <Input
                        name="name"
                        placeholder="Sensory test name"
                        isInvalid={Boolean(formik.errors.name)}
                        value={formik.values.name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </FormField>
                    <FieldArray
                      name="tags"
                      render={(arrayHelpers) => (
                        <div className={styles.tagsContainer}>
                          {formik.values.tags?.map((tag, index) => (
                            <div
                              key={index}
                              className={styles.tag}
                              style={{
                                backgroundColor: `${TagColorBackground[tag.color]}`,
                                color: TagColorValues[tag.color],
                              }}
                            >
                              <span>{tag.name}</span>
                              <span
                                className={styles.tagAction}
                                onClick={() => arrayHelpers.remove(index)}
                              >
                                <XCircle />
                              </span>
                            </div>
                          ))}
                        </div>
                      )}
                    />
                  </div>
                  <SensoryTestTagInput
                    options={workspace?.tags || []}
                    onTagSelected={(tag: Tag) => {
                      if (formik.values.tags === undefined) {
                        formik.setFieldValue('tags', [tag]);
                      } else if (!formik.values.tags.some((t) => t.id === tag.id)) {
                        formik.setFieldValue('tags', [...formik.values.tags, tag]);
                      }
                    }}
                  />
                </div>
                {formik.values.type === 'triangle' && (
                  <TriangleTestForm
                    formik={(formik as unknown) as FormikProps<TriangleTestFormValues>}
                  />
                )}
                {formik.values.type === 'qda' && (
                  <QdaTestForm formik={(formik as unknown) as FormikProps<QdaTestFormValues>} />
                )}
                {formik.values.type === 'twoFive' && (
                  <TwoFiveTestForm
                    formik={(formik as unknown) as FormikProps<TwoFiveTestFormValues>}
                  />
                )}
              </>
            )}
            <Modal.Footer>
              {currentFormStep === FormStep.TestType && (
                <Button
                  name="cancel"
                  type="secondary"
                  onClick={() => handleCancel(formik.resetForm)}
                >
                  Cancel
                </Button>
              )}
              {currentFormStep === FormStep.TestType && (
                <Button name="next" onClick={() => setCurrentFormStep(FormStep.TestDetails)}>
                  Next
                </Button>
              )}
              {currentFormStep === FormStep.TestDetails && (
                <Button
                  name="back"
                  type="secondary"
                  onClick={() => setCurrentFormStep(FormStep.TestType)}
                >
                  Back
                </Button>
              )}
              {currentFormStep === FormStep.TestDetails && (
                <Button name="create" isLoading={formik.isSubmitting} htmlType="submit">
                  Create
                </Button>
              )}
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default SensoryTestCreationDialog;
