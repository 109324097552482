import React from 'react';
import {
  CategoryScaleFormElement,
  CommentFormElement,
  FormElement,
  LineScaleFormElement,
  PageFormElement,
} from '@bloodhound/common/dist/models/formElement';
import {
  DiscriminativeTest,
  QdaTest,
  SensoryTest,
  TriangleTest,
  TwoFiveTest,
} from '@bloodhound/common/dist/models/sensoryTest';
import {
  SensoryTestParticipant,
  TriangleTestParticipant,
  TwoFiveTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';

export const isReactMouseEvent = (e: any): e is React.MouseEvent => {
  return (
    typeof e.pageX !== 'undefined' &&
    typeof e.pageY !== 'undefined' &&
    typeof e.screenX !== 'undefined' &&
    typeof e.screenY !== 'undefined'
  );
};

export const isReactKeyBoardEvent = (e: any): e is React.KeyboardEvent => {
  return typeof e.keyCode !== 'undefined' && e.key && e.locale && typeof e.location !== 'undefined';
};

export const isTriangleTest = (sensoryTest: SensoryTest): sensoryTest is TriangleTest => {
  return sensoryTest.type === 'triangle';
};

export const isQdaTest = (sensoryTest: SensoryTest): sensoryTest is QdaTest => {
  return sensoryTest.type === 'qda';
};

export const isTwoFiveTest = (sensoryTest: SensoryTest): sensoryTest is TwoFiveTest => {
  return sensoryTest.type === 'twoFive';
};

export const isDiscriminativeTest = (
  sensoryTest: SensoryTest,
): sensoryTest is DiscriminativeTest => {
  return isTriangleTest(sensoryTest) || isTwoFiveTest(sensoryTest);
};

export const isLineScaleFormElement = (
  formElement: FormElement,
): formElement is LineScaleFormElement => {
  return formElement.type === 'line';
};

export const isCategoryScaleFormElement = (
  formElement: FormElement,
): formElement is CategoryScaleFormElement => {
  return formElement.type === 'category';
};

export const isCommentFormElement = (
  formElement: FormElement,
): formElement is CommentFormElement => {
  return formElement.type === 'comment';
};

export const isPageFormElement = (formElement: FormElement): formElement is PageFormElement => {
  return formElement.type === 'page';
};

export const isTriangleTestParticipant = (
  participant: SensoryTestParticipant,
): participant is TriangleTestParticipant => {
  const castedResult = (participant as unknown) as TriangleTestParticipant;

  return (
    Boolean(castedResult.firstSampleChar) &&
    Boolean(castedResult.secondSampleChar) &&
    Boolean(castedResult.thirdSampleChar)
  );
};

export const isTwoFiveTestParticipant = (
  participant: SensoryTestParticipant,
): participant is TwoFiveTestParticipant => {
  const castedResult = (participant as unknown) as TwoFiveTestParticipant;

  return (
    Boolean(castedResult.firstSampleChar) &&
    Boolean(castedResult.secondSampleChar) &&
    Boolean(castedResult.thirdSampleChar) &&
    Boolean(castedResult.fourthSampleChar) &&
    Boolean(castedResult.fifthSampleChar)
  );
};
