import { jsPDF as JsPdf } from 'jspdf';
import QRCode from 'qrcode';

export interface PdfQrCodeItem {
  url: string;
  accessCode?: string;
  participantName?: string;
}

export const generateQrCodePdf = async (pdfQrCodeItems: PdfQrCodeItem[]): Promise<string> => {
  const doc = new JsPdf();
  doc.setProperties({ title: 'Access codes' });

  for (let index = 0; index < pdfQrCodeItems.length; index += 1) {
    const { url, accessCode, participantName } = pdfQrCodeItems[index];
    // eslint-disable-next-line no-await-in-loop
    const base64QrCode = await QRCode.toDataURL(url, {
      version: 6,
      errorCorrectionLevel: 'M',
    });

    const count = index % 20;

    if (count === 0 && index !== 0) {
      doc.addPage();
    }

    const qrCodesPerRow = 4;

    const leftPageMargin = 10;
    const topPageMargin = 10;

    const rowMargin = 15;
    const columnMargin = 10;

    const qrCodeWidthAndHeight = 40;

    const columnCount = count % qrCodesPerRow;
    const rowCount = Math.floor(count / qrCodesPerRow);

    const accessCodeTopOffset = 4;
    const accessCodeLeftOffsetToCenter = 12;

    const participantNameLeftOffsetToCenterBase = 15;

    const participantNameTopOffset = 10;

    doc.addImage(
      base64QrCode,
      'PNG',
      leftPageMargin + columnCount * qrCodeWidthAndHeight + columnCount * columnMargin,
      topPageMargin + rowCount * qrCodeWidthAndHeight + rowCount * rowMargin,
      qrCodeWidthAndHeight,
      qrCodeWidthAndHeight,
    );

    doc.setFontSize(12);
    doc.text(
      accessCode ?? '',
      leftPageMargin +
        columnCount * qrCodeWidthAndHeight +
        columnCount * columnMargin +
        accessCodeLeftOffsetToCenter,
      topPageMargin +
        (rowCount + 1) * qrCodeWidthAndHeight +
        rowCount * rowMargin +
        accessCodeTopOffset,
    );

    doc.text(
      participantName ?? '',
      leftPageMargin +
        columnCount * qrCodeWidthAndHeight +
        columnCount * columnMargin +
        (participantNameLeftOffsetToCenterBase - (participantName?.length ?? 0) / 2),
      topPageMargin +
        (rowCount + 1) * qrCodeWidthAndHeight +
        rowCount * rowMargin +
        participantNameTopOffset,
    );
  }

  return doc.output('bloburl').toString();
};
