import React, { ReactNode } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import clsx from 'clsx';
import { Button, Edit2, Trash2 } from 'ventura';

import { ReactComponent as BulletGridIcon } from 'assets/icons/bullet-grid.svg';
import { useDraggableInPortal } from 'utils/dragAndDrop';

import styles from './EditableFormElement.module.css';

interface Props {
  id: string;
  index: number;
  name?: string;
  type: string;
  onEdit: () => void;
  onRemove?: () => void;
  isDragDisabled?: boolean;
  className?: string;
  children: ReactNode;
}

const EditableFormElement: React.FC<Props> = ({
  id,
  index,
  name,
  type,
  onEdit,
  onRemove,
  isDragDisabled = false,
  className,
  children,
}: Props) => {
  const renderDraggable = useDraggableInPortal();
  const mergedClassName = clsx(styles.container, className, {
    [styles.isDraggable]: !isDragDisabled,
  });

  return (
    <Draggable key={id} draggableId={id} index={index} isDragDisabled={isDragDisabled}>
      {renderDraggable(({ draggableProps, innerRef, dragHandleProps }) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <div className={clsx(mergedClassName)} ref={innerRef} {...draggableProps}>
          {/* eslint-disable-next-line react/jsx-props-no-spreading */}
          <span {...dragHandleProps} className={styles.dragIconContainer}>
            <BulletGridIcon className={styles.dragIcon} />
          </span>
          <div className={styles.middle}>
            {Boolean(name) && (
              <span className={styles.title}>
                <span className={styles.name}>{name}</span>
                <span className={styles.type}>{type}</span>
              </span>
            )}
            <div className={styles.children}>{children}</div>
          </div>
          <div className={styles.actions}>
            <Button
              name="qdaParameter-edit"
              type="secondary"
              prefixIcon={<Edit2 />}
              onClick={onEdit}
            >
              Edit
            </Button>
            {Boolean(onRemove) && (
              <Button
                name="qdaParameter-delete"
                type="secondary"
                prefixIcon={<Trash2 />}
                onClick={onRemove}
              >
                Delete
              </Button>
            )}
          </div>
        </div>
      ))}
    </Draggable>
  );
};

export default EditableFormElement;
