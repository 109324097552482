import React, { useState } from 'react';
import { Tag } from '@bloodhound/common/dist/models';
import clsx from 'clsx';
import { Checkbox, Trash2 } from 'ventura';

import { SensoryTestTagDeleteDialog } from 'components/organisms';
import { TagColorBackground, TagColorSortOrder, TagColorValues } from 'utils/workspace';

import styles from './SensoryTestTagsDisplay.module.css';

export interface Props {
  workspace?: any;
  tags?: Tag[];
  selectedTags?: Tag[];
  checkBoxMode?: boolean;
  updateSelectedTags?: (tag: Tag) => void;
  tagsChecked?: Tag[];
  tagsUndetermined?: Tag[];
  updateTagsChecked?: (tag: Tag) => void;
  updateSensoryTagsHook?: (update: boolean) => void;
}

interface DisplayTagProps {
  tag: Tag;
  isSelected: boolean;
  isChecked: boolean;
  isUndetermined?: boolean;
}

const SensoryTestTagsDisplay: React.FC<Props> = ({
  workspace,
  tags,
  selectedTags,
  checkBoxMode,
  updateSelectedTags,
  tagsChecked,
  tagsUndetermined,
  updateTagsChecked,
  updateSensoryTagsHook,
}) => {
  const [isDeleteTagModalOpen, setIsDeleteTagModalOpen] = useState(false);
  const [tagToBeDeleted, setTagToBeDeleted] = useState<Tag | undefined>(undefined);

  const handleTagSelection = (newSelectedTags: Tag) => {
    if (updateSelectedTags) {
      updateSelectedTags(newSelectedTags);
    }
  };

  const handleTagCheckSelection = (tag: Tag) => {
    if (updateTagsChecked) {
      updateTagsChecked(tag);
    }
  };

  const handleCheckboxClick = (event: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    event.persist();
    setTimeout(() => {
      if (updateSensoryTagsHook) {
        updateSensoryTagsHook(true);
      }
    });
  };

  const openDeleteTagModal = (tag: Tag) => {
    setTagToBeDeleted(tag);
    setIsDeleteTagModalOpen(true);
  };

  const handleDeleteTagCancel = (hasDeleted?: boolean) => {
    if (hasDeleted && tagToBeDeleted && updateSelectedTags) {
      updateSelectedTags(tagToBeDeleted);
    }
    setIsDeleteTagModalOpen(false);
  };

  const DisplayTag: React.FC<DisplayTagProps> = ({
    tag,
    isSelected,
    isChecked,
    isUndetermined,
  }) => {
    const allClassNames = clsx({
      [styles.tag]: true,
      [styles.hasSelectedTests]: checkBoxMode,
      [styles.selected]: isSelected,
      [styles.untagged]: tag.id === 'untagged',
    });

    const checkBoxContainerClassNames = clsx({
      [styles.checkBoxContainer]: true,
      [styles.isUndetermined]: isUndetermined,
    });

    var tagStyle = {
      backgroundColor: `${TagColorBackground[tag.color]}`,
      border: isSelected
        ? `1px solid ${TagColorValues[tag.color]}`
        : `1px solid ${TagColorBackground[tag.color]}`,
    };

    return (
      <div className={styles.tagContainer}>
        {checkBoxMode && tag.id !== 'untagged' && (
          <Checkbox
            name={`tag-${tag.id}`}
            label=""
            value={isChecked}
            className={checkBoxContainerClassNames}
            onChange={() => handleTagCheckSelection(tag)}
            onClick={handleCheckboxClick}
          />
        )}
        <div key={tag.id} className={allClassNames} style={tagStyle}>
          <span
            onClick={(event) => {
              if (checkBoxMode) {
                handleTagCheckSelection(tag);
                handleCheckboxClick(event);
              } else {
                handleTagSelection(tag);
              }
            }}
            className={styles.tagText}
            style={{ color: TagColorValues[tag.color] }}
          >
            {tag.name}
          </span>
          {isSelected && !checkBoxMode && tag.id !== 'untagged' && (
            <span
              data-testid={`deletebtn-` + tag.id}
              className={styles.deleteTagButton}
              onClick={() => openDeleteTagModal(tag)}
            >
              <Trash2 className={styles.deleteIcon} color={TagColorValues[tag.color]} />
            </span>
          )}
        </div>
      </div>
    );
  };

  tags?.sort((p1, p2) => {
    const colorSortOrder = TagColorSortOrder[p1.color] - TagColorSortOrder[p2.color];
    if (colorSortOrder !== 0) {
      return colorSortOrder; //first sort by color
    }
    return p1.name.localeCompare(p2.name);
  });

  return (
    <>
      {tags &&
        tags.length > 0 &&
        tags.map((tag) => (
          <DisplayTag
            key={tag.id}
            tag={tag}
            isSelected={
              selectedTags ? selectedTags.some((selectedTag) => selectedTag.id === tag.id) : false
            }
            isChecked={
              tagsChecked ? tagsChecked.some((checkedTag) => checkedTag.id === tag.id) : false
            }
            isUndetermined={
              tagsUndetermined
                ? tagsUndetermined.some((undeterminedTag) => undeterminedTag.id === tag.id)
                : false
            }
          ></DisplayTag>
        ))}

      <SensoryTestTagDeleteDialog
        isOpen={isDeleteTagModalOpen}
        onClose={handleDeleteTagCancel}
        workspace={workspace}
        selectedTags={tagToBeDeleted ? [tagToBeDeleted] : []}
      ></SensoryTestTagDeleteDialog>
    </>
  );
};

export default SensoryTestTagsDisplay;
