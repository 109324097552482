import { Panelist } from '@bloodhound/common/dist/models/panelist';
import {
  DiscriminativeTest,
  QdaTest,
  TriangleTest,
  TwoFiveTest,
} from '@bloodhound/common/dist/models/sensoryTest';
import {
  DiscriminativeTestParticipant,
  QdaTestParticipant,
  SensoryTestParticipant,
  TriangleTestParticipant,
  TwoFiveTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';

import { generateQrCodePdf, PdfQrCodeItem } from './pdf/qr-codes';
import { generateAmountOfSampleIds, generateRandomInteger, shuffleArray } from './random';
import { getIndexesOfLetter } from './string';
import {
  isTriangleTest,
  isTriangleTestParticipant,
  isTwoFiveTest,
  isTwoFiveTestParticipant,
} from './typeGuards';

export const generateGuestParticipantName = (existingParticipants: SensoryTestParticipant[]) => {
  const participantNumber = existingParticipants.reduce((acc, participant) => {
    const previousNumber = parseInt(acc, 10);
    const match = participant.name.match(/(Guest|Panelist)\s([0-9]*)/);

    if (!match) {
      return acc;
    }

    const value = parseInt(match[2], 10);

    if (value >= previousNumber) {
      const newNumber = value + 1;

      const stringifiedNumber = String(newNumber).padStart(2, '0');

      return stringifiedNumber;
    }

    return acc;
  }, '01');

  return `Panelist ${participantNumber}`;
};

export function generateDiscriminativeTestParticipant(
  triangleTest: TwoFiveTest,
  participants: TwoFiveTestParticipant[],
  panelist?: Panelist,
): Omit<TwoFiveTestParticipant, 'id' | 'accessCode' | 'dateCreated'>;

export function generateDiscriminativeTestParticipant(
  triangleTest: TriangleTest,
  participants: TriangleTestParticipant[],
  panelist?: Panelist,
): Omit<TriangleTestParticipant, 'id' | 'accessCode' | 'dateCreated'>;

export function generateDiscriminativeTestParticipant(
  discriminativeTest: DiscriminativeTest,
  participants: DiscriminativeTestParticipant[],
  panelist?: Panelist,
): Omit<DiscriminativeTestParticipant, 'id' | 'accessCode' | 'dateCreated'> {
  let sampleOrderCountMap: {
    [key: string]: number;
  } = {};

  if (isTriangleTest(discriminativeTest)) {
    sampleOrderCountMap = participants.reduce(
      (acc: { [key: string]: number }, participant: SensoryTestParticipant) => {
        if (isTriangleTestParticipant(participant)) {
          const sampleOrder = `${participant.firstSampleChar}${participant.secondSampleChar}${participant.thirdSampleChar}`;

          acc[sampleOrder] += 1;
        }

        return acc;
      },
      {
        AAB: 0,
        ABB: 0,
        ABA: 0,
        BAB: 0,
        BAA: 0,
        BBA: 0,
      },
    );
  } else if (isTwoFiveTest(discriminativeTest)) {
    sampleOrderCountMap = participants.reduce(
      (acc: { [key: string]: number }, participant: SensoryTestParticipant) => {
        if (isTwoFiveTestParticipant(participant)) {
          const sampleOrder = `${participant.firstSampleChar}${participant.secondSampleChar}${participant.thirdSampleChar}${participant.fourthSampleChar}${participant.fifthSampleChar}`;

          acc[sampleOrder] += 1;
        }

        return acc;
      },
      {
        AAABB: 0,
        AABAB: 0,
        ABAAB: 0,
        ABABA: 0,
        ABBAA: 0,
        BBBAA: 0,
        BBABA: 0,
        BABBA: 0,
        BABAB: 0,
        BAABB: 0,
      },
    );
  }

  const shuffledSampleOrders = shuffleArray(Object.keys(sampleOrderCountMap));

  const leastUsedSampleOrder = shuffledSampleOrders.reduce((acc, sampleOrder) => {
    if (sampleOrderCountMap[sampleOrder] < sampleOrderCountMap[acc]) {
      return sampleOrder;
    }

    return acc;
  }, Object.keys(sampleOrderCountMap)[0]);

  const sampleIdsA = [...discriminativeTest.sampleIdsA];
  const sampleIdsB = [...discriminativeTest.sampleIdsB];

  let sampleIds: number[] = [];

  if (discriminativeTest.settings.usesSimplifiedSampleIds) {
    sampleIds = leastUsedSampleOrder.split('').map((sampleLetter: string) => {
      if (sampleLetter === 'A') {
        const randomIndex = generateRandomInteger(0, sampleIdsA.length - 1);
        const selectedSampleId = sampleIdsA[randomIndex];
        sampleIdsA.splice(randomIndex, 1);
        return selectedSampleId;
      }

      const randomIndex = generateRandomInteger(0, sampleIdsB.length - 1);
      const selectedSampleId = sampleIdsB[randomIndex];
      sampleIdsB.splice(randomIndex, 1);
      return selectedSampleId;
    });
  } else if (isTriangleTest(discriminativeTest)) {
    sampleIds = generateAmountOfSampleIds(3);
  } else if (isTwoFiveTest(discriminativeTest)) {
    sampleIds = generateAmountOfSampleIds(5);
  }

  const indexesA = getIndexesOfLetter(leastUsedSampleOrder, 'A');
  const indexesB = getIndexesOfLetter(leastUsedSampleOrder, 'B');

  const participantName = panelist
    ? `${panelist.firstName} ${panelist.lastName}`
    : generateGuestParticipantName(participants);

  let uniqueSampleId: number = 0;
  let uniqueSampleIds: number[] = [];

  if (isTriangleTest(discriminativeTest)) {
    if (indexesA.length > indexesB.length) {
      uniqueSampleId = sampleIds[indexesB[0]];
    } else {
      uniqueSampleId = sampleIds[indexesA[0]];
    }
  } else if (isTwoFiveTest(discriminativeTest)) {
    if (indexesA.length > indexesB.length) {
      uniqueSampleIds = indexesB.map((index) => sampleIds[index]);
    } else {
      uniqueSampleIds = indexesA.map((index) => sampleIds[index]);
    }
  }

  let participantPanelistInfo: { id: string; email: string } | undefined;

  if (panelist) {
    participantPanelistInfo = {
      id: panelist.id,
      email: panelist.email,
    };
  }

  if (isTriangleTest(discriminativeTest)) {
    return {
      sensoryTest: { type: 'triangle', purpose: discriminativeTest.purpose },
      name: participantName,
      firstSampleId: sampleIds[0],
      secondSampleId: sampleIds[1],
      thirdSampleId: sampleIds[2],
      firstSampleChar: leastUsedSampleOrder[0] as 'A' | 'B',
      secondSampleChar: leastUsedSampleOrder[1] as 'A' | 'B',
      thirdSampleChar: leastUsedSampleOrder[2] as 'A' | 'B',
      uniqueSampleId,
      panelist: participantPanelistInfo,
    } as Omit<TriangleTestParticipant, 'id' | 'accessCode' | 'dateCreated'>;
  }

  return {
    sensoryTest: { type: 'twoFive', purpose: discriminativeTest.purpose },
    name: participantName,
    firstSampleId: sampleIds[0],
    secondSampleId: sampleIds[1],
    thirdSampleId: sampleIds[2],
    fourthSampleId: sampleIds[3],
    fifthSampleId: sampleIds[4],
    firstSampleChar: leastUsedSampleOrder[0] as 'A' | 'B',
    secondSampleChar: leastUsedSampleOrder[1] as 'A' | 'B',
    thirdSampleChar: leastUsedSampleOrder[2] as 'A' | 'B',
    fourthSampleChar: leastUsedSampleOrder[3] as 'A' | 'B',
    fifthSampleChar: leastUsedSampleOrder[4] as 'A' | 'B',
    uniqueSampleIds,
    panelist: participantPanelistInfo,
  } as Omit<TwoFiveTestParticipant, 'id' | 'accessCode' | 'dateCreated'>;
}

export const generateQdaTestParticipant = (
  sensoryTest: QdaTest,
  participants: QdaTestParticipant[],
) => {
  const participantName = generateGuestParticipantName(participants);

  const qdaTestParticipant: Omit<QdaTestParticipant, 'id' | 'accessCode' | 'dateCreated'> = {
    name: participantName,
    sensoryTest: { type: sensoryTest.type, purpose: sensoryTest.purpose },
    sampleResults: [],
  };

  return qdaTestParticipant;
};

export const openQrCodePdf = async (participants: SensoryTestParticipant[], formLink: string) => {
  const pdfItems = participants.map(
    (participant): PdfQrCodeItem => ({
      url: participant.sensoryTest.type === 'qda' ? `${formLink}/${participant.id}` : formLink,
      accessCode: participant.sensoryTest.type === 'qda' ? participant.id : undefined,
      participantName: participant.name,
    }),
  );

  const blobUrl = await generateQrCodePdf(pdfItems);

  window.open(blobUrl);
};
