import React, { useState } from 'react';
import { FormElementType } from '@bloodhound/common/dist/models/formElement';
import {
  Button,
  CheckCircle,
  FilePlus,
  FormField,
  GitCommit,
  MessageSquare,
  Modal,
  Radio,
} from 'ventura';

import CategoryScaleFormElementDialogContent, {
  CategoryScaleFormElementPreloadedState,
} from '../CategoryScaleFormElementDialogContent/CategoryScaleFormElementDialogContent';
import CommentFormElementDialogContent, {
  CommentFormElementPreloadedState,
} from '../CommentFormElementDialogContent/CommentFormElementDialogContent';
import { getFormElementDisplayType } from '../FormComposer/helper';
import LineScaleFormElementDialogContent, {
  LineScaleFormElementPreloadedState,
} from '../LineScaleFormElementDialogContent/LineScaleFormElementDialogContent';
import PageFormElementDialogContent, {
  PageFormElementPreloadedState,
} from '../PageFormElementDialogContent/PageFormElementDialogContent';

import styles from './FormElementCreationDialog.module.css';

export type FormElementCreationData =
  | LineScaleFormElementPreloadedState
  | CategoryScaleFormElementPreloadedState
  | CommentFormElementPreloadedState
  | PageFormElementPreloadedState;

interface Props {
  pageIndex: number;
  pageCount: number;
  isOpen: boolean;
  onClose: (id?: string) => void;
  onSubmit: (creationData: FormElementCreationData, type: FormElementType) => void;
  showOnlyLayoutFormElements?: boolean;
}

enum FormStep {
  PickType,
  FillInDetails,
}

const FormElementCreationDialog: React.FC<Props> = ({
  pageIndex,
  pageCount,
  isOpen,
  onClose,
  onSubmit,
  showOnlyLayoutFormElements = false,
}: Props) => {
  const [currentFormStep, setCurrentFormStep] = useState(FormStep.PickType);
  const [selectedFormElementType, setSelectedFormElementType] = useState<
    FormElementType | undefined
  >('line');

  const resetForm = () => {
    setCurrentFormStep(FormStep.PickType);
    setSelectedFormElementType(undefined);
  };

  const handleCancel = () => {
    onClose();
    resetForm();
  };

  const handleSubmit = (creationData: FormElementCreationData) => {
    onSubmit(creationData, selectedFormElementType as FormElementType);
    resetForm();
  };

  return (
    <Modal
      isOpen={isOpen}
      title={
        currentFormStep === FormStep.PickType
          ? 'Choose a form element type'
          : getFormElementDisplayType(selectedFormElementType as FormElementType)
      }
    >
      {currentFormStep === FormStep.PickType && (
        <>
          <Radio.Group
            name="type"
            onChange={(e) => setSelectedFormElementType(e.target.value as FormElementType)}
            value={selectedFormElementType}
          >
            {!showOnlyLayoutFormElements && (
              <FormField label="Parameters">
                <Radio.Item
                  label={
                    <>
                      <GitCommit className={styles.typeIcon} /> Line scale parameter
                    </>
                  }
                  value="line"
                  description="Gather a rating between a minimum and a maximum on a line scale."
                />
                <Radio.Item
                  label={
                    <>
                      <CheckCircle className={styles.typeIcon} /> Category scale parameter
                    </>
                  }
                  value="category"
                  description="Multiple choice. Only 1 answer can be selected out of all options."
                />
                <Radio.Item
                  label={
                    <>
                      <MessageSquare className={styles.typeIcon} /> Comment field
                    </>
                  }
                  value="comment"
                  description="A text box where panelists can fill in free text."
                />
              </FormField>
            )}
            <FormField label="Layout">
              <Radio.Item
                label={
                  <>
                    <FilePlus className={styles.typeIcon} /> Page
                  </>
                }
                value="page"
                description="Split up large lists of parameters in groups with pages."
              />
            </FormField>
          </Radio.Group>
          <Modal.Footer>
            {currentFormStep === FormStep.PickType && (
              <Button name="cancel" type="secondary" onClick={handleCancel}>
                Cancel
              </Button>
            )}
            {currentFormStep === FormStep.PickType && (
              <Button
                name="next"
                onClick={() => setCurrentFormStep(FormStep.FillInDetails)}
                isDisabled={!selectedFormElementType}
              >
                Next
              </Button>
            )}
          </Modal.Footer>
        </>
      )}
      {currentFormStep === FormStep.FillInDetails && selectedFormElementType === 'line' && (
        <LineScaleFormElementDialogContent
          isEditLimited={false}
          onCancel={() => setCurrentFormStep(FormStep.PickType)}
          onSubmit={handleSubmit}
        />
      )}
      {currentFormStep === FormStep.FillInDetails && selectedFormElementType === 'category' && (
        <CategoryScaleFormElementDialogContent
          isEditLimited={false}
          onCancel={() => setCurrentFormStep(FormStep.PickType)}
          onSubmit={handleSubmit}
        />
      )}
      {currentFormStep === FormStep.FillInDetails && selectedFormElementType === 'comment' && (
        <CommentFormElementDialogContent
          onCancel={() => setCurrentFormStep(FormStep.PickType)}
          onSubmit={handleSubmit}
        />
      )}
      {currentFormStep === FormStep.FillInDetails && selectedFormElementType === 'page' && (
        <PageFormElementDialogContent
          onCancel={() => setCurrentFormStep(FormStep.PickType)}
          onSubmit={handleSubmit}
          currentPageIndex={pageIndex}
          pageCount={pageCount}
        />
      )}
    </Modal>
  );
};

export default FormElementCreationDialog;
