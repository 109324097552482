import React from 'react';
import { NavLink } from 'react-router-dom';
import clsx from 'clsx';

import { SvgIcon } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';

import styles from './NavItem.module.css';

type Props = {
  to: string;
  label: string;
  icon: SvgIconName;
  className?: string;
};

const NavItem: React.FC<Props> = ({ to, label, icon, className }: Props) => {
  return (
    <NavLink
      exact
      className={clsx(styles.navItem, className)}
      activeClassName={styles.navItemActive}
      to={to}
    >
      <SvgIcon icon={icon} className={styles.icon} />
      <span className={styles.navItemText}>{label}</span>
    </NavLink>
  );
};

export default NavItem;
