import {
  CategoryScaleFormElement,
  CommentFormElement,
  FormElement,
  FormElementResult,
  LineScaleFormElement,
  LineScaleFormElementResult,
} from '@bloodhound/common/dist/models/formElement';
import { QdaSample, QdaTest } from '@bloodhound/common/dist/models/sensoryTest';
import { QdaTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import ExcelJS from 'exceljs';

import { average, median } from 'utils/math/basic';
import { sanitize } from 'utils/string';
import {
  isCategoryScaleFormElement,
  isCommentFormElement,
  isLineScaleFormElement,
  isPageFormElement,
} from 'utils/typeGuards';
import { addTable, excelToBlob, getExcelColumnInLetters } from './helper';

const addParametersWorksheet = (workbook: ExcelJS.Workbook, formElementsInOrder: FormElement[]) => {
  const worksheet = workbook.addWorksheet(`Parameters`);

  const lineParametersInOrder = formElementsInOrder.filter((formElement) =>
    isLineScaleFormElement(formElement),
  ) as LineScaleFormElement[];
  const categoryParametersInOrder = formElementsInOrder.filter((formElement) =>
    isCategoryScaleFormElement(formElement),
  ) as CategoryScaleFormElement[];
  const commentParametersInOrder = formElementsInOrder.filter((formElement) =>
    isCommentFormElement(formElement),
  ) as CommentFormElement[];

  let amountOfAlreadyFilledColumns = 0;

  if (lineParametersInOrder.length > 0) {
    const minRow: (string | number)[] = ['Min'];
    const maxRow: (string | number)[] = ['Max'];

    lineParametersInOrder.forEach((parameter) => {
      minRow.push(parameter.valueBoundaries.minimum);
      maxRow.push(parameter.valueBoundaries.maximum);
    });

    addTable(worksheet, {
      name: `lineParameters`,
      title: 'Line parameters',
      ref: `A1`,
      columns: [
        { name: ' ', filterButton: false },
        ...lineParametersInOrder.map((parameter) => ({
          name: parameter.dataLabel || parameter.name,
          filterButton: false,
        })),
      ],
      rows: [minRow, maxRow],
    });
    amountOfAlreadyFilledColumns += lineParametersInOrder.length + 2;
  }

  if (categoryParametersInOrder.length > 0) {
    const mostAmountOfCategoriesForOneParameter = categoryParametersInOrder.reduce(
      (acc, parameter) => {
        if (parameter.options.length > acc) {
          return parameter.options.length;
        }

        return acc;
      },
      0,
    );

    const optionRows: ExcelJS.CellValue[][] = [];

    for (let i = 0; i < mostAmountOfCategoriesForOneParameter; i += 1) {
      optionRows.push([`Option ${i + 1}`]);

      categoryParametersInOrder.forEach((parameter) => optionRows[i].push(parameter.options[i]));
    }

    addTable(worksheet, {
      name: `categoryParameters`,
      title: 'Category parameters',
      ref: `${getExcelColumnInLetters(amountOfAlreadyFilledColumns + 1)}1`,
      columns: [
        { name: ' ', filterButton: false },
        ...categoryParametersInOrder.map((parameter) => ({
          name: parameter.dataLabel || parameter.name,
          filterButton: false,
        })),
      ],
      rows: optionRows,
    });
    amountOfAlreadyFilledColumns += categoryParametersInOrder.length + 2;
  }

  if (commentParametersInOrder.length > 0) {
    const isRequiredRow = commentParametersInOrder.map((parameter) =>
      parameter.isRequired ? 'yes' : 'no',
    );

    addTable(worksheet, {
      name: `commentParameters`,
      title: 'Comment parameters',
      ref: `${getExcelColumnInLetters(amountOfAlreadyFilledColumns + 1)}1`,
      columns: [
        { name: ' ', filterButton: false },
        ...commentParametersInOrder.map((parameter) => ({
          name: parameter.dataLabel || parameter.name,
          filterButton: false,
        })),
      ],
      rows: [['Required field', ...isRequiredRow]],
    });
  }
};

const addSampleWorksheet = (
  workbook: ExcelJS.Workbook,
  sample: QdaSample,
  formElementsInOrder: FormElement[],
  participants: QdaTestParticipant[],
) => {
  const worksheet = workbook.addWorksheet(sanitize(`${sample.name}---${sample.id}`));

  const lineParametersInOrder = formElementsInOrder.filter((formElement) =>
    isLineScaleFormElement(formElement),
  ) as LineScaleFormElement[];

  const rowsInOrder = participants.map<(string | number | undefined)[]>((participant) => {
    const participantResults = participant.sampleResults.find(
      (result) => result.sampleId === sample.id,
    );

    if (participantResults) {
      const participantResultsInOrder = formElementsInOrder.map((formElement) => {
        return participantResults.formElementResults.find(
          (result) => result.formElementId === formElement.id,
        );
      });

      const dataRow = participantResultsInOrder.map((result) => result?.value);

      return [participant.name, participant.id, ...dataRow];
    }
    return [participant.name, participant.id];
  }, []);

  const resultsTableName = `results${sample.id}`;

  addTable(worksheet, {
    name: resultsTableName,
    title: 'Results',
    ref: 'A1',
    columns: [
      { name: 'participant Name', filterButton: true },
      { name: 'participant Id', filterButton: true },
      ...formElementsInOrder.map((formElement) => ({
        name: formElement.dataLabel || formElement.name,
        filterButton: true,
      })),
    ],
    rows: rowsInOrder,
  });

  const medianRow: ExcelJS.CellValue[] = ['Median'];
  const averageRow: ExcelJS.CellValue[] = ['Average'];

  const allResultsForSample = participants.reduce<FormElementResult[]>((acc, participant) => {
    const participantSamplesResult = participant.sampleResults.find(
      (sampleResult) => sampleResult.sampleId === sample.id,
    );

    if (participantSamplesResult) {
      return [...acc, ...participantSamplesResult.formElementResults];
    }

    return acc;
  }, []);

  lineParametersInOrder.forEach((parameter) => {
    const parameterResults = allResultsForSample.filter(
      (parameterResult) => parameterResult.formElementId === parameter.id,
    ) as LineScaleFormElementResult[];
    const parameterResultsValues = parameterResults.map((parameterResult) => parameterResult.value);

    medianRow.push(({
      formula: `MEDIAN(${resultsTableName}[${parameter.dataLabel || parameter.name}])`,
      result: median(parameterResultsValues),
    } as unknown) as ExcelJS.CellValue);
    averageRow.push(({
      formula: `AVERAGE(${resultsTableName}[${parameter.dataLabel || parameter.name}])`,
      result: average(parameterResultsValues),
    } as unknown) as ExcelJS.CellValue);
  });

  addTable(worksheet, {
    name: `calculations${sample.id}`,
    title: 'Calculations',
    ref: `${getExcelColumnInLetters(formElementsInOrder.length + 4)}1`,
    columns: [
      { name: ' ', filterButton: false },
      ...lineParametersInOrder.map((parameter) => ({
        name: parameter.dataLabel || parameter.name,
        filterButton: false,
      })),
    ],
    rows: [averageRow, medianRow],
  });
};

const generateQdaResultsExcel = async (
  sensoryTest: QdaTest,
  participants: QdaTestParticipant[],
): Promise<Blob> => {
  const workbook = new ExcelJS.Workbook();
  const formElementsInOrder = sensoryTest.formElements.filter(
    (formElement) => !isPageFormElement(formElement),
  );

  sensoryTest.samples.forEach((sample) => {
    addSampleWorksheet(workbook, sample, formElementsInOrder, participants);
  });

  addParametersWorksheet(workbook, formElementsInOrder);

  return excelToBlob(workbook);
};

export default generateQdaResultsExcel;
