import React from 'react';
import ContentLoader from 'react-content-loader';

import { SvgIcon } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';

import styles from './PageHeader.module.css';

type Props = {
  title?: string;
  subTitle?: string;
  children?: React.ReactNode;
  isLoading?: boolean;
  logo?: SvgIconName;
};

const PageHeader: React.FC<Props> = ({
  title,
  subTitle,
  logo,
  children,
  isLoading = false,
}: Props) => {
  const isHeaderContentPresent =
    Boolean(title && title.length > 0) ||
    Boolean(subTitle && subTitle.length > 0) ||
    Boolean(logo && logo.length > 0);

  return (
    <div className={styles.header}>
      {!isLoading && (
        <>
          {isHeaderContentPresent && (
            <div className={styles.info}>
              {logo && <SvgIcon icon={logo} className={styles.logo} />}
              <div>
                <h2>{title}</h2>
                {Boolean(subTitle) && <span className={styles.subTitle}>{subTitle}</span>}
              </div>
            </div>
          )}
          <div className={styles.childrenContainer}>{children}</div>
        </>
      )}
      {isLoading && (
        <ContentLoader viewBox="0 0 780 24" width="780" height="24" uniqueKey="PageHeader">
          <rect x="0" y="0" rx="4" ry="4" width="320" height="24" />
          <rect x="740" y="2" rx="4" ry="4" width="40" height="20" />
          <rect x="692" y="2" rx="4" ry="4" width="40" height="20" />
        </ContentLoader>
      )}
    </div>
  );
};

export default PageHeader;
