import React, { useEffect, useMemo, useState } from 'react';
import { FirebaseError } from '@bloodhound/common/dist/models/firebase';
import { TriangleTest } from '@bloodhound/common/dist/models/sensoryTest';
import {
  SensoryTestParticipant,
  TriangleTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';
import { saveAs } from 'file-saver';
import {
  Alert,
  Button,
  CheckCircle,
  ChevronDown,
  ChevronUp,
  Download,
  Eye,
  Grid,
  Hash,
  Menu,
  PlusCircle,
  Popover,
  Printer,
  Radio,
  Tab,
  Table,
  Tabs,
  TextArea,
  toaster,
  Trash2,
  XCircle,
} from 'ventura';

import flaskWithPanelists from 'assets/flaskWithPanelists.svg';
import { Placeholder, ProgressBar, SampleIcon, SignificanceResult } from 'components/atoms';
import { ConfirmDialog, TabHeader } from 'components/molecules';
import { PrintSampleCodesDialog } from 'components/organisms';
import { getFormElementDisplayType } from 'components/organisms/FormComposer/helper';
import {
  useSensoryTestParticipantCreator,
  useSensoryTestParticipantRemover,
} from 'services/sensoryTestParticipantService';
import { generateTriangleResultsExcel } from 'utils/excel';
import { getBaseUrl } from 'utils/functions';
import { createProbabilityString } from 'utils/math/statistics/basic';
import { calculateCumulativeBinomialProbability } from 'utils/math/statistics/binomial';
import { generateDiscriminativeTestParticipant, openQrCodePdf } from 'utils/sensoryTestParticipant';
import { TabIndex } from '../types';

import styles from './TriangleTestDetail.module.css';

interface Props {
  triangleTest: TriangleTest;
  participants: TriangleTestParticipant[];
  participantsError?: FirebaseError;
}

const TriangleTestDetail: React.FC<Props> = ({
  triangleTest,
  participants,
  participantsError,
}: Props) => {
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isPrintMenuOpen, setIsPrintMenuOpen] = useState(false);
  const [isPrintSampleCodesDialogOpen, setIsPrintSampleCodesDialogOpen] = useState(false);

  const [toBeDeletedParticipant, setToBeDeletedParticipant] = useState<
    undefined | SensoryTestParticipant
  >(undefined);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.Form);

  const {
    createSensoryTestParticipant,
    error: createParticipantError,
  } = useSensoryTestParticipantCreator(triangleTest.id);
  const { removeSensoryTestParticipant, isDeletePending } = useSensoryTestParticipantRemover(
    triangleTest.id,
  );

  useEffect(() => {
    if (createParticipantError) {
      toaster.error('Failed to add a panelist.');
    }
  }, [createParticipantError]);

  const formLink = `${getBaseUrl()}/test/${triangleTest.id}`;

  const openDeleteDialog = (participant: SensoryTestParticipant) => {
    setToBeDeletedParticipant(participant);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setToBeDeletedParticipant(undefined);
  };

  const onConfirmDelete = async () => {
    if (toBeDeletedParticipant) {
      const result = await removeSensoryTestParticipant(toBeDeletedParticipant);
      if (result) {
        toaster.success('Successfully removed participant!', {
          isClosable: false,
          durationInSeconds: 3,
        });
      } else {
        toaster.error('Something went wrong, please try again.', {
          isClosable: false,
          durationInSeconds: 3,
        });
      }
      closeDeleteDialog();
    }
  };

  const onAddParticipant = () => {
    const participant = generateDiscriminativeTestParticipant(triangleTest, participants);
    return createSensoryTestParticipant(participant);
  };

  const resultData = useMemo(() => {
    const completedParticipants = participants.filter((participant) => {
      return typeof participant.selectedSampleId !== 'undefined';
    });

    const correctCount = completedParticipants.filter(
      (participant) => participant.selectedSampleId === participant.uniqueSampleId,
    ).length;

    const pValue =
      completedParticipants.length > 0
        ? calculateCumulativeBinomialProbability(correctCount, completedParticipants.length, 1 / 3)
        : undefined;

    return {
      completedCount: completedParticipants.length,
      passedCount: correctCount,
      passedPercentage: correctCount / completedParticipants.length,
      pValue,
    };
  }, [participants]);

  const onExportToExcel = async () => {
    const blob = await generateTriangleResultsExcel(participants);
    saveAs(blob, `${triangleTest.name}.xlsx`);
  };

  return (
    <>
      <div className={styles.samplesContainer}>
        <span className={styles.sampleA}>
          <SampleIcon id="A" sensoryTestType="triangle" index={0} />
          <span className={styles.sampleText}>{triangleTest.sampleNameA}</span>
        </span>
        <span className={styles.sampleB}>
          <SampleIcon id="B" sensoryTestType="triangle" index={1} />
          <span className={styles.sampleText}>{triangleTest.sampleNameB}</span>
        </span>
      </div>

      <Tabs onChange={setTabIndex} currentTabIndex={tabIndex}>
        <Tab title="Form" index={TabIndex.Form}>
          <TabHeader
            title="Simple form"
            description="This sensory test form has 2 simple questions. Currently, it's not possible to edit the parameters."
          >
            <a href={formLink} className={styles.formLink} target="_blank" rel="noreferrer">
              <Button prefixIcon={<Eye />}>View as panelist</Button>
            </a>
          </TabHeader>
          <ul className={styles.parameters}>
            <li className={styles.parameter}>
              <span className={styles.parameterTitle}>
                <span className={styles.name}>Select the different sample</span>
                <span className={styles.type}>{getFormElementDisplayType('category')}</span>
              </span>
              <Radio.Group
                name="qdaParameter-radioGroup-different-sample"
                className={styles.radioGroup}
              >
                {[387, 502, 219].map((option) => (
                  <Radio.Item
                    key={option}
                    label={option}
                    value={String(option)}
                    className={styles.radioItem}
                    isDisabled
                  />
                ))}
              </Radio.Group>
            </li>
            <li className={styles.parameter}>
              <span className={styles.parameterTitle}>
                <span className={styles.name}>Motivate your decision</span>
                <span className={styles.type}>{getFormElementDisplayType('comment')}</span>
              </span>
              <TextArea
                name="qdaParameter-comment-motivation"
                placeholder=""
                isDisabled
                className={styles.commentInput}
              />
            </li>
          </ul>
        </Tab>
        <Tab title="Panelists" index={TabIndex.Panelists}>
          <TabHeader
            title="Participating panelists"
            description="Only panelists you invite will be able to participate in the sensory test."
          >
            <Popover
              isVisible={isPrintMenuOpen}
              onClose={() => {
                setIsPrintMenuOpen(false);
              }}
              content={
                <Menu>
                  <Menu.Item
                    onClick={() => setIsPrintSampleCodesDialogOpen(true)}
                    prefixIcon={<Hash />}
                  >
                    Sample codes
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => openQrCodePdf(participants, formLink)}
                    prefixIcon={<Grid />}
                  >
                    QR codes
                  </Menu.Item>
                </Menu>
              }
              placement="bottom-end"
            >
              <Button
                onClick={() => {
                  setIsPrintMenuOpen(!isPrintMenuOpen);
                }}
                prefixIcon={<Printer />}
                suffixIcon={isPrintMenuOpen ? <ChevronUp /> : <ChevronDown />}
              >
                Print
              </Button>
            </Popover>
            <Button onClick={onAddParticipant} prefixIcon={<PlusCircle />}>
              Add panelist
            </Button>
          </TabHeader>
          {participantsError && <Alert intent="error" message={participantsError.message} />}
          <Table>
            <Table.Header>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Access code</Table.Cell>
              <Table.Cell>Sample codes</Table.Cell>
              <Table.Cell colSpan={2}>Result</Table.Cell>
            </Table.Header>
            <Table.Body>
              {participants.map((participant, index) => {
                let statusText: React.ReactNode;

                if (typeof participant.selectedSampleId === 'undefined') {
                  statusText = '-';
                } else if (participant.selectedSampleId === participant.uniqueSampleId) {
                  statusText = (
                    <>
                      <CheckCircle /> Passed
                    </>
                  );
                } else {
                  statusText = (
                    <>
                      <XCircle /> Failed
                    </>
                  );
                }
                return (
                  <Table.Row key={participant.id}>
                    <Table.Cell>{participant.name}</Table.Cell>
                    <Table.Cell>
                      <span className={styles.accessCode}>{participant.id}</span>
                    </Table.Cell>
                    <Table.Cell>
                      <div className={styles.sampleIds}>
                        <SampleIcon
                          sensoryTestType="triangle"
                          index={participant.firstSampleChar === 'A' ? 0 : 1}
                          id={participant.firstSampleId}
                          className={styles.sampleId}
                        />
                        <SampleIcon
                          sensoryTestType="triangle"
                          index={participant.secondSampleChar === 'A' ? 0 : 1}
                          id={participant.secondSampleId}
                          className={styles.sampleId}
                        />
                        <SampleIcon
                          sensoryTestType="triangle"
                          index={participant.thirdSampleChar === 'A' ? 0 : 1}
                          id={participant.thirdSampleId}
                          className={styles.sampleId}
                        />
                      </div>
                    </Table.Cell>
                    <Table.Cell>{statusText}</Table.Cell>
                    <Table.Cell align="right">
                      <Button
                        size="small"
                        type={(index + 1) % 2 === 0 ? 'secondary' : 'primary'}
                        onClick={() => {
                          openDeleteDialog(participant);
                        }}
                        className={styles.removeButton}
                      >
                        <Trash2 />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {participants.length === 0 && (
            <div className={styles.placeholderContainer}>
              <Placeholder
                image={flaskWithPanelists}
                subTitle="This sensory test has no panelists yet."
                imgSize="small"
              />
            </div>
          )}
          <ConfirmDialog
            title="Delete panelist"
            message={
              <>
                Are you sure you want to remove this panelist from the triangle test? The linked{' '}
                <b>result</b> will also be deleted.
              </>
            }
            isOpen={isDeleteDialogOpen}
            confirmButtonLabel="Delete"
            cancelButtonOnClick={closeDeleteDialog}
            confirmButtonOnClick={onConfirmDelete}
            isLoading={isDeletePending}
          />
          <PrintSampleCodesDialog
            isOpen={isPrintSampleCodesDialogOpen}
            onClose={() => setIsPrintSampleCodesDialogOpen(false)}
            sampleCodeSets={participants.map((participant) => [
              participant.firstSampleId,
              participant.secondSampleId,
              participant.thirdSampleId,
            ])}
          />
        </Tab>
        <Tab title="Results" index={TabIndex.Results}>
          <TabHeader
            title="Watch live results come in."
            description="All charts automatically update when a panelist submits the results."
          >
            <Button prefixIcon={<Download />} onClick={onExportToExcel}>
              Export XLS
            </Button>
          </TabHeader>
          <ProgressBar
            className={styles.numberOfPassedResults}
            data={resultData.completedCount ? resultData.passedPercentage : undefined}
            subTitle="Number of passed panelists"
            legend={`${resultData.passedCount}/${resultData.completedCount}`}
            sensoryTestType="triangle"
          />
          <ProgressBar
            className={styles.resultsCalculationBarChart}
            data={resultData.pValue !== undefined ? 1 - resultData.pValue : undefined}
            subTitle="Probability that there is a difference"
            legend={`${
              resultData.pValue
                ? `(p=${createProbabilityString(resultData.pValue)}) ${(
                    (1 - resultData.pValue) *
                    100
                  ).toPrecision(5)}`
                : '- '
            }%`}
            sensoryTestType="triangle"
          />
          <SignificanceResult
            discriminationTestType="triangle"
            probability={resultData.pValue}
            significanceLevel={triangleTest.significanceLevel}
          />
        </Tab>
      </Tabs>
    </>
  );
};

export default TriangleTestDetail;
