import {
  CategoryScaleFormElement,
  CommentFormElement,
  FormElementType,
  LineScaleFormElement,
  PageFormElement,
} from '@bloodhound/common/dist/models/formElement';

import { CategoryScaleFormElementPreloadedState } from '../CategoryScaleFormElementDialogContent/CategoryScaleFormElementDialogContent';
import { CommentFormElementPreloadedState } from '../CommentFormElementDialogContent/CommentFormElementDialogContent';
import { LineScaleFormElementPreloadedState } from '../LineScaleFormElementDialogContent/LineScaleFormElementDialogContent';
import { PageFormElementPreloadedState } from '../PageFormElementDialogContent/PageFormElementDialogContent';

export const convertLineScalePreloadedStateToFormElement = (
  state: LineScaleFormElementPreloadedState,
  id: string,
): LineScaleFormElement => ({
  id,
  name: state.name,
  dataLabel: state.dataLabel || '',
  type: 'line',
  textBoundaries: {
    minimum: state.leftText ?? '',
    maximum: state.rightText ?? '',
  },
  valueBoundaries: {
    minimum: state.minimumValue,
    default: state.defaultValue,
    maximum: state.maximumValue,
  },
});

export const convertLineScaleFormElementToPreloadedState = (
  state: LineScaleFormElement,
): LineScaleFormElementPreloadedState => ({
  name: state.name,
  dataLabel: state.dataLabel,
  minimumValue: state.valueBoundaries.minimum,
  maximumValue: state.valueBoundaries.maximum,
  defaultValue: state.valueBoundaries.default,
  leftText: state.textBoundaries.minimum,
  rightText: state.textBoundaries.maximum,
});

export const convertCategoryScalePreloadedStateToFormElement = (
  state: CategoryScaleFormElementPreloadedState,
  id: string,
): CategoryScaleFormElement => ({
  id,
  name: state.name,
  dataLabel: state.dataLabel || '',
  type: 'category',
  options: state.options,
});

export const convertCategoryScaleFormElementToPreloadedState = (
  state: CategoryScaleFormElement,
): CategoryScaleFormElementPreloadedState => ({
  name: state.name,
  dataLabel: state.dataLabel,
  options: state.options,
});

export const convertCommentPreloadedStateToFormElement = (
  state: CommentFormElementPreloadedState,
  id: string,
): CommentFormElement => ({
  id,
  name: state.name,
  dataLabel: state.dataLabel || '',
  type: 'comment',
  isRequired: state.isRequired,
});

export const convertCommentFormElementToPreloadedState = (
  state: CommentFormElement,
): CommentFormElementPreloadedState => ({
  name: state.name,
  dataLabel: state.dataLabel,
  isRequired: state.isRequired,
});

export const convertPagePreloadedStateToFormElement = (
  state: PageFormElementPreloadedState,
  id: string,
): PageFormElement => ({
  id,
  name: state.name,
  description: state.description,
  type: 'page',
});

export const convertPageFormElementToPreloadedState = (
  state: PageFormElement,
): PageFormElementPreloadedState => ({
  name: state.name,
  description: state.description,
});

export const getFormElementDisplayType = (type: FormElementType) => {
  switch (type) {
    case 'line':
      return 'Line scale';
    case 'category':
      return 'Category scale';
    case 'comment':
      return 'Comment field';
    case 'page':
      return 'Page';
    default:
      throw new Error(`Cannot get form element display type of ${type}`);
  }
};
