import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { SensoryTest, Tag } from '@bloodhound/common/dist/models';
import { Alert, Button, Copy, PlusCircle, toaster, Trash2, XSquare } from 'ventura';

import emptyFlasks from 'assets/emptyFlasks.svg';
import {
  Placeholder,
  SensoryTestsContentLoader,
  SensoryTestTagsContentLoader,
} from 'components/atoms';
import { PageHeader, SensoryTestCard, TagEditor } from 'components/molecules';
import CloneDialog from 'components/molecules/CloneDialog/CloneDialog';
import ConfirmDialog from 'components/molecules/ConfirmDialog/ConfirmDialog';
import { SensoryTestCreationDialog } from 'components/organisms';
import { PageLayout } from 'components/templates';
import {
  useAllSensoryTests,
  useSensoryTestEditor,
  useSensoryTestRemover,
} from 'services/sensoryTestService';
import { useAuthentication } from 'services/userService';
import { useWorkspace } from 'services/workspaceService';

import styles from './SensoryTests.module.css';

const SensoryTests: React.FC = () => {
  const { user } = useAuthentication();
  const history = useHistory();
  const { sensoryTests, isLoading, error } = useAllSensoryTests();
  const { editSensoryTest } = useSensoryTestEditor();
  const { removeSensoryTest, isDeletePending } = useSensoryTestRemover();
  const { workspace, isLoading: isWorkspaceLoading } = useWorkspace(user?.workspaceId);
  const [isCreationModalShown, setIsCreationModalShown] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isCloneDialogOpen, setIsCloneDialogOpen] = useState(false);
  const [selectedTests, setSelectedTests] = useState<SensoryTest[]>([]);
  const [selectedTags, setSelectedTags] = useState<Tag[]>([]);
  const [tagsChecked, setTagsChecked] = useState<Tag[]>([]);
  const [tagsUndetermined, setTagsUndetermined] = useState<Tag[]>([]);
  const [updateSensoryTags, setUpdateSensoryTags] = useState(false);

  const updateSelectedTags = (tag: Tag): void => {
    setSelectedTags((prevSelectedTags: Tag[]) => {
      if (prevSelectedTags.some((selectedTag) => selectedTag.id === tag.id)) {
        return prevSelectedTags.filter((selectedTag) => selectedTag.id !== tag.id);
      } else {
        return [...prevSelectedTags, tag];
      }
    });
  };

  const updateSensoryTagsHook = (update: boolean): void => {
    setUpdateSensoryTags(update);
  };

  useEffect(() => {
    if (isLoading) {
      // Reset the selectedTests when isLoading is true
      setSelectedTests([]);
      setTagsChecked([]);
    }
  }, [isLoading]);

  const setCheckedAndUndeterminedTags = (tests: SensoryTest[]): void => {
    const defaultCheckedTags = tests.reduce((acc, sensoryTest) => {
      const tags = sensoryTest.tags?.filter((tag) => tag !== undefined) as Tag[] | undefined;
      if (!tags) return acc;

      return acc.length === 0 ? tags : acc.filter((tag) => tags.some((t) => t.id === tag.id));
    }, [] as Tag[]);

    setTagsChecked(defaultCheckedTags);

    const defaultUndeterminedTags = tests.reduce((acc, sensoryTest) => {
      const tags = sensoryTest.tags?.filter((tag) => tag !== undefined) as Tag[] | undefined;
      if (!tags) return acc;

      const newTags = tags.filter(
        (tag) =>
          !acc.some((t) => t.id === tag.id) && !defaultCheckedTags.some((t) => t.id === tag.id),
      );

      return [...acc, ...newTags];
    }, [] as Tag[]);

    setTagsUndetermined(defaultUndeterminedTags);
  };

  useEffect(() => {
    if (updateSensoryTags && selectedTests.length) {
      const updatedSelectedTests = selectedTests.map((sensoryTest: SensoryTest) => {
        const updatedTags = [...tagsChecked];

        // Add undetermined tags to the updatedTags array if they are present in the current sensory test tags and not already in updatedTags
        tagsUndetermined.forEach((undeterminedTag) => {
          const isTagPresent = sensoryTest.tags?.some((tag) => tag.id === undeterminedTag.id);
          const isTagAlreadyAdded = updatedTags.some((tag) => tag.id === undeterminedTag.id);
          if (isTagPresent && !isTagAlreadyAdded) {
            updatedTags.push(undeterminedTag);
          }
        });

        const updatedSensory = {
          ...sensoryTest,
          tags: updatedTags,
        };
        editSensoryTest(updatedSensory);

        return updatedSensory;
      });

      setSelectedTests(updatedSelectedTests);
      setUpdateSensoryTags(false);
    }

    if (selectedTests.length && !updateSensoryTags) {
      // Sort the tests array so that items with empty or undefined tags are at the end.
      const sortedTests = [...selectedTests].sort((a, b) => {
        if (!a.tags?.length && !b.tags?.length) return 0;
        if (!a.tags?.length) return 1;
        if (!b.tags?.length) return -1;
        return 0;
      });

      setCheckedAndUndeterminedTags(sortedTests);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSensoryTags, selectedTests]);

  const openDeleteDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsDeleteDialogOpen(true);
  };

  const openCloneDialog = (e: React.MouseEvent) => {
    e.preventDefault();
    setIsCloneDialogOpen(true);
  };

  const deleteSensoryTest = async (sensoryTest: SensoryTest) => {
    const result = await removeSensoryTest(sensoryTest);
    if (result) {
      toaster.success('Successfully deleted sensory test!');
    } else {
      toaster.error('Something went wrong, please try again.');
    }
    return result;
  };

  const handleCloneClose = () => {
    setIsCloneDialogOpen(false);
  };

  const onConfirmDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();

    const promises = selectedTests.map((sensoryTest: SensoryTest) => {
      return deleteSensoryTest(sensoryTest);
    });

    await Promise.all(promises);
    setSelectedTests([]);
    setTagsChecked([]);
    setIsDeleteDialogOpen(false);
  };

  const updateTagsChecked = (tag: Tag): void => {
    setTagsChecked((prevTagsChecked: Tag[]) => {
      if (prevTagsChecked.some((selectedTag) => selectedTag.id === tag.id)) {
        return prevTagsChecked.filter((selectedTag) => selectedTag.id !== tag.id);
      } else {
        return [...prevTagsChecked, tag];
      }
    });
  };

  const updateSelectedTests = (test: SensoryTest): void => {
    setSelectedTests((prevSelectedTests: SensoryTest[]) => {
      if (prevSelectedTests.some((selectedTest) => selectedTest.id === test.id)) {
        return prevSelectedTests.filter((selectedTest) => selectedTest.id !== test.id);
      } else {
        return [...prevSelectedTests, test];
      }
    });
  };

  const onSensoryTestCheckboxChanged = (sensoryTest: SensoryTest): void => {
    updateSelectedTests(sensoryTest);
  };

  const handleUnselect = (): void => {
    setTagsChecked([]);
    setSelectedTests([]);
  };

  const handleCloseNewSensoryTestDialog = (sensoryTestId?: string) => {
    if (sensoryTestId) {
      history.push(`/sensoryTests/${sensoryTestId}`);
    } else {
      setIsCreationModalShown(false);
    }
  };

  // Filter sensoryTests based on selectedTags
  const filteredSensoryTests =
    selectedTags.length > 0
      ? sensoryTests.filter((test) => {
          const hasUntagged = selectedTags.some((selectedTag) => selectedTag.id === 'untagged');

          if (hasUntagged) {
            // Filter tests without any tags or with the selected tags
            return (
              !test?.tags ||
              test.tags.length === 0 ||
              test.tags.some((tag) => selectedTags.some((selectedTag) => selectedTag.id === tag.id))
            );
          } else {
            // Filter tests that have all of the selected tags
            return (
              test?.tags &&
              selectedTags.every((selectedTag) =>
                test.tags?.some((tag) => tag.id === selectedTag.id),
              )
            );
          }
        })
      : sensoryTests;

  return (
    <PageLayout>
      <PageLayout.Sidebar>
        {isWorkspaceLoading && <SensoryTestTagsContentLoader />}
        {!isWorkspaceLoading && (
          <TagEditor
            workspace={workspace}
            checkBoxMode={Boolean(selectedTests.length)}
            selectedTags={selectedTags}
            tagsChecked={tagsChecked}
            tagsUndetermined={tagsUndetermined}
            updateTagsChecked={updateTagsChecked}
            updateSelectedTags={updateSelectedTags}
            updateSensoryTagsHook={updateSensoryTagsHook}
          />
        )}
      </PageLayout.Sidebar>
      <PageLayout.Main>
        <>
          <PageHeader title="Sensory tests">
            <Button onClick={() => setIsCreationModalShown(true)} prefixIcon={<PlusCircle />}>
              New sensory test
            </Button>
          </PageHeader>
          {error && <Alert intent="error" message={error?.message} />}
          {isLoading && <SensoryTestsContentLoader />}
          {!isLoading && Boolean(selectedTests.length) && (
            <div className={styles.actions}>
              <Button
                className={styles.unselect}
                type="secondary"
                name="copy"
                prefixIcon={<XSquare />}
                onClick={handleUnselect}
              >
                {''}
              </Button>
              <div className={styles.selected}>{selectedTests.length} selected</div>
              {selectedTests.length === 1 && (
                <Button
                  className={styles.copy}
                  type="secondary"
                  name="copy"
                  prefixIcon={<Copy />}
                  onClick={openCloneDialog}
                >
                  Clone
                </Button>
              )}
              <Button
                className={styles.remove}
                type="secondary"
                name="delete"
                prefixIcon={<Trash2 />}
                onClick={openDeleteDialog}
              >
                Delete
              </Button>
            </div>
          )}
          <div className={styles.testsContainer}>
            {!isLoading &&
              Boolean(filteredSensoryTests.length) &&
              filteredSensoryTests.map((sensoryTest) => (
                <SensoryTestCard
                  sensoryTest={sensoryTest}
                  key={sensoryTest.id}
                  isSelected={
                    selectedTests ? selectedTests.some((test) => test.id === sensoryTest.id) : false
                  }
                  onCheckboxChange={() => onSensoryTestCheckboxChanged(sensoryTest)}
                />
              ))}
            {!isLoading && !filteredSensoryTests.length && (
              <Placeholder
                className={styles.noTestsPlaceholder}
                image={emptyFlasks}
                title="This is a little bit empty."
                subTitle="Create a new sensory test with the button above"
              />
            )}
          </div>
          <SensoryTestCreationDialog
            isOpen={isCreationModalShown}
            onClose={handleCloseNewSensoryTestDialog}
          />
        </>
      </PageLayout.Main>
      <ConfirmDialog
        title={`Delete ${selectedTests.length} sensory tests`}
        className={styles.deleteModal}
        message={
          <>
            Are you sure you want to delete this test? All <b>results</b> from panelists will also
            be deleted.
          </>
        }
        isOpen={isDeleteDialogOpen}
        confirmButtonLabel={`Delete ${selectedTests.length} sensory tests`}
        cancelButtonOnClick={() => setIsDeleteDialogOpen(false)}
        confirmButtonOnClick={onConfirmDelete}
        isLoading={isDeletePending}
      />
      {selectedTests.length === 1 && (
        <CloneDialog
          isOpen={isCloneDialogOpen}
          sensoryTest={selectedTests[0]}
          onClose={handleCloneClose}
        />
      )}
    </PageLayout>
  );
};

export default SensoryTests;
