import React, { CSSProperties } from 'react';
import { SensoryTestType } from '@bloodhound/common/dist/models';
import clsx from 'clsx';

import styles from './ProgressCircle.module.css';

type Props = {
  progressRatio: number;
  progressRatioSecondary?: number;
  sensoryTestType: SensoryTestType;
  sampleIndex: number;
  width?: number;
  barWidth?: number;
  className?: string;
};

const ProgressCircle: React.FC<Props> = ({
  progressRatio,
  progressRatioSecondary = progressRatio,
  sensoryTestType,
  sampleIndex,
  width = 50,
  barWidth = 7,
  className,
}: Props) => {
  return (
    <div className={className}>
      <div
        className={clsx(styles.pie, styles.background, styles.noRound)}
        style={
          {
            '--progressPercentage': 100,
            '--color': `var(--contrast-2)`,
            '--width': `${width}px`,
            '--barWidth': `${barWidth}px`,
          } as CSSProperties
        }
      >
        <div
          className={clsx(styles.pie, styles.progress)}
          style={
            {
              '--progressPercentage': progressRatioSecondary * 100,
              '--color': `var(--${sensoryTestType}-sample-${sampleIndex + 1}-1)`,
              '--width': `${width}px`,
              '--barWidth': `${barWidth}px`,
              opacity: progressRatioSecondary ? 1 : 0,
            } as CSSProperties
          }
        />
        <div
          className={clsx(styles.pie, styles.progress)}
          style={
            {
              '--progressPercentage': progressRatio * 100,
              '--color': `var(--${sensoryTestType}-sample-${sampleIndex + 1}-2)`,
              '--width': `${width}px`,
              '--barWidth': `${barWidth}px`,
              opacity: progressRatio ? 1 : 0,
            } as CSSProperties
          }
        />
      </div>
    </div>
  );
};

export default ProgressCircle;
