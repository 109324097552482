import React from 'react';
import { SensoryTest } from '@bloodhound/common/dist/models';
import clsx from 'clsx';

import styles from './SensoryTestInfoSection.module.css';

export interface Props {
  sensoryTest?: SensoryTest;
  isHidden?: boolean;
}

const SensoryTestInfoSection: React.FC<Props> = ({ sensoryTest, isHidden = false }) => {
  return (
    <section className={clsx(styles.explanationContainer, { [styles.hidden]: isHidden })}>
      <div className={styles.aboutContainer}>
        <h1 className={styles.aboutTitle}>About</h1>

        <h2 className={styles.aboutSubTitle}>
          {sensoryTest?.type === 'twoFive' && 'How does a two out of five test work?'}
          {sensoryTest?.type === 'triangle' && 'How does a triangle test work?'}
          {sensoryTest?.type === 'qda' && 'How does a descriptive analysis work?'}
        </h2>
        <p className={styles.aboutDescription}>
          {sensoryTest?.type === 'twoFive' &&
            'You will receive 3 samples of the same product and 2 samples of a different product. As a panelist, you have to identify which 2 samples are the different product.'}
          {sensoryTest?.type === 'triangle' &&
            'You will receive 2 samples of the same product and 1 sample of a different product. As a panelist, you have to identify which is the unique sample.'}
          {sensoryTest?.type === 'qda' &&
            'You will receive one or more samples, which you will need to grade on multiple sensory parameters.'}
        </p>

        <h2 className={styles.aboutSubTitle}>How should I prepare?</h2>
        <p className={styles.aboutDescription}>
          To participate, you need a stable internet connection. Besides technical requirements,
          it’s best to not be distracted and complete the test in a single run.
        </p>
      </div>

      <span className={styles.senslyCredits}>
        Made possible by{' '}
        <a className={styles.senslyLink} href="https://sensly.be" target="_blank" rel="noreferrer">
          Sensly
        </a>
      </span>
    </section>
  );
};

export default SensoryTestInfoSection;
