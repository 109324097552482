import React from 'react';
import { QdaSample, QdaTest } from '@bloodhound/common/dist/models/sensoryTest';
import { FieldArray, FormikProps } from 'formik';
import { Button, FormField, Input, PlusCircle, TextArea, Trash2 } from 'ventura';
import * as Yup from 'yup';

import { SampleIcon } from 'components/atoms';
import { generateUniqueQdaSampleId } from 'utils/random';

import styles from './QdaTestForm.module.css';

const MAX_SAMPLES = 8;

export type QdaTestFormValues = Omit<
  QdaTest,
  'workspaceId' | 'name' | 'type' | 'id' | 'dateCreated' | 'formElements' | 'significanceLevel'
> & { significanceLevel: string };

export const qdaTestDefaultFormValues: QdaTestFormValues = {
  samples: [
    {
      id: generateUniqueQdaSampleId([]),
      name: '',
    },
  ],
  significanceLevel: '0.05',
  instructions: '',
  purpose: '',
};

export const qdaTestFormValidationSchema = Yup.object().shape<Partial<QdaTestFormValues>>({
  // @ts-ignore
  samples: Yup.array().when('type', {
    is: 'qda',
    then: Yup.array()
      .of(
        Yup.object().shape<QdaSample>({
          id: Yup.number().required(),
          name: Yup.string().label('Sample name').required(),
        }),
      )
      .min(1, 'You need to add a minimum of 1 sample.')
      .max(MAX_SAMPLES, `A test can only contain up to ${MAX_SAMPLES} samples.`)
      .required(),
  }),
  // @ts-ignore
  significanceLevel: Yup.number().label('Significance level').required().moreThan(0).lessThan(1),
});

interface Props {
  formik: FormikProps<QdaTestFormValues>;
}

const QdaTestForm: React.FC<Props> = ({ formik }: Props) => {
  const { handleChange, handleBlur, values, errors } = formik;

  return (
    <div className={styles.container}>
      <div className={styles.formGroup}>
        <div className={styles.formField}>
          <FieldArray name="samples">
            {({ remove, push }) => {
              const sampleError =
                errors.samples &&
                (errors.samples as any[]).find((error) => {
                  return typeof error !== 'undefined';
                });

              return (
                <>
                  <FormField label="Samples" errorMessage={sampleError && sampleError.name}>
                    {values.samples.map((sample, index) => (
                      <div key={sample?.id} className={styles.sampleInputContainer}>
                        <SampleIcon
                          className={styles.sampleIcon}
                          id={sample?.id}
                          sensoryTestType="qda"
                          index={index}
                        />
                        <FormField className={styles.sampleFormField}>
                          <Input
                            name={`samples.${index}.name`}
                            placeholder="Sample name"
                            value={sample?.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={Boolean(errors.samples && errors.samples[index])}
                          />
                        </FormField>
                        {index > 0 && (
                          <Button onClick={() => remove(index)} type="secondary">
                            <Trash2 />
                          </Button>
                        )}
                      </div>
                    ))}
                  </FormField>
                  {values.samples.length < MAX_SAMPLES && (
                    <Button
                      prefixIcon={<PlusCircle />}
                      onClick={() =>
                        push({ id: generateUniqueQdaSampleId(values.samples), name: '' })
                      }
                    >
                      Add sample
                    </Button>
                  )}
                </>
              );
            }}
          </FieldArray>
        </div>

        <FormField
          label="Significance level (ɑ)"
          errorMessage={errors.significanceLevel}
          className={styles.formField}
        >
          <Input
            type="number"
            name="significanceLevel"
            placeholder="Level"
            value={values.significanceLevel}
            onChange={handleChange}
            onBlur={handleBlur}
            isInvalid={Boolean(errors.significanceLevel)}
            className={styles.significanceLevelInput}
          />
        </FormField>
      </div>
      <FormField
        className={styles.formField}
        label="Participant instructions"
        errorMessage={errors.instructions as string}
      >
        <TextArea
          name="instructions"
          placeholder="Instructions to help your panelists"
          isInvalid={Boolean(errors.instructions)}
          value={values.instructions}
          onChange={handleChange}
          onBlur={handleBlur}
          className={styles.instructions}
        />
      </FormField>

      <FormField
        className={styles.formField}
        label="The purpose of this test (optional)"
        errorMessage={errors.purpose as string}
      >
        <TextArea
          name="purpose"
          placeholder=""
          isInvalid={Boolean(errors.purpose)}
          value={values.purpose}
          onChange={handleChange}
          onBlur={handleBlur}
          className={styles.purpose}
        />
      </FormField>
    </div>
  );
};

export default QdaTestForm;
