import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './Card.module.css';

type Props = {
  children: ReactNode;
  className?: string;
};

const Card: React.FC<Props> = ({ children, className }: Props) => {
  return <div className={clsx(className, styles.card)}>{children}</div>;
};

export default Card;
