import React, { Fragment, useMemo } from 'react';
import { FormElement, QdaSample, QdaTestParticipant } from '@bloodhound/common/dist/models';

import {
  CategoryScaleResult,
  CommentResult,
  FormNavigation,
  LineScaleResult,
} from 'components/molecules';
import {
  isCategoryScaleFormElement,
  isCommentFormElement,
  isLineScaleFormElement,
} from 'utils/typeGuards';
import {
  createBarChartData,
  createBoxPlotData,
  createCommentResultData,
  getFormElementsByPage,
} from './chartData';

import styles from './ResultsByPage.module.css';

type Props = {
  participants: QdaTestParticipant[];
  samples: QdaSample[];
  formElements: FormElement[];
  significanceLevel: number;
  className?: string;
};

const ResultsByPage: React.FC<Props> = ({
  participants,
  samples,
  formElements,
  significanceLevel,
  className,
}: Props) => {
  const formElementsByPage = useMemo(() => getFormElementsByPage(formElements), [formElements]);

  return (
    <div className={className}>
      {formElementsByPage.map((page, index) => (
        <div className={styles.page} key={page.id ?? 'no-pages'}>
          {Boolean(page.id) && (
            <FormNavigation
              currentPageIndex={index}
              pageCount={formElementsByPage.length}
              title={page.name as string}
            />
          )}
          <div className={styles.resultBlocks}>
            {page.formElements.map((formElement) => (
              <Fragment key={formElement.id}>
                {isLineScaleFormElement(formElement) && (
                  <LineScaleResult
                    minimumValue={formElement.valueBoundaries.minimum}
                    maximumValue={formElement.valueBoundaries.maximum}
                    name={formElement.dataLabel || formElement.name}
                    significanceLevel={significanceLevel}
                    boxPlots={createBoxPlotData(formElement.id, samples, participants)}
                  />
                )}
                {isCategoryScaleFormElement(formElement) && (
                  <CategoryScaleResult
                    name={formElement.dataLabel || formElement.name}
                    barCharts={createBarChartData(formElement, samples, participants)}
                  />
                )}
                {isCommentFormElement(formElement) && (
                  <CommentResult
                    name={formElement.dataLabel || formElement.name}
                    comments={createCommentResultData(formElement, participants)}
                    samples={samples}
                  />
                )}
              </Fragment>
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default ResultsByPage;
