import React, { useEffect } from 'react';
import { detect } from 'detect-browser';
import { toaster } from 'ventura';

interface Props {
  children?: React.ReactNode;
}

const createBrowserMessage = () => {
  const browser = detect();
  if (!browser || browser.type !== 'browser') {
    return undefined;
  }

  switch (browser.name) {
    case 'chrome':
      if (parseFloat(browser.version) < 56) {
        return 'You are using an an old version of Chrome. Some features might not work as expected.';
      }
      return undefined;
    case 'firefox':
      if (parseFloat(browser.version) < 52) {
        return 'You are using an an old version of Firefox. Some features might not work as expected.';
      }
      return undefined;
    case 'safari':
      if (parseFloat(browser.version) < 11) {
        return 'You are using an an old version of Safari. Some features might not work as expected.';
      }
      return undefined;
    case 'ie':
      return 'You are using Internet Explorer. This browser is outdated. Some features might not work as expected.';
    case 'edge':
      if (parseFloat(browser.version) < 19) {
        return 'You are using the old Microsoft Edge. Some features might not work as expected. Consider switching to the Chromium based Microsoft edge.';
      }
      return undefined;
    case 'opera-mini':
      return 'You are using an unsupported browser. Some features might not work as expected.';
    default:
      return undefined;
  }
};

const OldBrowserAlert: React.FC<Props> = () => {
  useEffect(() => {
    const browserMessage = createBrowserMessage();
    if (browserMessage) {
      toaster.warning(browserMessage, { durationInSeconds: 0 });
    }
  }, []);

  return null;
};

export default OldBrowserAlert;
