export const removeFalsyProperties = <T extends Record<string, any>>(
  object: T,
  forceRemoveKeys: (keyof T)[],
) =>
  Object.keys(object).reduce(
    (accumulator, key) =>
      !forceRemoveKeys.includes(key) && object[key]
        ? { ...accumulator, [key]: object[key] }
        : accumulator,
    {},
  ) as T;
