import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SensoryTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import clsx from 'clsx';

import spilledFlasks from 'assets/spilledFlasks.svg';
import { Placeholder, SensoryTestFormLoader } from 'components/atoms';
import SensoryTestParticipantForm from 'components/organisms/SensoryTestParticipantForm/SensoryTestParticipantForm';
import {
  useSensoryTestParticipant,
  useSensoryTestParticipantEditor,
} from 'services/sensoryTestParticipantService';
import { useSensoryTest } from 'services/sensoryTestService';

import styles from './SensoryTestForm.module.css';

interface Props {}

const SensoryTestForm: React.FC<Props> = () => {
  const { id: sensoryTestId, code: accessCodeFromUrl } = useParams<{ id: string; code: string }>();

  const history = useHistory();
  const { sensoryTest, error: sensoryTestError, isLoading: isSensoryTestLoading } = useSensoryTest(
    sensoryTestId,
  );

  const {
    participant,
    error: participantError,
    isLoading: isParticipantLoading,
  } = useSensoryTestParticipant(sensoryTestId, accessCodeFromUrl, { realtime: false });

  const sensoryTestParticipantEditorHook = useSensoryTestParticipantEditor(sensoryTestId);

  const onSubmitTest = async (updatedParticipant: SensoryTestParticipant): Promise<boolean> => {
    return sensoryTestParticipantEditorHook.editParticipant(updatedParticipant);
  };

  useEffect(() => {
    if (participantError && !participant) {
      history.push(`/test/${sensoryTest?.id}/login`, {
        error: participantError,
      });
    }
  }, [participantError, history, sensoryTest?.id, participant]);

  const isPageLoading = isSensoryTestLoading || isParticipantLoading;

  return (
    <div
      className={clsx(styles.screen, {
        [styles.top]: participant,
      })}
    >
      <div className={styles.formContainer}>
        {!isPageLoading && !sensoryTestError && sensoryTest && (
          <div className={styles.form}>
            {participant && (
              <SensoryTestParticipantForm
                participant={participant}
                sensoryTest={sensoryTest}
                onSubmit={onSubmitTest}
                error={sensoryTestParticipantEditorHook.error?.message}
              />
            )}
          </div>
        )}
        {!isPageLoading && sensoryTestError && (
          <Placeholder
            className={styles.notFoundPlaceholder}
            image={spilledFlasks}
            title="Oops! This was not what we expected."
            subTitle="The sensory test was not found."
          />
        )}
        {isPageLoading && <SensoryTestFormLoader />}
      </div>
    </div>
  );
};

export default SensoryTestForm;
