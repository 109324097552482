import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { SensoryTestType } from '@bloodhound/common/dist/models';

import { ReactComponent as Shape } from 'assets/sensoryTestFormShape.svg';
import spilledFlasks from 'assets/spilledFlasks.svg';
import { Placeholder, SensoryTestFormInfoLoader } from 'components/atoms';
import { SensoryTestInfoSection } from 'components/molecules';
import PanelistWelcomeScreen from 'components/organisms/PanelistWelcomeScreen/PanelistWelcomeScreen';
import { useSensoryTest } from 'services/sensoryTestService';
import { useWorkspace } from 'services/workspaceService';

import styles from './SensoryTestFormWelcome.module.css';

export const SensoryTestFormWelcome = () => {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();

  const { sensoryTest, error: sensoryTestError, isLoading: isSensoryTestLoading } = useSensoryTest(
    id,
  );

  const { workspace, isLoading: isWorkspaceLoading, error: workspaceError } = useWorkspace(
    sensoryTest?.workspaceId,
  );

  const isPageLoading = isWorkspaceLoading || isSensoryTestLoading;

  const pageError = sensoryTestError || workspaceError;

  return (
    <div className={styles.screen}>
      <Shape
        style={{
          fill: `var(--${
            !isSensoryTestLoading && sensoryTest?.type ? `${sensoryTest?.type}-2` : 'contrast-4'
          })`,
        }}
        className={styles.largeShape}
      />
      <Shape
        style={{
          fill: `var(--${
            !isSensoryTestLoading && sensoryTest?.type ? `${sensoryTest?.type}-3` : 'contrast-5'
          })`,
        }}
        className={styles.smallShape}
      />
      <SensoryTestInfoSection sensoryTest={sensoryTest} />
      <section className={styles.formContainer}>
        {!isPageLoading && (
          <>
            {!pageError && (
              <div className={styles.form}>
                <PanelistWelcomeScreen
                  sensoryTestType={sensoryTest?.type as SensoryTestType}
                  companyLogoSrc={workspace?.logoSrc}
                  onStartTest={() => history.push(`/test/${sensoryTest?.id}/login`)}
                />
              </div>
            )}

            {!isPageLoading && pageError && (
              <Placeholder
                className={styles.notFoundPlaceholder}
                image={spilledFlasks}
                title="Oops! This was not what we expected."
                subTitle="The sensory test was not found."
              />
            )}
          </>
        )}
        {isPageLoading && <SensoryTestFormInfoLoader />}
      </section>
    </div>
  );
};
