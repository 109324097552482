import React, { useState } from 'react';
import {
  DiscriminativeTestSettings,
  SensoryTestType,
  sensoryTestTypeNames,
} from '@bloodhound/common/dist/models/sensoryTest';
import { TwoFiveTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import { FieldArray, Form, Formik, FormikProps } from 'formik';
import { Alert, Button, Checkbox, FormField, TextArea } from 'ventura';
import * as Yup from 'yup';

import { Step, SvgIcon } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';
import TwoFiveTestParticipantFormResult from 'components/molecules/TwoFiveTestParticipantFormResult/TwoFiveTestParticipantFormResult';
import { useFirebase } from 'components/providers/FirebaseProvider';

import styles from './TwoFiveTestParticipantForm.module.css';

interface FormValues {
  selectedSampleIds: number[];
  motivation?: string;
}

const defaultFormValues: FormValues = {
  selectedSampleIds: [],
  motivation: '',
};

const getFormValidationSchema = (sampleIds: number[], isCommentFieldRequired: boolean) =>
  Yup.object().shape<FormValues>({
    selectedSampleIds: Yup.array()
      .of(Yup.number().oneOf(sampleIds).required())
      .label('Different samples')
      .min(2)
      .max(2)
      .required(),
    motivation: isCommentFieldRequired
      ? Yup.string().label('Motivation').required()
      : Yup.string().label('Motivation'),
  });

interface Props {
  participant: TwoFiveTestParticipant;
  instructions: string;
  onSubmit: (participant: TwoFiveTestParticipant) => Promise<boolean>;
  error?: string;
  settings: DiscriminativeTestSettings;
}

const TwoFiveTestParticipantForm: React.FC<Props> = ({
  participant,
  instructions,
  onSubmit,
  error,
  settings,
}) => {
  const [submittedParticipant, setUpdatedParticipant] = useState<TwoFiveTestParticipant>();
  const firebase = useFirebase();

  const handleSubmit = async (formValues: FormValues) => {
    const newParticipant: TwoFiveTestParticipant = {
      ...(participant as TwoFiveTestParticipant),
      motivation: formValues.motivation,
      selectedSampleIds: formValues.selectedSampleIds,
    };

    const isSubmitSuccessful = await onSubmit(newParticipant);

    if (isSubmitSuccessful) {
      setUpdatedParticipant(newParticipant);
      firebase.analytics().logEvent('participant_form_submit', {
        participantId: participant.id,
        sensoryTestType: 'twoFive' as SensoryTestType,
      });
    }
  };

  const sampleIds = [
    participant.firstSampleId,
    participant.secondSampleId,
    participant.thirdSampleId,
    participant.fourthSampleId,
    participant.fifthSampleId,
  ];

  return (
    <>
      <header className={styles.header}>
        <div className={styles.title}>
          <SvgIcon
            icon={`sensoryTests/${participant.sensoryTest.type}` as SvgIconName}
            className={styles.icon}
          />
          <h1>{sensoryTestTypeNames[participant.sensoryTest.type]}</h1>
        </div>
      </header>
      {error && <Alert className={styles.error} intent="error" message={error} />}
      {submittedParticipant ? (
        <TwoFiveTestParticipantFormResult
          participant={submittedParticipant as TwoFiveTestParticipant}
        />
      ) : (
        <Formik
          onSubmit={handleSubmit}
          initialValues={defaultFormValues}
          validationSchema={getFormValidationSchema(sampleIds, settings.isCommentFieldRequired)}
          validateOnChange={false}
          validateOnBlur={false}
        >
          {(formik: FormikProps<FormValues>) => (
            <Form className={styles.form}>
              <Step number={1} title="Taste the samples in the correct order">
                <ul className={styles.sampleIdsOrder}>
                  {sampleIds.map((id) => (
                    <li key={id} className={styles.id}>
                      {id}
                    </li>
                  ))}
                </ul>
                <p className={styles.info}>{instructions}</p>
              </Step>
              <Step number={2} title="Select the 2 different samples">
                <FormField errorMessage={formik.errors.selectedSampleIds}>
                  <FieldArray
                    name="selectedSampleIds"
                    render={(arrayHelpers) => (
                      <div className={styles.checkBoxContainer}>
                        {sampleIds.map((sampleId) => (
                          <Checkbox
                            name={`${sampleId}`}
                            value={formik.values.selectedSampleIds.includes(sampleId)}
                            onChange={(e) => {
                              if (e.target.checked) {
                                arrayHelpers.push(sampleId);
                              } else {
                                const index = formik.values.selectedSampleIds.indexOf(sampleId);
                                arrayHelpers.remove(index);
                              }
                            }}
                            key={sampleId}
                            label={sampleId}
                          />
                        ))}
                      </div>
                    )}
                  />
                </FormField>
              </Step>
              <Step
                number={3}
                title={`Motivate your decision${
                  settings.isCommentFieldRequired ? '' : ' (optional)'
                }`}
              >
                <FormField errorMessage={formik.errors.motivation}>
                  <TextArea
                    name="motivation"
                    value={formik.values.motivation}
                    onChange={formik.handleChange}
                    className={styles.motivation}
                  />
                </FormField>
              </Step>
              <div className={styles.buttons}>
                <Button
                  name="submit"
                  htmlType="submit"
                  isLoading={formik.isSubmitting}
                  className={styles.submit}
                >
                  Submit
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
};

export default TwoFiveTestParticipantForm;
