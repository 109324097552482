import React from 'react';
import ContentLoader from 'react-content-loader';

interface Props {
  className?: string;
}

export const SensoryTestFormLoader: React.FC<Props> = ({ className }) => (
  <ContentLoader
    viewBox="0 0 375 600"
    width="375"
    height="600"
    uniqueKey="SensoryTestForm"
    className={className}
  >
    <rect x="28" y="30" rx="4" ry="4" width="30" height="30" />
    <rect x="66" y="30" rx="4" ry="4" width="260" height="30" />
    <rect x="28" y="90" rx="4" ry="4" width="320" height="20" />
    <rect x="28" y="116" rx="4" ry="4" width="250" height="20" />
    <rect x="28" y="142" rx="4" ry="4" width="270" height="20" />
    <rect x="28" y="168" rx="4" ry="4" width="240" height="20" />
    <rect x="28" y="194" rx="4" ry="4" width="300" height="20" />
    <rect x="28" y="220" rx="4" ry="4" width="280" height="20" />
  </ContentLoader>
);
