import React from 'react';
import { TwoFiveTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';

import styles from './TwoFiveTestParticipantFormResult.module.css';

interface Props {
  participant: TwoFiveTestParticipant;
}

const TwoFiveTestParticipantFormResult: React.FC<Props> = ({ participant }) => {
  const isCorrect =
    participant.uniqueSampleIds?.includes(participant?.selectedSampleIds?.[0] || 0) &&
    participant.uniqueSampleIds?.includes(participant?.selectedSampleIds?.[1] || 0);
  const isHalfCorrect =
    participant.uniqueSampleIds?.includes(participant?.selectedSampleIds?.[0] || 0) ||
    participant.uniqueSampleIds?.includes(participant?.selectedSampleIds?.[1] || 0);

  let text = `The different samples weren't identified. The correct samples are ${participant?.uniqueSampleIds?.[0]} & ${participant?.uniqueSampleIds?.[1]}`;

  if (isCorrect) {
    text = 'Well done, you correctly identified the 2 different samples.';
  } else if (isHalfCorrect) {
    const correctSample = participant?.selectedSampleIds?.find((selectedSampleId) =>
      participant.uniqueSampleIds.includes(selectedSampleId),
    );
    const otherSample = participant.uniqueSampleIds.find(
      (uniqueSampleId) => uniqueSampleId !== correctSample,
    );

    text = `Almost! You correctly identified sample ${correctSample}, but you didn't identify sample ${otherSample}.`;
  }

  const hasPurpose =
    participant.sensoryTest?.purpose !== undefined &&
    participant.sensoryTest?.purpose !== null &&
    participant.sensoryTest?.purpose !== '';

  return (
    <>
      <h2 className={styles.title}>{text}</h2>
      <h3 className={styles.subTitle}>Thank you for participating!</h3>
      {hasPurpose && (
        <>
          <div className={styles.purposeTitle}>The purpose of this sensory test:</div>
          <div className={styles.purpose}>{participant.sensoryTest.purpose}</div>
        </>
      )}
    </>
  );
};

export default TwoFiveTestParticipantFormResult;
