import React from 'react';
import ContentLoader from 'react-content-loader';
import { SensoryTestType } from '@bloodhound/common/dist/models';
import clsx from 'clsx';

import { SvgIcon } from '../../atoms';
import { SvgIconName } from '../../atoms/SvgIcon/SvgIcon';

import styles from './SensoryTestFormLogo.module.css';

interface Props {
  sensoryTestType?: SensoryTestType;
  companyLogoSrc?: string;
  className?: string;
  isLoading?: boolean;
}

export const SensoryTestFormLogo: React.FC<Props> = ({
  sensoryTestType,
  companyLogoSrc,
  className,
  isLoading = false,
}) => {
  const allClassNames = clsx(styles.icon, className);

  return (
    <>
      {!isLoading && (companyLogoSrc || sensoryTestType) && (
        <>
          {companyLogoSrc ? (
            <img src={companyLogoSrc} className={allClassNames} alt="Company logo" />
          ) : (
            <SvgIcon
              icon={`sensoryTests/${sensoryTestType}` as SvgIconName}
              className={allClassNames}
            />
          )}
        </>
      )}

      {(isLoading || (!companyLogoSrc && !sensoryTestType)) && (
        <ContentLoader
          viewBox="0 0 26 26"
          className={allClassNames}
          uniqueKey="SensoryTestFormLogo"
        >
          <rect x="0" y="0" rx="4" ry="4" width="26" height="26" />
        </ContentLoader>
      )}
    </>
  );
};
