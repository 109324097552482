import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SensoryTest, sensoryTestTypeNames } from '@bloodhound/common/dist/models/sensoryTest';
import clsx from 'clsx';

//import { Checkbox } from 'ventura';
import { SampleIcon, SvgIcon } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';
import { SensoryTestTagsDisplay } from 'components/molecules';
import { isQdaTest, isTriangleTest, isTwoFiveTest } from 'utils/typeGuards';

import styles from './SensoryTestCard.module.css';

type Props = {
  sensoryTest: SensoryTest;
  isSelected: boolean;
  onCheckboxChange: () => void;
};

const SensoryTestCard: React.FC<Props> = ({ sensoryTest, isSelected, onCheckboxChange }: Props) => {
  const [cardStyles, setCardStyles] = useState(clsx(styles.card));

  // const handleCheckboxClick = (e: React.MouseEvent) => {
  //   e.stopPropagation();
  // };

  useEffect(() => {
    const newCardStyles = isSelected ? clsx(styles.card, styles.selected) : clsx(styles.card);
    setCardStyles(newCardStyles);
  }, [isSelected]);

  const date: any = sensoryTest.dateCreated;

  let formattedDate = '';
  if (date instanceof Date) {
    formattedDate = date.toLocaleDateString('en-GB');
  } else if (typeof date === 'string') {
    formattedDate = new Date(date.toString() as string).toLocaleDateString('en-GB');
  } else if (typeof date === 'object') {
    formattedDate = new Date(date.toDate()).toLocaleDateString('en-GB');
  }

  return (
    <div className={cardStyles}>
      <div className={styles.checkbox}>
        {/* <Checkbox
          label=""
          value={isSelected}
          name={`test-${sensoryTest.id}`}
          onChange={onCheckboxChange}
        /> */}
        <label className={styles.checkboxContainer}>
          <input
            type="checkbox"
            checked={isSelected}
            name={`test-${sensoryTest.id}`}
            onChange={onCheckboxChange}
          />
          <span className={styles.checkboxMark}></span>
        </label>
      </div>
      <Link className={styles.cardLink} to={`sensoryTests/${sensoryTest.id}`}>
        <div className={styles.header}>
          <div className={styles.cardContainer}>
            <h4 className={styles.title}>
              <SvgIcon
                className={styles.logo}
                icon={`sensoryTests/${sensoryTest.type}` as SvgIconName}
              />
              <span className={styles.text}>{sensoryTest.name}</span>
            </h4>
            <p className={styles.type}>{sensoryTestTypeNames[sensoryTest.type]}</p>
          </div>
          <div className={styles.tagsDateCreatedContainer}>
            {sensoryTest.tags && Boolean(sensoryTest.tags?.length) && (
              <div className={styles.tagsContainer}>
                <SensoryTestTagsDisplay tags={sensoryTest.tags} />
              </div>
            )}
            <div className={styles.dateCreatedContainer}>{formattedDate}</div>
          </div>
        </div>
        <div className={styles.samplesContainer}>
          {isTriangleTest(sensoryTest) && (
            <>
              <span className={styles.sample}>
                <SampleIcon id="A" sensoryTestType="triangle" index={0} />
                <span className={styles.sampleText}>{sensoryTest.sampleNameA}</span>
              </span>
              <span className={styles.sample}>
                <SampleIcon id="B" sensoryTestType="triangle" index={1} />
                <span className={styles.sampleText}>{sensoryTest.sampleNameB}</span>
              </span>
            </>
          )}
          {isTwoFiveTest(sensoryTest) && (
            <>
              <span className={styles.sample}>
                <SampleIcon id="A" sensoryTestType="twoFive" index={0} />
                <span className={styles.sampleText}>{sensoryTest.sampleNameA}</span>
              </span>
              <span className={styles.sample}>
                <SampleIcon id="B" sensoryTestType="twoFive" index={1} />
                <span className={styles.sampleText}>{sensoryTest.sampleNameB}</span>
              </span>
            </>
          )}
          {isQdaTest(sensoryTest) && (
            <>
              {sensoryTest.samples.map((sample, index) => (
                <span key={sample.id} className={styles.sample}>
                  <SampleIcon id={sample.id} sensoryTestType="qda" index={index} />
                  <span className={styles.sampleText}>{sample.name}</span>
                </span>
              ))}
            </>
          )}
        </div>
      </Link>
    </div>
  );
};

export default SensoryTestCard;
