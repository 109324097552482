import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { FirebaseError } from '@bloodhound/common/dist/models';

import { ReactComponent as Shape } from 'assets/sensoryTestFormShape.svg';
import spilledFlasks from 'assets/spilledFlasks.svg';
import { Placeholder, SensoryTestFormLoginLoader } from 'components/atoms';
import { SensoryTestFormLogo } from 'components/molecules';
import { AccessCodeForm } from 'components/organisms';
import SampleCodesForm, {
  SampleCodesFormValues,
} from 'components/organisms/SampleCodesForm/SampleCodesForm';
import {
  useSensoryTestParticipant,
  useSensoryTestParticipantAccessCode,
} from 'services/sensoryTestParticipantService';
import { useSensoryTest } from 'services/sensoryTestService';
import { useWorkspace } from 'services/workspaceService';

import styles from './SensoryTestFormLogin.module.css';

export const SensoryTestFormLogin = () => {
  const { id } = useParams<{ id: string }>();

  const { state } = useLocation<{ error?: FirebaseError } | undefined>();

  const { sensoryTest, error: sensoryTestError, isLoading: isSensoryTestLoading } = useSensoryTest(
    id,
  );

  const { workspace, isLoading: isWorkspaceLoading, error: workspaceError } = useWorkspace(
    sensoryTest?.workspaceId,
  );

  const history = useHistory();

  const {
    fetchWithAccessCode,
    fetchWithSampleCodes,
    accessCode,
    error: accessCodeError,
  } = useSensoryTestParticipantAccessCode(id);

  const { participant, error: participantError } = useSensoryTestParticipant(
    sensoryTest?.id,
    accessCode,
  );

  useEffect(() => {
    if (participant?.id && sensoryTest?.id) {
      history.push(`/test/${sensoryTest?.id}/${participant.id}`);
    }
  }, [participant?.id, sensoryTest?.id, history]);

  const onSubmitAccessCode = async (submittedAccessCode: string) => {
    await fetchWithAccessCode(submittedAccessCode.toUpperCase());
  };

  const onSubmitSampleCodes = async (formValues: SampleCodesFormValues) => {
    const sampleCodes = [
      formValues.firstSampleCode,
      formValues.secondSampleCode,
      formValues.thirdSampleCode,
    ];

    if (formValues.fourthSampleCode && formValues.fifthSampleCode) {
      sampleCodes.push(formValues.fourthSampleCode);
      sampleCodes.push(formValues.fifthSampleCode);
    }

    await fetchWithSampleCodes(sampleCodes);
  };

  const isPageLoading = isWorkspaceLoading || isSensoryTestLoading;

  const pageError = sensoryTestError || workspaceError;

  return (
    <div className={styles.screen}>
      <Shape
        style={{
          fill: `var(--${
            !isSensoryTestLoading && sensoryTest?.type ? `${sensoryTest?.type}-2` : 'contrast-4'
          })`,
        }}
        className={styles.largeShape}
      />
      <Shape
        style={{
          fill: `var(--${
            !isSensoryTestLoading && sensoryTest?.type ? `${sensoryTest?.type}-3` : 'contrast-5'
          })`,
        }}
        className={styles.smallShape}
      />

      <SensoryTestFormLogo
        companyLogoSrc={workspace?.logoSrc}
        sensoryTestType={sensoryTest?.type}
        className={styles.icon}
        isLoading={isPageLoading}
      />

      {!isPageLoading && (
        <>
          {!pageError && (
            <div className={styles.form}>
              {sensoryTest?.type === 'qda' && (
                <AccessCodeForm
                  onSubmit={onSubmitAccessCode}
                  error={
                    accessCodeError?.message || participantError?.message || state?.error?.message
                  }
                />
              )}
              {(sensoryTest?.type === 'triangle' || sensoryTest?.type === 'twoFive') && (
                <SampleCodesForm
                  sensoryTestType={sensoryTest.type}
                  onSubmit={onSubmitSampleCodes}
                  error={
                    accessCodeError?.message || participantError?.message || state?.error?.message
                  }
                  companyLogoSrc={workspace?.logoSrc}
                />
              )}
            </div>
          )}

          {!isPageLoading && pageError && (
            <Placeholder
              className={styles.notFoundPlaceholder}
              image={spilledFlasks}
              title="Oops! This was not what we expected."
              subTitle="The sensory test was not found."
            />
          )}
        </>
      )}

      {isPageLoading && <SensoryTestFormLoginLoader className={styles.loginFormLoading} />}
    </div>
  );
};
