import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';

import App from './app/App';
import { getEnvValue } from './services/configService';
import * as serviceWorker from './serviceWorker';

import './variables.css';
import './fonts.css';
import './index.css';

if (getEnvValue('NODE_ENV') === 'production') {
  Sentry.init({
    dsn: 'https://fb2f26e2c03b42158b9712c43cbfdfc7@o342831.ingest.sentry.io/1887318',
    release: `bloodhound@${getEnvValue('REACT_APP_VERSION')}`,
    environment: getEnvValue('REACT_APP_HOSTING_ENV'),
  });
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
