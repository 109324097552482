import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Alert, Button, FormField, Input } from 'ventura';
import * as Yup from 'yup';

import styles from './AccessCodeForm.module.css';

interface FormValues {
  accessCode: string;
}

const defaultFormValues: FormValues = {
  accessCode: '',
};

const formValidationSchema = Yup.object().shape<FormValues>({
  accessCode: Yup.string().label('access code').length(6).required(),
});

interface Props {
  onSubmit: (accessCode: string) => void;
  error?: string;
}

const AccessCodeForm: React.FC<Props> = ({ onSubmit, error }) => {
  return (
    <>
      <h3 className={styles.title}>Enter your access code to get started.</h3>

      <p className={styles.description}>
        Your access code is a 6-character code handed you by the organizer of this test.
      </p>

      {error && <Alert className={styles.error} intent="error" message={error} />}

      <Formik
        onSubmit={({ accessCode }) => onSubmit(accessCode)}
        initialValues={defaultFormValues}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik: FormikProps<FormValues>) => (
          <Form>
            <FormField errorMessage={formik.errors.accessCode}>
              <div className={styles.form}>
                <Input
                  name="accessCode"
                  maxLength={6}
                  placeholder="Access code"
                  onChange={formik.handleChange}
                  value={formik.values.accessCode}
                  className={styles.accessCodeInput}
                />
                <Button
                  className={styles.button}
                  htmlType="submit"
                  isDisabled={formik.values.accessCode.length < 6}
                  isLoading={formik.isSubmitting}
                  name="continue"
                >
                  Continue
                </Button>
              </div>
            </FormField>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default AccessCodeForm;
