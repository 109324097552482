import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './TabHeader.module.css';

type Props = {
  title: string;
  description: string;
  className?: string;
  children?: ReactNode;
};

const TabHeader: React.FC<Props> = ({ title, description, className, children }: Props) => {
  const classNames = clsx(styles.tabHeader, className);

  return (
    <div className={classNames}>
      <div className={styles.text}>
        <h3 className={styles.title}>{title}</h3>
        <p className={styles.description}>{description}</p>
      </div>
      <div className={styles.children}>{children}</div>
    </div>
  );
};

export default TabHeader;
