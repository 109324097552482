import { useEffect, useRef } from 'react';
import { DraggableChildrenFn, DraggingStyle } from 'react-beautiful-dnd';
import { createPortal } from 'react-dom';

// Dragging item is rendered in incorrect position combined with 'position: fixed' portal
// https://github.com/atlassian/react-beautiful-dnd/issues/128#issuecomment-669083882
export const useDraggableInPortal = () => {
  const state = useRef<{ elt?: HTMLElement }>({}).current;

  useEffect(() => {
    const div = document.createElement('div');
    div.style.position = 'absolute';
    div.style.pointerEvents = 'none';
    div.style.top = '0';
    div.style.width = '100%';
    div.style.height = '100%';
    div.className = 'dragging';
    state.elt = div;
    document.body.appendChild(div);
    return () => {
      document.body.removeChild(div);
    };
  }, [state]);

  return (render: DraggableChildrenFn): DraggableChildrenFn => (provided, ...args) => {
    const element = render(provided, ...args);
    if (
      provided.draggableProps.style &&
      (provided.draggableProps.style as DraggingStyle).position &&
      (provided.draggableProps.style as DraggingStyle).position === 'fixed' &&
      state.elt
    ) {
      return createPortal(element, state.elt);
    }
    return element;
  };
};
