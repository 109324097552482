import React from 'react';
import { SensoryTestType, sensoryTestTypeNames } from '@bloodhound/common/dist/models/sensoryTest';
import { Button } from 'ventura';

import { SensoryTestFormInfoLoader } from 'components/atoms';
import { SensoryTestFormLogo } from 'components/molecules';

import styles from './PanelistWelcomeScreen.module.css';

interface Props {
  sensoryTestType?: SensoryTestType;
  companyLogoSrc?: string;
  onStartTest: () => void;
}

const PanelistWelcomeScreen: React.FC<Props> = ({
  sensoryTestType,
  companyLogoSrc,
  onStartTest,
}) => {
  const isTestLoaded = Boolean(sensoryTestType);

  return (
    <>
      {isTestLoaded ? (
        <>
          <SensoryTestFormLogo
            className={styles.icon}
            companyLogoSrc={companyLogoSrc}
            sensoryTestType={sensoryTestType}
          />
          <h1 className={styles.title}>
            {sensoryTestTypeNames[sensoryTestType as SensoryTestType]}
          </h1>
          <p className={styles.warning}>
            You are selected to be a panelist for this sensory test. Your input will be used for the
            further development of the given products.
          </p>

          <Button className={styles.button} onClick={onStartTest} name="start">
            Start
          </Button>
        </>
      ) : (
        <SensoryTestFormInfoLoader />
      )}
    </>
  );
};

export default PanelistWelcomeScreen;
