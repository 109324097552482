import ExcelJS from 'exceljs';

export const blobToExcel = async (blob: Blob): Promise<ExcelJS.Workbook> => {
  const buffer: ArrayBuffer = await new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onload = (event) => {
      if (event.target) {
        const arrayBuffer = event.target.result as ArrayBuffer;
        resolve(arrayBuffer);
      } else {
        reject();
      }
    };
    fileReader.readAsArrayBuffer(blob);
  });

  const workbook = new ExcelJS.Workbook();
  return workbook.xlsx.load(buffer);
};

export const excelToBlob = async (workbook: ExcelJS.Workbook): Promise<Blob> => {
  const buffer = await workbook.xlsx.writeBuffer();

  return new Blob([buffer]);
};

export const getExcelColumnInLetters = (index: number) => {
  let letters = '';
  let remainingColumns = index;

  while (remainingColumns > 0) {
    const currentLetter = (remainingColumns - 1) % 26;
    letters = String.fromCharCode(currentLetter + 65) + letters;
    remainingColumns = (remainingColumns - currentLetter - 1) / 26;
  }
  return letters;
};

const getExcelLettersInColumn = (letters: string) => {
  let column = 0;

  for (let i = 0; i < letters.length; i += 1) {
    column += (letters.charCodeAt(i) - 64) * 26 ** (letters.length - i - 1);
  }
  return column;
};

const autoSizeColumn = (
  worksheet: ExcelJS.Worksheet,
  columnNumber: number,
  { isFilterPresent }: { isFilterPresent: boolean },
) => {
  const column = worksheet.getColumn(columnNumber);

  let highestCellValueLength = 10;

  column.eachCell((cell) => {
    const cellValueLength = cell.value ? cell.value.toString().length : 10;

    if (cellValueLength > highestCellValueLength) {
      highestCellValueLength = cellValueLength;
    }
  });

  column.width = highestCellValueLength;

  if (isFilterPresent) {
    column.width += 2;
  }
};

const autoSizeTable = (worksheet: ExcelJS.Worksheet, table: ExcelJS.Table) => {
  const tableRef = (table as any).tableRef as string;

  const borderColumns = tableRef.match(/[A-Z]+/g);

  if (borderColumns) {
    const firstColumn = getExcelLettersInColumn(borderColumns[0]);
    const lastColumn = getExcelLettersInColumn(borderColumns[1]);

    const columns = Array.from(
      { length: lastColumn + 1 - firstColumn },
      (_v, k) => k + firstColumn,
    );

    columns.forEach((column, index) =>
      autoSizeColumn(worksheet, column, {
        isFilterPresent: Boolean(table.columns[index]?.filterButton),
      }),
    );
  }
};

interface AddTableProperties {
  name: string; // Should match /^[a-zA-Z][a-zA-Z0-9]*/
  title: string;
  ref: string;
  columns: ExcelJS.TableColumnProperties[];
  rows?: ExcelJS.CellValue[][];
}

export const addTable = (
  worksheet: ExcelJS.Worksheet,
  { name, title, ref, columns, rows = [] }: AddTableProperties,
) => {
  const refRowExtraction = ref.match(/\d+/);
  const refColumnExtraction = ref.match(/[A-Z]+/);

  if (!refRowExtraction || !refColumnExtraction) {
    throw Error("Provided table ref isn't in the valid format");
  }

  const refRow = parseInt(refRowExtraction[0], 10);
  const refColumn = refColumnExtraction[0];

  const table = (worksheet.addTable({
    name: name.replace(/[^\w]/gi, ''),
    ref: `${refColumn}${refRow + 1}`,
    headerRow: true,
    style: {
      theme: 'TableStyleLight1',
      showRowStripes: true,
    },
    columns,
    rows,
  }) as any).table as ExcelJS.Table;

  autoSizeTable(worksheet, table);

  // eslint-disable-next-line no-param-reassign
  worksheet.getCell(ref).value = title;
  // eslint-disable-next-line no-param-reassign
  worksheet.getCell(ref).alignment = { horizontal: 'center' };
  worksheet.mergeCells(
    `${ref}:${getExcelColumnInLetters(
      getExcelLettersInColumn(refColumn) + table.columns.length - 1,
    )}${refRow}`,
  );
};
