import React, { useState } from 'react';
import { Alert, Button, PlusCircle, Table } from 'ventura';

import noPanelists from 'assets/panelists.svg';
import { Placeholder, SensoryTestsContentLoader } from 'components/atoms';
import { PageHeader } from 'components/molecules';
import { PanelistCreationDialog } from 'components/organisms';
import { PageLayout } from 'components/templates';
import { useAllPanelists } from 'services/panelistService';

import styles from './Panelists.module.css';

const Panelists: React.FC = () => {
  const { panelists, isLoading, error } = useAllPanelists();
  const [isCreationModalShown, setIsCreationModalShown] = useState(false);

  const handleCloseNewSensoryTestDialog = () => {
    setIsCreationModalShown(false);
  };

  return (
    <PageLayout>
      <PageLayout.Main>
        <PageHeader title="Panelists">
          <Button onClick={() => setIsCreationModalShown(true)} prefixIcon={<PlusCircle />}>
            Create panelist
          </Button>
        </PageHeader>
        {error && <Alert intent="error" message={error?.message} />}
        {isLoading && <SensoryTestsContentLoader />}
        {!isLoading && (
          <>
            <Table>
              <Table.Header>
                <Table.Cell>Name</Table.Cell>
                <Table.Cell>Email</Table.Cell>
              </Table.Header>

              <Table.Body>
                {panelists.map((panelist) => (
                  <Table.Row key={panelist.id}>
                    <Table.Cell>
                      {panelist.firstName} {panelist.lastName}
                    </Table.Cell>
                    <Table.Cell>{panelist.email}</Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </>
        )}
        {!isLoading && !panelists.length && (
          <Placeholder
            className={styles.noPanelistsPlaceholder}
            image={noPanelists}
            title="You don't have any panelists yet"
            subTitle="Add a panelists with the button above"
          />
        )}
        <PanelistCreationDialog
          isOpen={isCreationModalShown}
          onClose={handleCloseNewSensoryTestDialog}
          existingEmails={panelists.map((panelist) => panelist.email)}
        />
      </PageLayout.Main>
    </PageLayout>
  );
};

export default Panelists;
