export const getIndexesOfLetter = (string: string, letter: string): number[] => {
  if (letter.length !== 1) {
    throw new Error('Value of arg "letter" must have length of 1');
  }

  const indexes = [];

  for (let i = 0; i < string.length; i += 1) {
    if (string[i] === letter) indexes.push(i);
  }

  return indexes;
};

export const sanitize = (str: string) => {
  return str.replace(/[^a-zA-Z0-9]/g, '-');
};
