import 'firebase/storage';
import { useEffect, useState } from 'react';
import { FirebaseError, FirestoreError } from '@bloodhound/common/dist/models/firebase';
import { Workspace } from '@bloodhound/common/dist/models/workspace';

import {
  FirebaseAnalyticsUserProperties,
  useFirebase,
} from 'components/providers/FirebaseProvider';

export const useWorkspace = (
  id: string | undefined,
): {
  workspace?: Workspace;
  isLoading: boolean;
  error?: FirebaseError;
} => {
  const [workspace, setWorkspace] = useState<Workspace>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();

  useEffect(() => {
    if (!id) {
      return undefined;
    }

    setIsLoading(true);

    const doc = firebase.firestore().collection('workspaces').doc(id);

    const subscription = doc.onSnapshot(
      async (snapshot) => {
        setError(undefined);
        const snapshotData = snapshot.data();

        if (!snapshotData) {
          setError(new FirebaseError('not-found'));
        }

        const fetchedWorkspace = snapshotData
          ? ({ ...snapshotData, id: snapshot.id } as Workspace)
          : undefined;

        if (fetchedWorkspace) {
          const imageRef = firebase.storage().ref(`workspaces/${fetchedWorkspace.id}/logo.png`);
          try {
            const src = await imageRef.getDownloadURL();
            fetchedWorkspace.logoSrc = src;
          } catch (e) {
            delete fetchedWorkspace.logoSrc;
          }
        }

        setWorkspace(fetchedWorkspace);
        setIsLoading(false);
        firebase.analytics().setUserProperties({
          workspaceId: fetchedWorkspace?.id,
          workspaceName: fetchedWorkspace?.name,
        } as FirebaseAnalyticsUserProperties);
      },
      (e: Error) => {
        const exception = e as FirestoreError;

        setWorkspace(undefined);
        setError(new FirebaseError(exception.code));
        setIsLoading(false);
      },
    );

    return subscription;
  }, [firebase, id]);

  return { workspace, isLoading, error };
};

export const useWorkspaceEditor = (): {
  editWorkspace: <T extends Workspace>(workspace: T) => Promise<boolean>;
  error?: FirebaseError;
  clearError: () => void;
  isEditPending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isEditPending, setIsEditPending] = useState<boolean>(false);
  const firebase = useFirebase();

  const editWorkspace = async <T extends Workspace>(workspace: T): Promise<boolean> => {
    setError(undefined);
    setIsEditPending(true);
    const document = firebase.firestore().collection('workspaces').doc(workspace.id);
    try {
      await document.update(workspace);
      return true;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return false;
    } finally {
      setIsEditPending(false);
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return { editWorkspace, error, isEditPending, clearError };
};
