import React, { useMemo, useState } from 'react';
import { Redirect, useParams } from 'react-router-dom';
import { sensoryTestTypeNames } from '@bloodhound/common/dist/models/sensoryTest';
import {
  QdaTestParticipant,
  TriangleTestParticipant,
  TwoFiveTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';
import { Button, Edit2, toaster, Trash2 } from 'ventura';

import { SensoryTestDetailContentLoader } from 'components/atoms';
import { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';
import { ConfirmDialog, PageHeader } from 'components/molecules';
import { SensoryTestEditDialog } from 'components/organisms';
import { PageLayout } from 'components/templates';
import { useSensoryTestParticipants } from 'services/sensoryTestParticipantService';
import { useSensoryTest, useSensoryTestRemover } from 'services/sensoryTestService';
import { isQdaTest, isTriangleTest, isTwoFiveTest } from 'utils/typeGuards';
import QdaTestDetail from './QdaTestDetail/QdaTestDetail';
import TriangleTestDetail from './TriangleTestDetail/TriangleTestDetail';
import TwoFiveTestDetail from './TwoFiveTestDetail/TwoFiveTestDetail';

import styles from './SensoryTestDetail.module.css';

const SensoryTestDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const { sensoryTest, isLoading: isSensoryTestLoading, error } = useSensoryTest(id);
  const { removeSensoryTest, isDeletePending } = useSensoryTestRemover();

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const {
    participants,
    isLoading: areParticipantsLoading,
    error: participantsError,
  } = useSensoryTestParticipants(sensoryTest?.id);

  const isLoading = isSensoryTestLoading || areParticipantsLoading;

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
  };

  const openEditModal = () => {
    setIsEditModalOpen(true);
  };

  const onConfirmDelete = async () => {
    if (sensoryTest) {
      const result = await removeSensoryTest(sensoryTest);
      if (result) {
        toaster.success('Successfully deleted sensory test!');
      } else {
        toaster.error('Something went wrong, please try again.');
      }
      setIsDeleteDialogOpen(false);
    }
  };

  const logoIconName = useMemo<SvgIconName | undefined>(() => {
    if (sensoryTest && sensoryTest.type === 'triangle') {
      return 'sensoryTests/triangle';
    }
    if (sensoryTest && sensoryTest.type === 'qda') {
      return 'sensoryTests/qda';
    }
    if (sensoryTest && sensoryTest.type === 'twoFive') {
      return 'sensoryTests/twoFive';
    }
    return undefined;
  }, [sensoryTest]);

  const subTitle = useMemo<string | undefined>(() => {
    if (!sensoryTest) {
      return undefined;
    }

    return sensoryTestTypeNames[sensoryTest.type];
  }, [sensoryTest]);

  return (
    <PageLayout>
      <PageLayout.Main>
        <PageHeader
          title={sensoryTest?.name}
          logo={logoIconName}
          subTitle={subTitle}
          isLoading={isLoading}
        >
          <Button
            className={styles.buttonWithSpacingRight}
            prefixIcon={<Edit2 />}
            onClick={openEditModal}
          >
            Edit
          </Button>
          <Button onClick={() => setIsDeleteDialogOpen(true)} prefixIcon={<Trash2 />}>
            Delete
          </Button>

          <ConfirmDialog
            title="Delete sensory test"
            message={
              <>
                Are you sure you want to delete this test? All <b>results</b> from panelists will
                also be deleted.
              </>
            }
            isOpen={isDeleteDialogOpen}
            confirmButtonLabel="Delete"
            cancelButtonOnClick={closeDeleteDialog}
            confirmButtonOnClick={onConfirmDelete}
            isLoading={isDeletePending}
          />
          {sensoryTest && (
            <SensoryTestEditDialog
              isOpen={isEditModalOpen}
              onClose={() => setIsEditModalOpen(false)}
              sensoryTest={sensoryTest}
            />
          )}
        </PageHeader>
        {error && error.code === 'not-found' && <Redirect to="/sensoryTests" />}
        {isLoading && <SensoryTestDetailContentLoader />}
        {!isLoading && sensoryTest && isTriangleTest(sensoryTest) && (
          <TriangleTestDetail
            triangleTest={sensoryTest}
            participants={participants as TriangleTestParticipant[]}
            participantsError={participantsError}
          />
        )}
        {!isLoading && sensoryTest && isQdaTest(sensoryTest) && (
          <QdaTestDetail
            qdaTest={sensoryTest}
            participants={participants as QdaTestParticipant[]}
          />
        )}
        {!isLoading && sensoryTest && isTwoFiveTest(sensoryTest) && (
          <TwoFiveTestDetail
            twoFiveTest={sensoryTest}
            participants={participants as TwoFiveTestParticipant[]}
            participantsError={participantsError}
          />
        )}
      </PageLayout.Main>
    </PageLayout>
  );
};

export default SensoryTestDetail;
