import 'firebase/analytics';
import React, { useEffect } from 'react';
import { Switch, useHistory } from 'react-router-dom';

import { useFirebase } from 'components/providers/FirebaseProvider';

import styles from './AppSwitch.module.css';

interface Props {
  children?: React.ReactNode;
}

const AppSwitch: React.FC<Props> = ({ children }) => {
  const history = useHistory();
  const firebase = useFirebase();

  useEffect(() => {
    const removeHistoryListener = history.listen(() => {
      const analytics = firebase.analytics();
      analytics.setCurrentScreen(window.location.pathname);
      analytics.logEvent('screen_view', null as any);
    });

    return removeHistoryListener;
  }, [firebase, history]);

  return (
    <div className={styles.screen}>
      <Switch>{children}</Switch>
    </div>
  );
};

export default AppSwitch;
