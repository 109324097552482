import React, { useState } from 'react';
import { Button, CheckCircle, Maximize2, Modal } from 'ventura';

import { SampleIcon } from 'components/atoms';
import { getCssVariable } from 'utils/style';

import styles from './CategoryScaleResult.module.css';

export interface BarChart {
  sampleId: number;
  sampleName: string;
  bars: {
    name: string;
    count: number;
  }[];
}

type Props = {
  name: string;
  barCharts: BarChart[];
  className?: string;
};

const CategoryScaleResult: React.FC<Props> = ({ name, barCharts, className }: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const barValues = barCharts.flatMap((group) => group.bars.map((bar) => bar.count));
  const areThereResults = Math.max(...barValues) > 0;

  return (
    <>
      <div className={className}>
        <div className={styles.header}>
          <div className={styles.title}>
            <CheckCircle />
            <span className={styles.formElementName}>{name}</span>
            <span className={styles.formElementType}>Category scale</span>
          </div>
          <Button onClick={() => setIsModalOpen(true)}>
            <Maximize2 />
          </Button>
        </div>
        {areThereResults ? (
          <>
            <span className={styles.chartTitle}>Most popular option per sample</span>
            <ul className={styles.popularBars}>
              {barCharts.map((barChart, index) => {
                const mostPopularOption = [...barChart.bars].sort(
                  (barA, barB) => barB.count - barA.count,
                )[0];
                const totalCount = barChart.bars.reduce(
                  (accumulator, bar) => accumulator + bar.count,
                  0,
                );
                const barPercentage = (mostPopularOption.count / totalCount) * 100 || 0;

                return (
                  <li className={styles.barChart} key={barChart.sampleId}>
                    <div
                      className={styles.bar}
                      style={{
                        backgroundColor: getCssVariable(`--qda-sample-${index + 1}-1`),
                      }}
                    >
                      <div
                        className={styles.barSize}
                        style={{
                          width: `${barPercentage}%`,
                          backgroundColor: getCssVariable(`--qda-sample-${index + 1}-2`),
                        }}
                      />
                    </div>
                    <div className={styles.barInfo}>
                      <span
                        className={styles.percentageNumber}
                        style={{ color: getCssVariable(`--qda-sample-${index + 1}-2`) }}
                      >
                        {Math.round(barPercentage)}%
                      </span>
                      {mostPopularOption.name}
                    </div>
                  </li>
                );
              })}
            </ul>
          </>
        ) : (
          <span className={styles.placeholder}>No results yet</span>
        )}
      </div>
      <Modal isOpen={isModalOpen} title="Parameter results" className={styles.modal}>
        <div className={styles.title}>
          <CheckCircle />
          <span className={styles.formElementName}>{name}</span>
          <span className={styles.formElementType}>Category scale</span>
        </div>
        {barCharts.map((barChart, index) => {
          const totalCount = barChart.bars.reduce((accumulator, bar) => accumulator + bar.count, 0);

          return (
            <div key={barChart.sampleId}>
              <div className={styles.sampleTitle}>
                <span className={styles.sample}>Sample: </span>
                <SampleIcon id={barChart.sampleId} index={index} sensoryTestType="qda" />
                <span className={styles.sampleName}>{barChart.sampleName}</span>
              </div>
              {areThereResults ? (
                <div className={styles.barChart}>
                  <ul className={styles.axisTicks}>
                    {barChart.bars.map((bar) => (
                      <li key={bar.name}>{bar.name}</li>
                    ))}
                  </ul>
                  <div
                    className={styles.axis}
                    style={{ backgroundColor: getCssVariable(`--qda-sample-${index + 1}-2`) }}
                  />
                  <ul className={styles.bars}>
                    {barChart.bars.map((bar) => {
                      const barPercentage = totalCount !== 0 ? (bar.count / totalCount) * 100 : 0;

                      return (
                        <li key={bar.name}>
                          <div
                            className={styles.bar}
                            style={{
                              width: `${barPercentage}%`,
                              backgroundColor: getCssVariable(`--qda-sample-${index + 1}-1`),
                            }}
                          />
                          <span className={styles.barPercentage}>
                            {Math.round(barPercentage)}% ({bar.count})
                          </span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              ) : (
                <p className={styles.noResultsText}>No answers for this sample</p>
              )}
            </div>
          );
        })}
        <Modal.Footer>
          <Button onClick={() => setIsModalOpen(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CategoryScaleResult;
