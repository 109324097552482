import React from 'react';
import ContentLoader from 'react-content-loader';

const SensoryTestsContentLoader: React.FC = () => (
  <ContentLoader viewBox="0 0 425 200" width="425" height="230" uniqueKey="SensoryTests">
    <rect x="10" y="0" rx="4" ry="4" width="24" height="22" />
    <rect x="45" y="0" rx="4" ry="4" width="320" height="22" />
    <rect x="45" y="40" rx="4" ry="4" width="425" height="16" />
    <rect x="45" y="65" rx="4" ry="4" width="425" height="16" />
    <rect x="10" y="120" rx="4" ry="4" width="24" height="22" />
    <rect x="45" y="120" rx="4" ry="4" width="320" height="22" />
    <rect x="45" y="160" rx="4" ry="4" width="425" height="16" />
    <rect x="45" y="185" rx="4" ry="4" width="425" height="16" />
  </ContentLoader>
);

export default SensoryTestsContentLoader;
