import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { FormElement } from '@bloodhound/common/dist/models/formElement';
import { QdaTest } from '@bloodhound/common/dist/models/sensoryTest';
import {
  QdaTestParticipant,
  SensoryTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';
import clsx from 'clsx';
import { saveAs } from 'file-saver';
import {
  Button,
  ChevronDown,
  ChevronUp,
  Download,
  Grid,
  Hash,
  Menu,
  PlusCircle,
  Popover,
  Printer,
  Tab,
  Table,
  Tabs,
  toaster,
  Trash2,
} from 'ventura';

import flaskWithPanelists from 'assets/flaskWithPanelists.svg';
import qdaGraph from 'assets/qdaGraph.svg';
import { Placeholder, SampleIcon } from 'components/atoms';
import { ConfirmDialog, TabHeader } from 'components/molecules';
import { FormComposer, PrintSampleCodesDialog, ResultsByPage } from 'components/organisms';
import QdaResultsOverview from 'components/organisms/QdaResultsOverview/QdaResultsOverview';
import {
  useSensoryTestParticipantCreator,
  useSensoryTestParticipantRemover,
} from 'services/sensoryTestParticipantService';
import { useSensoryTestEditor } from 'services/sensoryTestService';
import { generateQdaResultsExcel } from 'utils/excel';
import { getBaseUrl } from 'utils/functions';
import { generateQdaTestParticipant, openQrCodePdf } from 'utils/sensoryTestParticipant';
import { TabIndex } from '../types';

import styles from './QdaTestDetail.module.css';

interface Props {
  qdaTest: QdaTest;
  participants: QdaTestParticipant[];
}

const QdaTestDetail: React.FC<Props> = ({ qdaTest, participants }) => {
  const [isPrintMenuOpen, setIsPrintMenuOpen] = useState(false);
  const [isPrintSampleCodesDialogOpen, setIsPrintSampleCodesDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [toBeDeletedParticipant, setToBeDeletedParticipant] = useState<
    undefined | SensoryTestParticipant
  >(undefined);
  const [tabIndex, setTabIndex] = useState<TabIndex>(TabIndex.Form);
  const {
    createSensoryTestParticipant,
    error: createSensoryTestParticipantError,
  } = useSensoryTestParticipantCreator(qdaTest.id);
  const { removeSensoryTestParticipant, isDeletePending } = useSensoryTestParticipantRemover(
    qdaTest.id,
  );
  const { editSensoryTest, error, clearError } = useSensoryTestEditor();

  const formLink = `${getBaseUrl()}/test/${qdaTest.id}`;
  const participantsWithoutPartialResults = useMemo(
    () =>
      participants.map((participant) => {
        const fullSampleResults = participant.sampleResults.filter(
          (sampleResult) => sampleResult.isCompleted,
        );
        return { ...participant, sampleResults: fullSampleResults };
      }),
    [participants],
  );

  const saveFormElements = async (nextFormElements: FormElement[]) => {
    clearError();

    const updatedQdaTest: QdaTest = {
      ...qdaTest,
      formElements: nextFormElements,
    };
    await editSensoryTest(updatedQdaTest);
  };

  const hasSampleResults = useMemo(() => {
    return participantsWithoutPartialResults.some(
      (participant) => participant.sampleResults.length > 0,
    );
  }, [participantsWithoutPartialResults]);

  useEffect(() => {
    if (createSensoryTestParticipantError) {
      toaster.error('Failed to add a panelist.');
    }
  }, [createSensoryTestParticipantError]);

  const openDeleteDialog = (participant: SensoryTestParticipant) => {
    setToBeDeletedParticipant(participant);
    setIsDeleteDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDeleteDialogOpen(false);
    setToBeDeletedParticipant(undefined);
  };

  const onConfirmDelete = async () => {
    if (toBeDeletedParticipant) {
      const result = await removeSensoryTestParticipant(toBeDeletedParticipant);
      if (result) {
        toaster.success('Successfully removed panelist!', {
          isClosable: false,
          durationInSeconds: 3,
        });
      } else {
        toaster.error('Something went wrong, please try again.', {
          isClosable: false,
          durationInSeconds: 3,
        });
      }
      closeDeleteDialog();
    }
  };

  const createQdaTestParticipant = async (): Promise<string> => {
    const participant = generateQdaTestParticipant(qdaTest, participants);

    return createSensoryTestParticipant(participant);
  };

  const onExportToExcel = async () => {
    const blob = await generateQdaResultsExcel(
      qdaTest,
      participantsWithoutPartialResults as QdaTestParticipant[],
    );
    saveAs(blob, `${qdaTest.name}.xlsx`);
  };

  return (
    <>
      <div className={styles.samplesContainer}>
        {qdaTest.samples.map((sample, index) => (
          <span key={sample.id} className={styles.sample}>
            <SampleIcon sensoryTestType="qda" id={sample.id} index={index} />
            <span className={styles.sampleText}>{sample.name}</span>
          </span>
        ))}
      </div>
      <Tabs onChange={setTabIndex} currentTabIndex={tabIndex}>
        <Tab title="Form" index={TabIndex.Form}>
          <FormComposer
            isEditLimited={hasSampleResults}
            onEdit={saveFormElements}
            formElements={qdaTest.formElements}
            errors={error ? [error.message] : undefined}
            sensoryTestId={qdaTest.id}
          />
        </Tab>
        <Tab title="Panelists" index={TabIndex.Panelists}>
          <TabHeader
            title="Participating panelists"
            description="Only panelists you invite will be able to participate in the sensory test."
          >
            <Popover
              isVisible={isPrintMenuOpen}
              onClose={() => {
                setIsPrintMenuOpen(false);
              }}
              content={
                <Menu>
                  <Menu.Item
                    onClick={() => setIsPrintSampleCodesDialogOpen(true)}
                    prefixIcon={<Hash />}
                  >
                    Sample codes
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => openQrCodePdf(participants, formLink)}
                    prefixIcon={<Grid />}
                  >
                    QR codes
                  </Menu.Item>
                </Menu>
              }
              placement="bottom-end"
            >
              <Button
                onClick={() => {
                  setIsPrintMenuOpen(!isPrintMenuOpen);
                }}
                prefixIcon={<Printer />}
                suffixIcon={isPrintMenuOpen ? <ChevronUp /> : <ChevronDown />}
              >
                Print
              </Button>
            </Popover>
            <Button onClick={createQdaTestParticipant} prefixIcon={<PlusCircle />}>
              Add panelist
            </Button>
          </TabHeader>
          <Table>
            <Table.Header>
              <Table.Cell>Name</Table.Cell>
              <Table.Cell>Access code</Table.Cell>
              <Table.Cell>Completed samples</Table.Cell>
              <Table.Cell />
            </Table.Header>
            <Table.Body>
              {participants.map((participant, index) => {
                return (
                  <Table.Row key={participant.id}>
                    <Table.Cell>{participant.name}</Table.Cell>
                    <Table.Cell>
                      <span className={styles.accessCode}>{participant.id}</span>
                    </Table.Cell>
                    <Table.Cell>
                      {participant.sampleResults.map((result) => {
                        const sampleIndex = qdaTest.samples.findIndex(
                          (sample) => sample.id === result.sampleId,
                        );

                        return (
                          <Fragment key={result.sampleId}>
                            <SampleIcon
                              sensoryTestType="qda"
                              id={result.sampleId}
                              index={sampleIndex}
                              className={styles.tableSampleIcon}
                            />
                            {!result.isCompleted && (
                              <span
                                className={styles.progressRatio}
                                style={{
                                  color: `var(--qda-sample-${sampleIndex + 1}-2)`,
                                  borderColor: `var(--qda-sample-${sampleIndex + 1}-1)`,
                                }}
                              >
                                {Math.floor(result.progressRatio * 100)}%
                              </span>
                            )}
                          </Fragment>
                        );
                      })}
                    </Table.Cell>
                    <Table.Cell align="right">
                      <Button
                        size="small"
                        type={(index + 1) % 2 === 0 ? 'secondary' : 'primary'}
                        onClick={() => {
                          openDeleteDialog(participant);
                        }}
                        className={styles.removeButton}
                      >
                        <Trash2 />
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                );
              })}
            </Table.Body>
          </Table>
          {participants.length === 0 && (
            <div className={styles.placeholderContainer}>
              <Placeholder
                image={flaskWithPanelists}
                subTitle="This sensory test has no panelists yet."
                imgSize="small"
              />
            </div>
          )}
          <ConfirmDialog
            title="Delete panelist"
            message={
              <>
                Are you sure you want to remove this panelist from the test? The linked{' '}
                <b>result</b> will also be deleted.
              </>
            }
            isOpen={isDeleteDialogOpen}
            confirmButtonLabel="Delete"
            cancelButtonOnClick={closeDeleteDialog}
            confirmButtonOnClick={onConfirmDelete}
            isLoading={isDeletePending}
          />
          <PrintSampleCodesDialog
            isOpen={isPrintSampleCodesDialogOpen}
            onClose={() => setIsPrintSampleCodesDialogOpen(false)}
            sampleCodeSets={Array(participants.length).fill(
              qdaTest.samples.map((sample) => sample.id),
            )}
          />
        </Tab>
        <Tab title="Results" index={TabIndex.Results}>
          <TabHeader
            title="Watch live results come in."
            description="All charts automatically update when a panelist submits the results. Incomplete results are excluded."
          >
            <Button prefixIcon={<Download />} onClick={onExportToExcel}>
              Export XLS
            </Button>
          </TabHeader>

          <h3 className={styles.subTitle}>Test overview</h3>
          <QdaResultsOverview
            formElements={qdaTest.formElements}
            samples={qdaTest.samples}
            participantCount={participants.length}
            sampleResults={participants.flatMap((participant) => participant.sampleResults)}
          />

          <h3 className={styles.subTitle}>Parameter results</h3>
          {qdaTest.formElements.length === 0 ? (
            <div className={clsx(styles.placeholderContainer, styles.noParamsPlaceholderContainer)}>
              <Placeholder
                image={qdaGraph}
                subTitle="Add parameters to see results for them."
                className={styles.noFormPlacholder}
              />
              <Button onClick={() => setTabIndex(TabIndex.Form)}>Go to form</Button>
            </div>
          ) : (
            <ResultsByPage
              formElements={qdaTest.formElements}
              participants={participants}
              samples={qdaTest.samples}
              significanceLevel={qdaTest.significanceLevel}
              className={styles.results}
            />
          )}
        </Tab>
      </Tabs>
    </>
  );
};

export default QdaTestDetail;
