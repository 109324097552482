import 'firebase/analytics';
import React from 'react';
import { BrowserRouter, Redirect, Route } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { AppSwitch, OldBrowserAlert, PrivateRoute } from 'components/atoms';
import RestrictedRoute from 'components/atoms/RestrictedRoute/RestrictedRoute';
import FirebaseProvider from 'components/providers/FirebaseProvider';
import {
  Login,
  Logout,
  NotFound,
  Panelists,
  Register,
  SensoryTestDetail,
  SensoryTestForm,
  SensoryTestFormLogin,
  SensoryTestFormWelcome,
  SensoryTests,
} from '.';

const App: React.FC = () => {
  return (
    <Sentry.ErrorBoundary fallback={<>A react error has occurred</>}>
      <FirebaseProvider>
        <BrowserRouter>
          <OldBrowserAlert />
          <AppSwitch>
            <RestrictedRoute exact path="/login" component={Login} />
            <RestrictedRoute exact path="/register" component={Register} />
            <PrivateRoute exact path="/logout" component={Logout} />
            <PrivateRoute exact path="/sensoryTests" component={SensoryTests} />
            <PrivateRoute exact path="/sensoryTests/:id" component={SensoryTestDetail} />
            <PrivateRoute exact path="/panelists" component={Panelists} />
            <Route exact path="/test/:id" component={SensoryTestFormWelcome} />
            <Route exact path="/test/:id/login" component={SensoryTestFormLogin} />
            <Route exact path="/test/:id/:code" component={SensoryTestForm} />
            <Redirect exact from="/" to="/sensoryTests" />
            <PrivateRoute default component={NotFound} />
          </AppSwitch>
        </BrowserRouter>
      </FirebaseProvider>
    </Sentry.ErrorBoundary>
  );
};

export default App;
