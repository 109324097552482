import React from 'react';
import { Redirect } from 'react-router-dom';

import { useAuthentication } from 'services/userService';

const Logout: React.FC = () => {
  const { signOut } = useAuthentication();

  signOut();

  return <Redirect to="/login" />;
};

export default Logout;
