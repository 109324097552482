import React from 'react';
import ContentLoader from 'react-content-loader';

const SensoryTestTagsContentLoader: React.FC = () => (
  <ContentLoader viewBox="0 0 425 200" width="152" height="230" uniqueKey="SensoryTestsTags">
    <rect x="0" y="0" rx="4" ry="4" width="400" height="40" />
  </ContentLoader>
);

export default SensoryTestTagsContentLoader;
