import { useEffect, useState } from 'react';
import { FirebaseError, FirestoreError } from '@bloodhound/common/dist/models/firebase';
import { SensoryTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import axios from 'axios';
import * as uuid from 'uuid';

import { useFirebase } from 'components/providers/FirebaseProvider';
import { removeFalsyProperties } from 'utils/object';
import { useAuthentication } from './userService';

export const useSensoryTestParticipants = <T extends SensoryTestParticipant>(
  sensoryTestId?: string,
): {
  participants: T[];
  error?: FirebaseError;
  isLoading: boolean;
} => {
  const [participants, setParticipants] = useState<T[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();
  const { user, isLoading: isAuthLoading } = useAuthentication();

  useEffect(() => {
    if (sensoryTestId) {
      setIsLoading(true);

      if (isAuthLoading) {
        return;
      }

      if (!user) {
        setIsLoading(false);
        setError(new FirebaseError('unauthenticated'));
        return;
      }

      if (typeof user === 'undefined') {
        return undefined;
      }

      const collection = firebase
        .firestore()
        .collection('sensoryTests')
        .doc(sensoryTestId)
        .collection('participants')
        .orderBy('dateCreated');

      const subscription = collection.onSnapshot(
        (snapshot) => {
          const fetchedParticipants = snapshot.docs.map(
            (doc) => ({ id: doc.id, ...doc.data() } as T),
          );

          setParticipants(fetchedParticipants);
          setError(undefined);
          setIsLoading(false);
        },
        (e: Error) => {
          const exception = e as FirestoreError;

          setParticipants([]);
          setError(new FirebaseError(exception.code));
          setIsLoading(false);
        },
      );

      return subscription;
    }

    return undefined;
  }, [firebase, sensoryTestId, user, isAuthLoading]);

  return { participants, error, isLoading };
};

export const useSensoryTestParticipant = <T extends SensoryTestParticipant>(
  sensoryTestId?: string,
  accessCode?: string,
  { realtime = true }: { realtime?: boolean } = {},
) => {
  const [participant, setParticipant] = useState<T>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();

  useEffect(() => {
    if (sensoryTestId && accessCode) {
      setIsLoading(true);
      setError(undefined);

      const doc = firebase
        .firestore()
        .collection('sensoryTests')
        .doc(sensoryTestId)
        .collection('participants')
        .doc(accessCode);

      if (realtime) {
        const subscription = doc.onSnapshot(
          (snapshot) => {
            setError(undefined);
            const snapshotData = snapshot.data();

            if (!snapshotData) {
              setError(new FirebaseError('not-found'));
            }

            const fetchedSensoryTest = snapshotData
              ? ({ ...snapshotData, id: snapshot.id } as T)
              : undefined;
            setParticipant(fetchedSensoryTest);
            setIsLoading(false);
          },
          () => {
            setParticipant(undefined);
            setError(
              new FirebaseError('not-found', 'These samples are invalid or already submitted.'),
            );
            setIsLoading(false);
          },
        );

        return subscription;
      }

      doc
        .get()
        .then((snapshot) => {
          setError(undefined);
          const snapshotData = snapshot.data();

          if (!snapshotData) {
            setError(new FirebaseError('not-found'));
          }

          const fetchedSensoryTest = snapshotData
            ? ({ ...snapshotData, id: snapshot.id } as T)
            : undefined;
          setParticipant(fetchedSensoryTest);
          setIsLoading(false);
        })
        .catch(() => {
          setParticipant(undefined);
          setError(
            new FirebaseError('not-found', 'These samples are invalid or already submitted.'),
          );
          setIsLoading(false);
        });

      return undefined;
    }

    setParticipant(undefined);
    setIsLoading(false);
    setError(undefined);

    return undefined;
  }, [firebase, sensoryTestId, accessCode, realtime]);

  return { participant, isLoading, error };
};

export function useSensoryTestParticipantAccessCode(sensoryTestId: string) {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const [accessCode, setAccessCode] = useState<string>();
  const firebase = useFirebase();

  const fetchWithAccessCode = async (userEnteredAccessCode: string): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_API}/sensory-tests/${sensoryTestId}/access-code`,
        { accessCode: userEnteredAccessCode },
      );

      const participantId = response.data.participantId as string;

      setAccessCode(participantId);
      firebase.analytics().logEvent('participant_form_start', {
        participant: {
          id: participantId,
        },
        sensoryTest: {
          id: sensoryTestId,
        },
      });
    } catch {
      setError(
        new FirebaseError(
          'not-found',
          'This combination does not exist. Please review the numbers are in the correct order or ask your host for help.',
        ),
      );
    }

    setIsLoading(false);
  };

  const fetchWithSampleCodes = async (sampleCodes: string[]): Promise<void> => {
    setIsLoading(true);
    setError(undefined);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_FIREBASE_FUNCTIONS_API}/sensory-tests/${sensoryTestId}/access-code`,
        { sampleCodes },
      );

      const participantId = response.data.participantId as string;

      setAccessCode(participantId);
      firebase.analytics().logEvent('participant_form_start', {
        participant: {
          id: participantId,
        },
        sensoryTest: {
          id: sensoryTestId,
        },
      });
    } catch {
      setError(
        new FirebaseError(
          'not-found',
          'This combination does not exist. Please review the numbers are in the correct order or ask your host for help.',
        ),
      );
    }

    setIsLoading(false);
  };

  return {
    fetchWithAccessCode,
    error,
    isLoading,
    fetchWithSampleCodes,
    accessCode,
  };
}

export const useSensoryTestParticipantCreator = (
  sensoryTestId: string,
): {
  createSensoryTestParticipant: (
    partialParticipant: Omit<SensoryTestParticipant, 'id' | 'dateCreated'>,
  ) => Promise<string>;
  error?: FirebaseError;
  clearError: () => void;
  isCreatePending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isCreatePending, setIsCreatePending] = useState<boolean>(false);
  const firebase = useFirebase();

  const createSensoryTestParticipant = async (
    participant: Omit<SensoryTestParticipant, 'id' | 'dateCreated'>,
  ): Promise<string> => {
    const id = uuid.v4().substring(0, 6).toUpperCase();

    const document = firebase
      .firestore()
      .collection('sensoryTests')
      .doc(sensoryTestId)
      .collection('participants')
      .doc(id);

    try {
      await document.set({
        dateCreated: new Date(),
        ...participant,
      });
      setError(undefined);
      setIsCreatePending(false);
      return id;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      setIsCreatePending(false);
      return '';
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return { createSensoryTestParticipant, error, isCreatePending, clearError };
};

export const useSensoryTestParticipantEditor = (
  sensoryTestId: string,
): {
  editParticipant: (participant: SensoryTestParticipant) => Promise<boolean>;
  error?: FirebaseError;
  isEditPending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isEditPending, setIsEditPending] = useState<boolean>(false);
  const firebase = useFirebase();

  const editParticipant = async (participant: SensoryTestParticipant): Promise<boolean> => {
    setError(undefined);
    setIsEditPending(true);

    const document = firebase
      .firestore()
      .collection('sensoryTests')
      .doc(sensoryTestId)
      .collection('participants')
      .doc(participant.id);

    try {
      const firestoreParticipant = removeFalsyProperties<SensoryTestParticipant>(participant, [
        'id',
      ]);
      await document.update(firestoreParticipant);
      return true;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return false;
    } finally {
      setIsEditPending(false);
    }
  };

  return { editParticipant, error, isEditPending };
};

export const useSensoryTestParticipantRemover = (
  sensoryTestId: string,
): {
  removeSensoryTestParticipant: (participant: SensoryTestParticipant) => Promise<boolean>;
  error?: FirebaseError;
  isDeletePending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isDeletePending, setIsDeletePending] = useState<boolean>(false);
  const firebase = useFirebase();

  const removeSensoryTestParticipant = async (
    participant: SensoryTestParticipant,
  ): Promise<boolean> => {
    setError(undefined);
    setIsDeletePending(true);

    const collection = firebase
      .firestore()
      .collection('sensoryTests')
      .doc(sensoryTestId)
      .collection('participants');

    try {
      await collection.doc(participant.id).delete();
      return true;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return false;
    } finally {
      setIsDeletePending(false);
    }
  };

  return { removeSensoryTestParticipant, error, isDeletePending };
};
