import 'firebase/auth';
import { useEffect, useState } from 'react';
import { AuthError, AuthErrorCode, FirebaseError } from '@bloodhound/common/dist/models/firebase';
import { User } from '@bloodhound/common/dist/models/user';

import {
  FirebaseAnalyticsUserProperties,
  useFirebase,
} from 'components/providers/FirebaseProvider';

export const useAuthentication = (): {
  signIn: (email: string, password: string) => Promise<void>;
  signUp: (email: string, password: string, name: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signOut: () => Promise<void>;
  error?: FirebaseError;
  user?: User;
  isLoading: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const firebase = useFirebase();

  const signIn = async (email: string, password: string): Promise<void> => {
    try {
      await firebase.auth().signInWithEmailAndPassword(email, password);

      firebase.analytics().logEvent('login', {
        method: 'EmailAndPassword',
        userEmail: email,
      });
    } catch (exception) {
      setError(new FirebaseError((exception as AuthError).code as AuthErrorCode));
    }
  };

  const signUp = async (email: string, password: string, name: string): Promise<void> => {
    try {
      await firebase.auth().createUserWithEmailAndPassword(email, password);
      firebase.auth().currentUser!.updateProfile({
        displayName: name,
      });

      firebase.analytics().logEvent('register', {
        method: 'EmailAndPassword',
        userEmail: email,
      });
    } catch (exception) {
      setError(new FirebaseError((exception as AuthError).code as AuthErrorCode));
    }
  };

  const signInWithGoogle = async (): Promise<void> => {
    try {
      var provider = new firebase.auth.GoogleAuthProvider();
      provider.setCustomParameters({ prompt: 'select_account' });
      const userCredential = await firebase.auth().signInWithPopup(provider);

      if (userCredential.additionalUserInfo?.isNewUser) {
        firebase.analytics().logEvent('register', {
          method: 'Google',
          userEmail: userCredential.user?.email,
        });
      } else {
        firebase.analytics().logEvent('login', {
          method: 'Google',
          userEmail: userCredential.user?.email,
        });
      }
    } catch (exception) {
      const errorCode = (exception as AuthError).code as AuthErrorCode;
      if (['auth/popup-closed-by-user', 'auth/cancelled-popup-request'].includes(errorCode)) {
        return;
      }
      setError(new FirebaseError(errorCode));
    }
  };

  const signOut = async () => {
    firebase.auth().signOut();
  };

  useEffect(() => {
    if (!firebase) return;

    return firebase.auth().onAuthStateChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        return setIsLoading(false);
      }

      setIsLoading(true);

      firebase.analytics().setUserId(firebaseUser.uid);
      firebase.analytics().setUserProperties({ email: firebaseUser.email });

      // After registration, a workspace is created by a GCP function. This can
      // take a couple of seconds, so we wait for the workspaceId claim to be set
      let retries = 10;
      while (retries > 0) {
        const token = await firebaseUser.getIdTokenResult();

        if (token.claims.workspaceId) {
          firebase.analytics().setUserProperties({
            workspaceId: token.claims.workspaceId,
          } as FirebaseAnalyticsUserProperties);

          const doc = await firebase.firestore().collection('users').doc(firebaseUser.uid).get();
          const userInfo: User = { id: doc.id, ...doc.data() } as User;
          setUser(userInfo);
          return setIsLoading(false);
        }

        await new Promise((resolve) => setTimeout(resolve, 1000));
        await firebaseUser.getIdToken(true);
        retries--;
      }

      setError(
        new FirebaseError(
          'unknown',
          'Something went wrong during registration. Please sign in again',
        ),
      );
      setIsLoading(false);
    });
  }, [firebase]);

  return {
    signIn,
    signUp,
    signInWithGoogle,
    signOut,
    error,
    user,
    isLoading: isLoading && !Boolean(user),
  };
};
