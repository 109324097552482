import React, { useState } from 'react';
import { FormElement, FormElementResult } from '@bloodhound/common/dist/models/formElement';
import {
  QdaSample,
  SensoryTestType,
  sensoryTestTypeNames,
} from '@bloodhound/common/dist/models/sensoryTest';
import {
  QdaSampleResult,
  QdaTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';
import { Alert, ArrowLeft, Button, CheckCircle } from 'ventura';

import SvgIcon, { SvgIconName } from 'components/atoms/SvgIcon/SvgIcon';
import { SampleSelector } from 'components/molecules';
import { SampleResultStatusMap } from 'components/molecules/SampleSelector/SampleSelector';
import { useFirebase } from 'components/providers/FirebaseProvider';
import { formatToTime } from 'utils/dateTime';
import { isPageFormElement } from 'utils/typeGuards';
import FormPage from '../FormPage/FormPage';
import { getFormElementsAndResultsByPage } from './helper';

import styles from './QdaTestParticipantForm.module.css';

interface Props {
  participant: QdaTestParticipant;
  instructions: string;
  formElements: FormElement[];
  onSubmit: (participant: QdaTestParticipant) => Promise<boolean>;
  error?: string;
  samples: QdaSample[];
}

const QdaTestParticipantForm: React.FC<Props> = ({
  participant,
  instructions,
  onSubmit,
  formElements,
  samples,
  error,
}: Props) => {
  const [selectedSample, setSelectedSample] = useState<QdaSample>();
  const [sampleResults, setSampleResults] = useState<QdaSampleResult[]>(participant.sampleResults);
  const [isCompletedAlertVisible, setIsCompletedAlertVisible] = useState(true);
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [lastSavedDate, setLastSavedDate] = useState<Date>();
  const firebase = useFirebase();

  const selectedSampleResult = sampleResults.find(
    (sampleResult) => sampleResult.sampleId === selectedSample?.id,
  );
  const formElementsByPage = getFormElementsAndResultsByPage(
    formElements,
    selectedSampleResult?.formElementResults,
  );
  const areTherePageFormElements = formElementsByPage.length > 0;
  const sampleResultStatusMap = sampleResults.reduce<SampleResultStatusMap>(
    (accumulator, sampleResult) => ({
      ...accumulator,
      [sampleResult.sampleId]: {
        isCompleted: sampleResult.isCompleted,
        progressRatio: sampleResult.progressRatio,
      },
    }),
    {},
  );
  const areAllSamplesCompleted =
    sampleResults.length === 0
      ? false
      : sampleResults.every((sampleResult) => sampleResult.isCompleted) &&
        sampleResults.length === samples.length;

  const handleSave = async (
    currentPageFormElementResults: FormElementResult[],
    isAutoSave: boolean,
  ) => {
    const existingFormElementResults = selectedSampleResult?.formElementResults ?? [];
    const currentPageElementResultIds = currentPageFormElementResults.map(
      (formElementResult) => formElementResult.formElementId,
    );
    const existingFormElementResultsNotOnCurrentPage = existingFormElementResults?.filter(
      (existingFormElementResult) =>
        !currentPageElementResultIds.includes(existingFormElementResult.formElementId),
    );
    const flooredProgressRatio =
      Math.floor(
        ((existingFormElementResultsNotOnCurrentPage.length +
          currentPageFormElementResults.length) /
          formElements.filter((formElement) => !isPageFormElement(formElement)).length) *
          99,
      ) / 100;
    const isLastPage =
      !areTherePageFormElements || currentPageIndex + 1 === formElementsByPage.length;
    const isCompleted = !isAutoSave && isLastPage;

    const updatedSampleResult: QdaSampleResult = {
      sampleId: selectedSample!.id,
      formElementResults: [
        ...existingFormElementResultsNotOnCurrentPage,
        ...currentPageFormElementResults,
      ],
      isCompleted,
      progressRatio: isCompleted ? 1 : flooredProgressRatio,
    };
    const updatedParticipant: QdaTestParticipant = {
      ...participant,
      sampleResults: [
        ...sampleResults.filter(
          (sampleResult) => sampleResult.sampleId !== selectedSampleResult?.sampleId,
        ),
        updatedSampleResult,
      ],
    };

    const isSubmitSuccessful = await onSubmit(updatedParticipant);
    if (isSubmitSuccessful) {
      setSampleResults(updatedParticipant.sampleResults);
      setLastSavedDate(new Date());
      if (!isAutoSave) {
        firebase.analytics().logEvent('participant_form_submit', {
          participantId: participant.id,
          sensoryTestType: 'qda' as SensoryTestType,
        });
      }
    }

    if (updatedSampleResult.isCompleted) {
      setSelectedSample(undefined);
      setCurrentPageIndex(0);
    }
  };

  const hasPurpose =
    participant.sensoryTest.purpose !== undefined &&
    participant.sensoryTest.purpose !== null &&
    participant.sensoryTest.purpose !== '';

  return (
    <>
      {!selectedSample ? (
        <header className={styles.header}>
          <div className={styles.title}>
            <SvgIcon
              icon={`sensoryTests/${participant.sensoryTest.type}` as SvgIconName}
              className={styles.icon}
            />
            <h1>{sensoryTestTypeNames[participant.sensoryTest.type]}</h1>
          </div>
        </header>
      ) : (
        <header className={styles.header}>
          <Button type="secondary" onClick={() => setSelectedSample(undefined)}>
            <ArrowLeft className={styles.backArrow} />
          </Button>
          <h2 className={styles.sampleTitle}>Sample {selectedSample.id}</h2>
          {!selectedSampleResult?.isCompleted ? (
            <span
              className={styles.autoSaveNotice}
              style={{ opacity: selectedSample && lastSavedDate ? 1 : 0 }}
            >
              Last saved at
              <br />
              {Boolean(lastSavedDate) && formatToTime(lastSavedDate!)}
            </span>
          ) : (
            <span className={styles.sampleResultStatusCompleted}>
              <CheckCircle /> Completed
            </span>
          )}
        </header>
      )}
      {error && <Alert className={styles.error} intent="error" message={error} />}
      {!selectedSample ? (
        <>
          {Boolean(instructions) && <div className={styles.info}>{instructions}</div>}
          {areAllSamplesCompleted && isCompletedAlertVisible && (
            <Alert
              className={styles.completedAlert}
              intent="success"
              onClose={() => setIsCompletedAlertVisible(false)}
              message="All samples have been completed. Thank you for participating!"
            />
          )}
          <SampleSelector
            samples={samples}
            sampleResultStatusMap={sampleResultStatusMap}
            onChange={setSelectedSample}
            className={styles.sampleOptionsContainer}
          />
          {areAllSamplesCompleted && isCompletedAlertVisible && hasPurpose && (
            <>
              <div className={styles.purposeTitle}>The purpose of this sensory test:</div>
              <div className={styles.purpose}>{participant.sensoryTest.purpose}</div>
            </>
          )}
        </>
      ) : (
        <FormPage
          name={formElementsByPage?.[currentPageIndex]?.name}
          currentPageIndex={currentPageIndex}
          pageCount={formElementsByPage.length}
          description={formElementsByPage?.[currentPageIndex]?.description}
          elements={
            areTherePageFormElements
              ? formElementsByPage[currentPageIndex].formElements
              : formElements
          }
          results={
            areTherePageFormElements
              ? formElementsByPage[currentPageIndex].results
              : selectedSampleResult?.formElementResults
          }
          onSave={handleSave}
          onPrevious={() => setCurrentPageIndex(currentPageIndex - 1)}
          onNext={() => setCurrentPageIndex(currentPageIndex + 1)}
          isReadOnly={selectedSampleResult?.isCompleted}
        />
      )}
    </>
  );
};

export default QdaTestParticipantForm;
