import React, { useEffect, useRef, useState } from 'react';
import { Tag } from '@bloodhound/common/dist/models';
import { FormField, Input } from 'ventura';

import { TagColorBackground, TagColorValues } from 'utils/workspace';

import styles from './SensoryTestTagInput.module.css';

export interface Props {
  options: Tag[];
  onTagSelected?: (tag: Tag) => void;
}

const highlightMatchedCharacters = (name: string, inputValue: string) => {
  const regex = new RegExp(inputValue, 'gi');
  const parts = name.split(regex);
  const matches = name.match(regex);

  if (!matches) {
    return name;
  }

  return (
    <span>
      {parts.map((part, index) => (
        <React.Fragment key={index}>
          {part}
          {matches[index] && <span className={styles.highlight}>{matches[index]}</span>}
        </React.Fragment>
      ))}
    </span>
  );
};

const SensoryTestTagInput: React.FC<Props> = ({ options, onTagSelected }) => {
  const [suggestions, setSuggestions] = useState<Tag[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setInputValue(value);
    const filteredSuggestions = options.filter((suggestion) =>
      suggestion.name.toLowerCase().includes(value.toLowerCase()),
    );
    setSuggestions(filteredSuggestions);
  };

  const handleTagSelected = (tag: Tag) => {
    if (onTagSelected) {
      onTagSelected(tag);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
        setSuggestions([]);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.tagInput} ref={inputRef}>
      <FormField label="Tags">
        <Input name="tags" placeholder="Search tags..." onChange={handleInputChange} />
      </FormField>
      {suggestions.length > 0 && (
        <div className={styles.suggestions}>
          {suggestions.map((option) => {
            if (option.id !== 'untagged') {
              return (
                <div
                  key={option.id}
                  data-testid={option.name}
                  className={styles.suggestion}
                  style={{
                    backgroundColor: `${TagColorBackground[option.color]}`,
                    color: TagColorValues[option.color],
                  }}
                  onClick={() => handleTagSelected(option)}
                >
                  {highlightMatchedCharacters(option.name, inputValue)}
                </div>
              );
            }
            return null;
          })}
        </div>
      )}
    </div>
  );
};

export default SensoryTestTagInput;
