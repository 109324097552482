import { FDist, Tukey } from 'lib-r-math.js';

import { average, sum } from '../basic';
import { calculateStandardError } from './basic';

const getYOfCumulativeStudentizedRangeDistribution = Tukey().ptukey;
const getYOfCumulativeFDistribution = FDist().pf;

export const calculateAnovaProbability = (...groups: number[][]): number => {
  const meanOfMeans = average(groups.flat());
  const betweenGroupsSummary = {
    degreesOfFreedom: groups.length - 1,
    sumOfSquares: sum(
      groups.map((values) => {
        const groupMean = average(values);
        return values.length * (groupMean - meanOfMeans) ** 2;
      }),
    ),
  };
  const withinGroupsSummary = {
    degreesOfFreedom: sum(groups.map((values) => values.length - 1)),
    sumOfSquares: sum(
      groups
        .map((values) => {
          const groupMean = average(values);
          return values.map((value) => (value - groupMean) ** 2);
        })
        .flat(),
    ),
  };
  const fStatistic =
    betweenGroupsSummary.sumOfSquares /
    betweenGroupsSummary.degreesOfFreedom /
    (withinGroupsSummary.sumOfSquares / withinGroupsSummary.degreesOfFreedom);

  if (isNaN(fStatistic)) {
    return 1;
  }

  return (
    1 -
    getYOfCumulativeFDistribution(
      fStatistic,
      betweenGroupsSummary.degreesOfFreedom,
      withinGroupsSummary.degreesOfFreedom,
    )
  );
};

export interface TukeyHsdResult {
  names: [string, string];
  probability: number;
  standardErrorMean: number;
}

export const calculateTukeyHsdProbabilities = (groups: {
  [name: string]: number[];
}): TukeyHsdResult[] => {
  const groupNames = Object.keys(groups);
  const degreesOfFreedom = Object.values(groups).length * (Object.values(groups)[0].length - 1);
  const results: TukeyHsdResult[] = [];

  groupNames.forEach((groupNameA) => {
    groupNames.forEach((groupNameB) => {
      const resultForGroupNamePair = results.find(
        (result) => result.names.includes(groupNameA) && result.names.includes(groupNameB),
      );
      if (groupNameA === groupNameB || resultForGroupNamePair) {
        return;
      }

      const groupA = groups[groupNameA];
      const groupB = groups[groupNameB];
      const meanGroupA = average(groupA);
      const meanGroupB = average(groupB);
      const standardErrorGroupA = calculateStandardError(groups[groupNameA]);
      const standardErrorGroupB = calculateStandardError(groups[groupNameB]);
      const sumOfSquaresWithinGroups = sum(
        Object.values(groups)
          .map((values) => {
            const groupMean = average(values);
            return values.map((value) => (value - groupMean) ** 2);
          })
          .flat(),
      );
      const meanSquareWithinGroups = sumOfSquaresWithinGroups / degreesOfFreedom;

      let qStatistic: number;
      if (groupA.length === groupB.length) {
        qStatistic = (meanGroupA - meanGroupB) / Math.sqrt(meanSquareWithinGroups / groupA.length);
      } else {
        qStatistic =
          (meanGroupA - meanGroupB) /
          Math.sqrt(
            (meanSquareWithinGroups / groupA.length + meanSquareWithinGroups / groupB.length) / 2,
          );
      }

      results.push({
        names: [groupNameA, groupNameB],
        probability:
          1 -
          getYOfCumulativeStudentizedRangeDistribution(
            Math.abs(qStatistic),
            groupNames.length,
            degreesOfFreedom,
          ),
        standardErrorMean: average([standardErrorGroupA, standardErrorGroupB]),
      });
    });
  });

  return results;
};
