import 'firebase/analytics';
import React, { createContext, useContext } from 'react';
import { Firebase } from '@bloodhound/common/dist/models/firebase';
import firebase from 'firebase/app';

import { getEnvValue } from 'services/configService';

export interface FirebaseAnalyticsUserProperties {
  workspaceId: string;
  workspaceName: string;
}

const FirebaseContext = createContext<Firebase>({} as Firebase);
export const useFirebase = () => useContext(FirebaseContext);

interface Props {
  children: React.ReactNode;
}

const FirebaseProvider: React.FC<Props> = ({ children }: Props) => {
  if (!firebase.apps.length) {
    firebase.initializeApp({
      projectId: getEnvValue('REACT_APP_FIREBASE_PROJECT_ID'),
      appId: getEnvValue('REACT_APP_FIREBASE_APP_ID'),
      databaseURL: getEnvValue('REACT_APP_FIREBASE_DATABASE_URL'),
      storageBucket: getEnvValue('REACT_APP_FIREBASE_STORAGE_BUCKET'),
      locationId: getEnvValue('REACT_APP_FIREBASE_LOCATION_ID'),
      apiKey: getEnvValue('REACT_APP_FIREBASE_API_KEY'),
      authDomain: getEnvValue('REACT_APP_FIREBASE_AUTH_DOMAIN'),
      messagingSenderId: getEnvValue('REACT_APP_FIREBASE_MESSAGING_SENDER_IDx'),
      measurementId: getEnvValue('REACT_APP_FIREBASE_MEASUREMENT_ID'),
    });

    firebase.analytics();
    firebase.firestore().settings({ ignoreUndefinedProperties: true });
  }

  return (
    <FirebaseContext.Provider value={firebase as Firebase}>{children}</FirebaseContext.Provider>
  );
};

export default FirebaseProvider;
