import React, { useState } from 'react';
import { Radar as RadarChart } from 'react-chartjs-2';
import { FormElement, QdaSample, QdaSampleResult } from '@bloodhound/common/dist/models';
import clsx from 'clsx';
import { ArrowLeft, ArrowRight, Button } from 'ventura';

import { Card } from 'components/atoms';
import { SampleResultsOverviewCard } from 'components/molecules';
import { createChartOptions } from 'utils/chart';
import {
  createLineScaleRadarChartData,
  getOuterValueBoundaries,
  getSampleProgress,
} from './results';

import styles from './QdaResultsOverview.module.css';

type Props = {
  formElements: FormElement[];
  samples: QdaSample[];
  sampleResults: QdaSampleResult[];
  participantCount: number;
  className?: string;
};

const QdaResultsOverview: React.FC<Props> = ({
  formElements,
  samples,
  sampleResults,
  participantCount,
  className,
}: Props) => {
  const [xOffset, setXOffset] = useState(0);

  const lineScaleChartGridItemsCount = 4;
  const gridItemsCount = samples.length + lineScaleChartGridItemsCount;
  const visibleHorizontalGridCards = 3;
  const maxXOffset = Math.ceil(gridItemsCount / 2) - visibleHorizontalGridCards;

  return (
    <div className={clsx(className, styles.overview)}>
      <div className={clsx(className, styles.scrollArea)}>
        <div className={styles.content} style={{ transform: `translateX(-${xOffset * 263.5}px)` }}>
          {samples.map((sample, index) => {
            const sampleProgress = getSampleProgress(sample.id, sampleResults);
            return (
              <SampleResultsOverviewCard
                formsCompletedCount={sampleProgress.completed}
                formsInProgressCount={sampleProgress.inProgress}
                participantCount={participantCount}
                sampleIndex={index}
                sample={sample}
                key={sample.id}
              />
            );
          })}
          <Card className={styles.lineScaleChartCard}>
            <RadarChart
              data={createLineScaleRadarChartData(formElements, samples, sampleResults)}
              width={471}
              datasetKeyProvider={Math.random}
              options={createChartOptions({
                maintainAspectRatio: false,
                title: {
                  display: true,
                  text: 'Line scale parameters',
                },
                legend: {
                  display: false,
                },
                scale: {
                  ticks: {
                    maxTicksLimit: 10,
                    ...getOuterValueBoundaries(formElements),
                  },
                },
              })}
            />
          </Card>
        </div>
      </div>
      {xOffset > 0 && (
        <Button
          className={clsx(styles.button, styles.buttonLeft)}
          onClick={() => setXOffset(xOffset - 1)}
        >
          <ArrowLeft />
        </Button>
      )}
      {xOffset < maxXOffset && (
        <Button
          className={clsx(styles.button, styles.buttonRight)}
          onClick={() => setXOffset(xOffset + 1)}
        >
          <ArrowRight />
        </Button>
      )}
    </div>
  );
};

export default QdaResultsOverview;
