import { TwoFiveTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';
import ExcelJS from 'exceljs';

import { addTable, excelToBlob } from './helper';

const generateTwoFiveResultsExcel = async (
  participants: TwoFiveTestParticipant[],
): Promise<Blob> => {
  const workbook = new ExcelJS.Workbook();

  const worksheet = workbook.addWorksheet('Results');

  const resultsRows = participants.map<(string | number | undefined)[]>((participant) => {
    return [
      participant.name,
      participant.id,
      participant.firstSampleChar,
      participant.secondSampleChar,
      participant.thirdSampleChar,
      participant.fourthSampleChar,
      participant.fifthSampleChar,
      participant.selectedSampleIds &&
        (participant.selectedSampleIds.includes(participant.uniqueSampleIds[0]) &&
        participant.selectedSampleIds.includes(participant.uniqueSampleIds[1])
          ? 'yes'
          : 'no'),
      participant.motivation,
    ];
  });

  addTable(worksheet, {
    name: `twoFiveResults`,
    title: 'Results',
    ref: `A1`,
    columns: [
      { name: 'participant Name', filterButton: true },
      { name: 'participant Id', filterButton: true },
      { name: 'Sample 1', filterButton: true },
      { name: 'Sample 2', filterButton: true },
      { name: 'Sample 3', filterButton: true },
      { name: 'Sample 4', filterButton: true },
      { name: 'Sample 5', filterButton: true },
      { name: 'Correct', filterButton: true },
      { name: 'Motivation', filterButton: true },
    ],
    rows: resultsRows,
  });

  return excelToBlob(workbook);
};

export default generateTwoFiveResultsExcel;
