import React, { useState } from 'react';
import { Tag, Workspace } from '@bloodhound/common/dist/models';
//import * as uuid from 'uuid';
import { Button, Modal } from 'ventura';

//import * as Yup from 'yup';
import { FormErrors } from 'components/molecules';
import { SensoryTestTagsDisplay } from 'components/molecules';
import { useWorkspaceEditor } from 'services/workspaceService';

//import { TagColors } from 'utils/workspace';
import styles from './SensoryTestTagDeleteDialog.module.css';

interface Props {
  isOpen: boolean;
  onClose: (hasDeleted?: boolean) => void;
  workspace: any;
  selectedTags?: Tag[];
}

const SensoryTestTagDeleteDialog: React.FC<Props> = ({
  isOpen,
  onClose,
  workspace,
  selectedTags,
}: Props) => {
  const [isLoading, setIsLoading] = useState(false);
  const { editWorkspace, error } = useWorkspaceEditor();

  const handleDeleteTagCancel = () => {
    onClose(false);
  };

  const handleDeleteTag = async () => {
    setIsLoading(true);
    const tags = workspace.tags;
    const updatedTags = tags.filter(
      (tag: Tag) => !selectedTags?.some((selected) => selected.id === tag.id),
    );
    const updatedWorkspace: Workspace = {
      id: workspace.id,
      dateCreated: workspace.dateCreated,
      name: workspace.name,
      tags: updatedTags,
    };
    const updated = await editWorkspace(updatedWorkspace);
    if (updated) {
      onClose(true);
    } else {
      onClose(false);
    }
    setIsLoading(false);
  };

  const count = selectedTags?.length;

  const firebaseErrors = error ? [error.message] : [];

  return (
    <Modal
      title={count && count > 1 ? `Delete ${count} selected tags` : `Delete tag`}
      isOpen={isOpen}
      className={styles.modal}
    >
      <div className={styles.firebaseErrors}>
        <FormErrors messages={firebaseErrors} />
      </div>
      {count && count > 1 ? (
        <p>
          Are you sure you want to delete these tags? The selected tags will also be removed on the
          sensory tests.
        </p>
      ) : (
        <p>
          Are you sure you want to delete this tag? The selected tag will also be removed on the
          sensory tests.
        </p>
      )}

      <div className={styles.tagsContainer}>
        <SensoryTestTagsDisplay tags={selectedTags}></SensoryTestTagsDisplay>
      </div>
      <Modal.Footer>
        <Button type="secondary" onClick={() => handleDeleteTagCancel()}>
          Cancel
        </Button>
        <Button isLoading={isLoading} onClick={() => handleDeleteTag()}>
          {count && count > 1 ? `Delete ${count} tags` : `Delete tag`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SensoryTestTagDeleteDialog;
