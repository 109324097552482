import React from 'react';
import ContentLoader from 'react-content-loader';

export const SensoryTestFormInfoLoader: React.FC = () => (
  <ContentLoader viewBox="0 0 500 290" width="500" height="290" uniqueKey="SensoryTestFormInfo">
    <rect x="0" y="0" rx="4" ry="4" width="30" height="30" />
    <rect x="0" y="60" rx="4" ry="4" width="180" height="42" />
    <rect x="0" y="152" rx="4" ry="4" width="500" height="16" />
    <rect x="0" y="172" rx="4" ry="4" width="276" height="16" />
  </ContentLoader>
);
