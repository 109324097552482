import React from 'react';
import { Form, Formik } from 'formik';
import { Button, Checkbox, FormField, Input, Modal, TextArea } from 'ventura';
import * as Yup from 'yup';

import { PreviewBox } from 'components/atoms';

import styles from './CommentFormElementDialogContent.module.css';

export interface CommentFormElementPreloadedState {
  name: string;
  dataLabel?: string;
  isRequired: boolean;
}

const formValidationSchema = Yup.object().shape<CommentFormElementPreloadedState>({
  name: Yup.string().label('Parameter name').required(),
  dataLabel: Yup.string().label('Chart label'),
  isRequired: Yup.bool().required(),
});

const initialState: CommentFormElementPreloadedState = {
  name: '',
  isRequired: false,
};

interface Props {
  preloadedState?: CommentFormElementPreloadedState;
  onCancel: () => void;
  onSubmit: (sliderProps: CommentFormElementPreloadedState) => void;
}

const CommentFormElementDialogContent: React.FC<Props> = ({
  preloadedState,
  onCancel,
  onSubmit,
}: Props) => {
  return (
    <>
      <p className={styles.description}>
        Ask your panelists anything. They will reply in full sentences.
      </p>

      <Formik
        onSubmit={onSubmit}
        initialValues={preloadedState ?? initialState}
        validationSchema={formValidationSchema}
        validateOnChange={false}
        validateOnBlur={false}
      >
        {(formik) => (
          <Form className={styles.form}>
            <div className={styles.nameAndGraphLabelInputsContainer}>
              <FormField
                label="Name"
                errorMessage={formik.errors.name}
                className={styles.nameFormField}
              >
                <Input
                  name="name"
                  placeholder="Question"
                  isInvalid={Boolean(formik.errors.name)}
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
              <FormField
                label="Chart label"
                errorMessage={formik.errors.dataLabel}
                className={styles.graphLabelFormField}
              >
                <Input
                  name="dataLabel"
                  placeholder="Chart label (optional)"
                  isInvalid={Boolean(formik.errors.dataLabel)}
                  value={formik.values.dataLabel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
              </FormField>
            </div>
            <Checkbox
              name="isRequired"
              label="Panelists need to fill in this field"
              description="By checking this, a panelist will not be able to submit his results without entering some text."
              value={formik.values.isRequired}
              onChange={formik.handleChange}
            />

            <PreviewBox>
              <FormField
                label={`${formik.values.name || 'Parameter name'} ${
                  formik.values.isRequired ? '' : '(optional)'
                }`}
              >
                <TextArea
                  className={styles.textArea}
                  name="preview-options"
                  isDisabled
                  placeholder="Write your text here"
                />
              </FormField>
            </PreviewBox>

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={onCancel}>
                {preloadedState ? 'Cancel' : 'Back'}
              </Button>
              <Button name="save" htmlType="submit">
                {preloadedState ? 'Save' : 'Create'}
              </Button>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CommentFormElementDialogContent;
