import React from 'react';
import { Form, Formik, FormikProps } from 'formik';
import { Button, FormField, Input } from 'ventura';
import * as Yup from 'yup';

import { FormErrors } from 'components/molecules';
import { useAuthentication } from 'services/userService';
import JulesDestrooper from '../../assets/companies/JulesDestrooper.png';
import KULeuven from '../../assets/companies/KULeuven.png';
import MQWI from '../../assets/companies/MQWI.png';
import Shape1 from '../../assets/shape1.svg';
import Shape2 from '../../assets/shape2.svg';

import styles from './Register.module.css';

type FormValues = {
  emailAddress: string;
  firstName: string;
  lastName: string;
  password: string;
};

const formValidationSchema = Yup.object().shape<FormValues>({
  emailAddress: Yup.string().label('email address').email().required(),
  firstName: Yup.string().label('Firstname').required(),
  lastName: Yup.string().label('Lastname').required(),
  password: Yup.string().label('password').required(),
});

const queryParameters = new URLSearchParams(window.location.search);

const defaultFormValues: FormValues = {
  emailAddress: queryParameters.get('email') ?? '',
  firstName: '',
  lastName: '',
  password: '',
};

const Register: React.FC = () => {
  const {
    signUp,
    signInWithGoogle,
    error: firebaseError,
    isLoading: isAuthLoading,
  } = useAuthentication();
  const [method, setMethod] = React.useState<'emailAndPassword' | 'google'>();

  const handleRegister = async (formValues: FormValues) => {
    setMethod('emailAndPassword');
    await signUp(
      formValues.emailAddress,
      formValues.password,
      `${formValues.firstName} ${formValues.lastName}`,
    );
  };

  const handleGoogleSignIn = async () => {
    setMethod('google');
    await signInWithGoogle();
  };

  return (
    <>
      <main className={styles.main}>
        <div className={styles.fold}>
          <div className={styles.leftContent}>
            <h1 className={styles.title}>
              The easiest way to start{' '}
              <span className={styles.titleHighlight}>food quality testing</span>
            </h1>
            <p className={styles.subTitle}>
              We help you perform food sensory tests <b>faster</b>, so you can focus on the results.
            </p>
            <ul className={styles.checkLists}>
              <li>3 types of sensory tests</li>
              <li>Invite participants using a QR code</li>
              <li>Results are automatically analyzed</li>
            </ul>
            <p className={styles.partnersTitle}>Approved by</p>
            <div className={styles.companyLogos}>
              <img className={styles.companyLogo} src={KULeuven} alt="KU Leuven" />
              <img className={styles.companyLogo} src={MQWI} alt="KU Leuven" />
              <img className={styles.companyLogo} src={JulesDestrooper} alt="KU Leuven" />
            </div>
          </div>
          <div className={styles.formContainer}>
            <div className={styles.formTitle}>Get started with Sensly</div>
            <div className={styles.formSubTitle}>
              Enter your contact details to get immediate access to all features. Your information
              may be used to contact you later.
            </div>
            <Formik
              initialValues={defaultFormValues}
              onSubmit={handleRegister}
              validationSchema={formValidationSchema}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                values,
                errors,
                handleChange,
                handleBlur,
                isSubmitting,
              }: FormikProps<FormValues>) => (
                <Form className={styles.form}>
                  <div className={styles.inputFields}>
                    <FormErrors messages={firebaseError ? [firebaseError.message] : []} />
                    <FormField errorMessage={errors.emailAddress}>
                      <Input
                        name="emailAddress"
                        placeholder="Email address"
                        type="email"
                        value={values.emailAddress}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        isInvalid={Boolean(errors.emailAddress)}
                      />
                    </FormField>
                    <div className={styles.flexRow}>
                      <FormField errorMessage={errors.firstName}>
                        <Input
                          name="firstName"
                          placeholder="Firstname"
                          value={values.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(errors.firstName)}
                        />
                      </FormField>
                      <FormField errorMessage={errors.lastName}>
                        <Input
                          name="lastName"
                          placeholder="Lastname"
                          value={values.lastName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(errors.lastName)}
                        />
                      </FormField>
                    </div>
                    <div className={styles.flexRow2}>
                      <FormField errorMessage={errors.password}>
                        <Input
                          name="password"
                          placeholder="Password"
                          type="password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={Boolean(errors.password)}
                        />
                      </FormField>
                      <Button
                        type="secondary"
                        size="large"
                        name="login"
                        isLoading={isSubmitting || (method === 'emailAndPassword' && isAuthLoading)}
                        htmlType="submit"
                      >
                        Continue
                      </Button>
                    </div>
                    <Button
                      size="large"
                      className={styles.googleButton}
                      onClick={handleGoogleSignIn}
                      isLoading={method === 'google' && isAuthLoading}
                    >
                      <img className={styles.googleIcon} src="/googleIcon.png" alt="Google" />
                      Continue with Google
                    </Button>
                  </div>
                </Form>
              )}
            </Formik>
            <img className={styles.shape1} src={Shape1} alt="Background shape" />
            <img className={styles.shape2} src={Shape2} alt="Background shape" />
          </div>
        </div>
      </main>
    </>
  );
};

export default Register;
