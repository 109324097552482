import { average, median, sum } from '../basic';

export const calculateVariance = (values: number[]) => {
  const mean = average(values);
  return sum(values.map((value) => (value - mean) ** 2)) / values.length;
};

export const calculateStandardDeviation = (values: number[]) => {
  const variance = calculateVariance(values);
  return Math.sqrt(variance);
};

export const calculateStandardError = (values: number[]) => {
  return calculateStandardDeviation(values) / Math.sqrt(values.length);
};

export const calculateInterquartileRange = (numbers: number[]) => {
  const sortedNumbers = [...numbers].sort((a, b) => a - b);
  let numbersLeftFromMedian: number[];
  let numbersRightFromMedian: number[];

  if (numbers.length % 2) {
    numbersLeftFromMedian = sortedNumbers.slice(0, Math.floor(sortedNumbers.length / 2));
    numbersRightFromMedian = sortedNumbers.slice(
      Math.floor(sortedNumbers.length / 2) + 1,
      sortedNumbers.length,
    );
  } else {
    numbersLeftFromMedian = sortedNumbers.slice(0, sortedNumbers.length / 2);
    numbersRightFromMedian = sortedNumbers.slice(sortedNumbers.length / 2, sortedNumbers.length);
  }

  const quartile1 = median(numbersLeftFromMedian);
  const quartile3 = median(numbersRightFromMedian);

  return [quartile1, quartile3];
};

export const createProbabilityString = (probability: number) => {
  return probability.toFixed(3);
};
