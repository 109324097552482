import React from 'react';
import { SensoryTest } from '@bloodhound/common/dist/models/sensoryTest';
import {
  QdaTestParticipant,
  SensoryTestParticipant,
  TriangleTestParticipant,
  TwoFiveTestParticipant,
} from '@bloodhound/common/dist/models/sensoryTestParticipant';

import { isQdaTest, isTriangleTest, isTwoFiveTest } from 'utils/typeGuards';
import { TriangleTestParticipantForm } from '..';
import QdaTestParticipantForm from '../QdaTestParticipantForm/QdaTestParticipantForm';
import TwoFiveTestParticipantForm from '../TwoFiveTestParticipantForm/TwoFiveTestParticipantForm';

interface Props {
  sensoryTest: SensoryTest;
  participant: SensoryTestParticipant;
  onSubmit: (participant: SensoryTestParticipant) => Promise<boolean>;
  error?: string;
}

const SensoryTestParticipantForm: React.FC<Props> = ({
  onSubmit,
  participant,
  error,
  sensoryTest,
}) => {
  return (
    <>
      {isTriangleTest(sensoryTest) && (
        <TriangleTestParticipantForm
          error={error}
          onSubmit={onSubmit}
          participant={participant as TriangleTestParticipant}
          instructions={sensoryTest.instructions}
          settings={sensoryTest.settings}
        />
      )}
      {isTwoFiveTest(sensoryTest) && (
        <TwoFiveTestParticipantForm
          error={error}
          onSubmit={onSubmit}
          participant={participant as TwoFiveTestParticipant}
          instructions={sensoryTest.instructions}
          settings={sensoryTest.settings}
        />
      )}
      {isQdaTest(sensoryTest) && (
        <QdaTestParticipantForm
          onSubmit={onSubmit}
          participant={participant as QdaTestParticipant}
          instructions={sensoryTest.instructions}
          formElements={sensoryTest.formElements}
          samples={sensoryTest.samples}
          error={error}
        />
      )}
    </>
  );
};

export default SensoryTestParticipantForm;
