import {
  CategoryScaleFormElement,
  CommentFormElement,
  FormElement,
  QdaSample,
} from '@bloodhound/common/dist/models';
import { QdaTestParticipant } from '@bloodhound/common/dist/models/sensoryTestParticipant';

import { BarChart } from 'components/molecules/CategoryScaleResult/CategoryScaleResult';
import { CommentResultType } from 'components/molecules/CommentResult/CommentResult';
import { BoxPlot } from 'components/molecules/LineScaleResult/LineScaleResult';
import { isPageFormElement } from 'utils/typeGuards';

interface Page {
  id?: string;
  name?: string;
  formElements: FormElement[];
}

export const getFormElementsByPage = (formElements: FormElement[]): Page[] => {
  const pages = formElements.reduce<Page[]>((accumulator, formElement) => {
    if (isPageFormElement(formElement)) {
      return [
        ...accumulator,
        {
          id: formElement.id,
          name: formElement.name,
          formElements: [],
        },
      ];
    }

    if (accumulator.length === 0) {
      // a parameter is above the first page form element element
      return accumulator;
    }
    accumulator[accumulator.length - 1].formElements.push(formElement);

    return accumulator;
  }, []);

  const pagesWithFormElements = pages.filter((page) => page.formElements.length > 0);

  if (pagesWithFormElements.length === 0) {
    return [
      {
        formElements,
      },
    ];
  }

  return pagesWithFormElements;
};

export const createBoxPlotData = (
  formElementId: string,
  samples: QdaSample[],
  participants: QdaTestParticipant[],
): BoxPlot[] => {
  const boxPlotDataArray = samples.map((sample, index) => ({
    sampleId: sample.id,
    sampleName: sample.name,
    colorIndex: index,
    values: [],
  }));

  participants.forEach((participant) => {
    participant.sampleResults.forEach((sampleResult) => {
      if (!sampleResult.isCompleted) {
        return;
      }

      const result = sampleResult.formElementResults.find(
        (formElementResult) => formElementResult.formElementId === formElementId,
      );

      if (result && (result.value || result.value === 0)) {
        const boxPlotData = boxPlotDataArray.find(
          ({ sampleId }) => sampleId === sampleResult.sampleId,
        ) as BoxPlot;
        boxPlotData.values.push(result.value as number);
      }
    });
  });

  return boxPlotDataArray;
};

export const createBarChartData = (
  formElement: CategoryScaleFormElement,
  samples: QdaSample[],
  participants: QdaTestParticipant[],
): BarChart[] => {
  const barCharts = samples.map((sample) => ({
    sampleId: sample.id,
    sampleName: sample.name,
    bars: formElement.options.map((option) => ({
      name: option,
      count: 0,
    })),
  }));

  participants.forEach((participant) => {
    participant.sampleResults.forEach((sampleResult) => {
      if (!sampleResult.isCompleted) {
        return;
      }

      const result = sampleResult.formElementResults.find(
        (formElementResult) => formElementResult.formElementId === formElement.id,
      );

      if (result && result.value) {
        const barChart = barCharts.find(
          ({ sampleId }) => sampleId === Number(sampleResult.sampleId),
        )!;
        const bar = barChart.bars.find(({ name }) => name === result.value)!;
        bar.count += 1;
      }
    });
  });

  return barCharts;
};

export const createCommentResultData = (
  formElement: CommentFormElement,
  participants: QdaTestParticipant[],
): CommentResultType[] => {
  const commentResults: CommentResultType[] = [];

  participants.forEach((participant) => {
    participant.sampleResults.forEach((sampleResult) => {
      if (!sampleResult.isCompleted) {
        return;
      }

      const result = sampleResult.formElementResults.find(
        (formElementResult) => formElementResult.formElementId === formElement.id,
      );

      if (result && result.value) {
        commentResults.push({
          participantId: participant.id,
          sampleId: sampleResult.sampleId,
          value: result.value as string,
        });
      }
    });
  });

  return commentResults.sort((commentA, commentB) =>
    commentA.participantId > commentB.participantId ? 1 : -1,
  );
};
