/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { useAuthentication } from 'services/userService';
import PageLoader from '../PageLoader/PageLoader';

type Props = RouteProps & {
  component: React.ComponentType;
};

const RestrictedRoute: React.FC<Props> = ({ component: Component, ...otherRouteProps }: Props) => {
  const { user, isLoading } = useAuthentication();

  return (
    <Route
      {...otherRouteProps}
      render={(props) => {
        if (isLoading) return <PageLoader />;
        if (!user) return <Component {...props} />;
        return <Redirect to="/" />;
      }}
    />
  );
};

export default RestrictedRoute;
