import React from 'react';
import { QdaSample } from '@bloodhound/common/dist/models/sensoryTest';
import { Button, CheckCircle } from 'ventura';

import styles from './SampleSelector.module.css';

export interface SampleResultStatusMap {
  [sampleId: string]: {
    isCompleted: boolean;
    progressRatio: number;
  };
}

type Props = {
  samples: QdaSample[];
  sampleResultStatusMap: SampleResultStatusMap;
  onChange: (sample: QdaSample) => void;
  className?: string;
};

const SampleSelector: React.FC<Props> = ({
  samples,
  sampleResultStatusMap,
  onChange,
  className,
}: Props) => {
  return (
    <div className={className}>
      {samples.map((sample) => (
        <div key={sample.id} className={styles.sampleOption}>
          <span className={styles.sampleTitle}>Sample {sample.id}</span>
          <div className={styles.horizontalSpacer} />
          {sampleResultStatusMap[sample.id]?.isCompleted ? (
            <>
              <span className={styles.sampleResultStatusCompleted}>
                <CheckCircle /> Completed
              </span>
              <Button name={`start-sample-${sample.id}`} onClick={() => onChange(sample)}>
                View
              </Button>
            </>
          ) : (
            <Button name={`start-sample-${sample.id}`} onClick={() => onChange(sample)}>
              {sampleResultStatusMap[sample.id] ? 'Continue' : 'Start'}
            </Button>
          )}
        </div>
      ))}
    </div>
  );
};

export default SampleSelector;
