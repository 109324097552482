import React from 'react';
import clsx from 'clsx';

import { Navigation } from 'components/organisms';
import Main from './PageLayoutMain/PageLayoutMain';
import Sidebar from './PageLayoutSidebar/PageLayoutSidebar';

import styles from './PageLayout.module.css';

interface Props {
  children: React.ReactNode;
}

const PageLayout: React.FC<Props> & { Main: typeof Main; Sidebar: typeof Sidebar } = ({
  children,
}: Props) => {
  const allClassNames = clsx({
    [styles.content]: true,
    [styles.hasSidebar]: false,
  });

  return (
    <div className={styles.screen}>
      <Navigation />
      <div className={styles.page}>
        <div className={allClassNames}>{children}</div>
      </div>
    </div>
  );
};

PageLayout.Main = Main;
PageLayout.Sidebar = Sidebar;

export default PageLayout;
