import React, { Fragment, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from 'react-beautiful-dnd';
import {
  CategoryScaleFormElement,
  CommentFormElement,
  FormElement,
  FormElementType,
  LineScaleFormElement,
  PageFormElement,
} from '@bloodhound/common/dist/models/formElement';
import clsx from 'clsx';
import * as uuid from 'uuid';
import { Alert, Button, Eye, Modal, PlusCircle, Radio, Slider, TextArea } from 'ventura';

import qdaGraphImage from 'assets/qdaGraph.svg';
import { Placeholder } from 'components/atoms';
import { EditableFormElement, FormErrors, FormNavigation, TabHeader } from 'components/molecules';
import { getBaseUrl } from 'utils/functions';
import { isPageFormElement } from 'utils/typeGuards';
import CategoryScaleFormElementDialogContent, {
  CategoryScaleFormElementPreloadedState,
} from '../CategoryScaleFormElementDialogContent/CategoryScaleFormElementDialogContent';
import CommentFormElementDialogContent, {
  CommentFormElementPreloadedState,
} from '../CommentFormElementDialogContent/CommentFormElementDialogContent';
import FormElementCreationDialog, {
  FormElementCreationData,
} from '../FormElementCreationDialog/FormElementCreationDialog';
import LineScaleFormElementDialogContent, {
  LineScaleFormElementPreloadedState,
} from '../LineScaleFormElementDialogContent/LineScaleFormElementDialogContent';
import PageFormElementDialogContent, {
  PageFormElementPreloadedState,
} from '../PageFormElementDialogContent/PageFormElementDialogContent';
import {
  convertCategoryScaleFormElementToPreloadedState,
  convertCategoryScalePreloadedStateToFormElement,
  convertCommentFormElementToPreloadedState,
  convertCommentPreloadedStateToFormElement,
  convertLineScaleFormElementToPreloadedState,
  convertLineScalePreloadedStateToFormElement,
  convertPageFormElementToPreloadedState,
  convertPagePreloadedStateToFormElement,
  getFormElementDisplayType,
} from './helper';

import styles from './FormComposer.module.css';

interface Props {
  formElements: FormElement[];
  isEditLimited: boolean;
  onEdit: (formElements: FormElement[]) => void;
  errors?: string[];
  sensoryTestId: string;
}

const FormComposer: React.FC<Props> = ({
  formElements,
  isEditLimited,
  onEdit: saveFormElements,
  errors,
  sensoryTestId,
}: Props) => {
  const [formElementForEdit, setFormElementForEdit] = useState<FormElement>();
  const [insertIndex, setInsertIndex] = useState<number>();
  const [pageIndex, setPageIndex] = useState<number>(0);
  const formLink = `${getBaseUrl()}/test/${sensoryTestId}`;
  const pageFormElements = formElements.filter(isPageFormElement);

  const addFormElement = (creationData: FormElementCreationData, type: FormElementType) => {
    const id = uuid.v4();

    let updatedFormElements = [...formElements];
    if (type === 'line') {
      const formElement = convertLineScalePreloadedStateToFormElement(
        creationData as LineScaleFormElementPreloadedState,
        id,
      );
      updatedFormElements.splice(insertIndex as number, 0, formElement);
    } else if (type === 'category') {
      const formElement = convertCategoryScalePreloadedStateToFormElement(
        creationData as CategoryScaleFormElementPreloadedState,
        id,
      );
      updatedFormElements.splice(insertIndex as number, 0, formElement);
    } else if (type === 'comment') {
      const formElement = convertCommentPreloadedStateToFormElement(
        creationData as CommentFormElementPreloadedState,
        id,
      );
      updatedFormElements.splice(insertIndex as number, 0, formElement);
    } else {
      const formElement = convertPagePreloadedStateToFormElement(
        creationData as PageFormElementPreloadedState,
        id,
      );
      if (pageFormElements.length === 0 && insertIndex !== 0) {
        updatedFormElements = [
          {
            id: uuid.v4(),
            name: 'Page 1',
            type: 'page',
          },
          ...formElements,
        ];
        updatedFormElements.splice((insertIndex as number) + 1, 0, formElement);
      } else {
        updatedFormElements.splice(insertIndex as number, 0, formElement);
      }
    }

    saveFormElements(updatedFormElements);
    setInsertIndex(undefined);
  };

  const updateFormElement = (createFormElement: (id: string) => FormElement) => {
    const { id } = formElementForEdit as FormElement;
    const updatedFormElement = createFormElement(id);

    const index = formElements.findIndex((p) => p.id === id);
    const formElementsCopy = [...formElements];
    formElementsCopy.splice(index, 1, updatedFormElement);
    saveFormElements(formElementsCopy);

    setFormElementForEdit(undefined);
  };

  const deleteFormElement = (formElement: FormElement) => {
    saveFormElements(formElements.filter((p) => formElement.id !== p.id));
  };

  const onDragEnd = ({ source, destination }: DropResult) => {
    // Element is dragged out of droppable zone
    if (!destination) return;

    const arrayCopy = [...formElements];
    const [reorderedItem] = arrayCopy.splice(source.index, 1);

    if (
      destination.index === 0 &&
      !isPageFormElement(reorderedItem) &&
      isPageFormElement(formElements[0])
    ) {
      arrayCopy.splice(1, 0, reorderedItem); // parameter form elements should always reside be under a page
    } else {
      arrayCopy.splice(destination.index, 0, reorderedItem);
    }

    saveFormElements(arrayCopy);
  };

  const startEdit = (formElement: FormElement) => {
    if (isPageFormElement(formElement)) {
      const pageIndexForEdit = pageFormElements.findIndex(
        (pageFormElement) => pageFormElement.id === formElement.id,
      );
      setPageIndex(pageIndexForEdit);
    }
    setFormElementForEdit(formElement);
  };

  const startInsert = (index: number) => {
    setInsertIndex(index);

    // Only useful for page form elements
    const formElementsUntilInsertIndex = formElements.slice(0, index);
    formElementsUntilInsertIndex.reverse();
    const previousPageFormElement = formElementsUntilInsertIndex.find(isPageFormElement);
    const pageIndexOnInsert = previousPageFormElement
      ? pageFormElements.indexOf(previousPageFormElement) + 1
      : 0;
    setPageIndex(pageIndexOnInsert);
  };

  return (
    <>
      <TabHeader
        title="Compose your form"
        description="Define the parameters that can differentiate between the samples. Panelists will score each sample on all parameters."
      >
        <a href={formLink} className={styles.formLink} target="_blank" rel="noreferrer">
          <Button prefixIcon={<Eye />}>View as panelist</Button>
        </a>
      </TabHeader>

      <div className={styles.alertContainer}>
        {isEditLimited && (
          <Alert
            intent="warning"
            message="Editing is limited because some panelists already have submitted their answers."
            className={styles.editWarning}
          />
        )}
        <FormErrors messages={errors ?? []} />
      </div>
      <div className={styles.content}>
        {formElements.length === 0 && (
          <Placeholder
            className={styles.placeholder}
            image={qdaGraphImage}
            subTitle="This test has no form elements yet"
          />
        )}
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="formElements">
            {({ innerRef, placeholder, droppableProps }) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <ul className={styles.list} {...droppableProps} ref={innerRef}>
                {formElements.map((formElement, index) => (
                  <Fragment key={formElement.id}>
                    {isEditLimited ? (
                      <div className={styles.elementSpacing} />
                    ) : (
                      <div className={styles.insertFormElement}>
                        <div className={styles.separator} />
                        <div className={styles.actions}>
                          <Button
                            onClick={() => startInsert(index)}
                            prefixIcon={<PlusCircle />}
                            type="secondary"
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    )}
                    <EditableFormElement
                      id={formElement.id}
                      index={index}
                      name={isPageFormElement(formElement) ? undefined : formElement.name}
                      type={getFormElementDisplayType(formElement.type)}
                      onEdit={() => startEdit(formElement)}
                      onRemove={
                        !isPageFormElement(formElement) ||
                        !(
                          isPageFormElement(formElement) &&
                          index === 0 &&
                          pageFormElements.length !== 1
                        )
                          ? () => deleteFormElement(formElement)
                          : undefined
                      }
                      isDragDisabled={isPageFormElement(formElement) && index === 0}
                      className={clsx(styles.item, {
                        [styles.pageItem]: isPageFormElement(formElement),
                      })}
                    >
                      {formElement.type === 'line' && (
                        <Slider
                          value={(formElement as LineScaleFormElement).valueBoundaries.default}
                          onChange={() => {}}
                          valueBoundaries={[
                            (formElement as LineScaleFormElement).valueBoundaries.minimum,
                            (formElement as LineScaleFormElement).valueBoundaries.maximum,
                          ]}
                          textBoundaries={[
                            (formElement as LineScaleFormElement).textBoundaries?.minimum ?? '',
                            (formElement as LineScaleFormElement).textBoundaries?.maximum ?? '',
                          ]}
                          isDisabled
                          className={styles.slider}
                        />
                      )}
                      {formElement.type === 'category' && (
                        <Radio.Group
                          name={`formElement-radioGroup-${formElement.id}`}
                          className={styles.radioGroup}
                        >
                          {(formElement as CategoryScaleFormElement).options.map((option) => (
                            <Radio.Item
                              key={option}
                              label={option}
                              value={option}
                              className={styles.radioItem}
                              isDisabled
                            />
                          ))}
                        </Radio.Group>
                      )}
                      {formElement.type === 'comment' && (
                        <TextArea
                          name={`formElement-comment-${formElement.id}`}
                          placeholder="Write your text here"
                          isDisabled
                          className={styles.commentInput}
                        />
                      )}
                      {isPageFormElement(formElement) && (
                        <FormNavigation
                          currentPageIndex={pageFormElements.findIndex(
                            (pageFormElement) => pageFormElement.id === formElement.id,
                          )}
                          pageCount={pageFormElements.length}
                          title={formElement.name}
                        />
                      )}
                    </EditableFormElement>
                    {isEditLimited ? (
                      <div className={styles.elementSpacing} />
                    ) : (
                      index + 1 === formElements.length && (
                        <div className={styles.insertFormElement}>
                          <div className={styles.separator} />
                          <div className={styles.actions}>
                            <Button
                              onClick={() => startInsert(index + 1)}
                              prefixIcon={<PlusCircle />}
                              type="secondary"
                            >
                              Add
                            </Button>
                          </div>
                        </div>
                      )
                    )}
                  </Fragment>
                ))}
                {placeholder}
              </ul>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Button
        prefixIcon={<PlusCircle />}
        isDisabled={isEditLimited}
        onClick={() => startInsert(formElements.length)}
        className={styles.addFormElementButton}
      >
        Add form element
      </Button>

      <FormElementCreationDialog
        pageIndex={pageIndex}
        pageCount={pageFormElements.length + 1}
        isOpen={insertIndex !== undefined}
        onClose={() => setInsertIndex(undefined)}
        onSubmit={addFormElement}
        showOnlyLayoutFormElements={
          insertIndex === 0 && formElements.length > 0 && isPageFormElement(formElements[0])
        }
      />
      <Modal
        isOpen={formElementForEdit && formElementForEdit.type === 'line'}
        title="Edit line scale parameter"
      >
        <LineScaleFormElementDialogContent
          preloadedState={
            formElementForEdit && formElementForEdit.type === 'line'
              ? convertLineScaleFormElementToPreloadedState(
                  formElementForEdit as LineScaleFormElement,
                )
              : undefined
          }
          onCancel={() => setFormElementForEdit(undefined)}
          onSubmit={(state) =>
            updateFormElement(
              (id: string): LineScaleFormElement =>
                convertLineScalePreloadedStateToFormElement(state, id),
            )
          }
          isEditLimited={isEditLimited}
        />
      </Modal>
      <Modal
        isOpen={formElementForEdit && formElementForEdit.type === 'category'}
        title="Edit category scale parameter"
      >
        <CategoryScaleFormElementDialogContent
          preloadedState={
            formElementForEdit && formElementForEdit.type === 'category'
              ? convertCategoryScaleFormElementToPreloadedState(
                  formElementForEdit as CategoryScaleFormElement,
                )
              : undefined
          }
          onCancel={() => setFormElementForEdit(undefined)}
          onSubmit={(state) =>
            updateFormElement(
              (id: string): CategoryScaleFormElement =>
                convertCategoryScalePreloadedStateToFormElement(state, id),
            )
          }
          isEditLimited={isEditLimited}
        />
      </Modal>
      <Modal
        isOpen={formElementForEdit && formElementForEdit.type === 'comment'}
        title="Edit comment field"
      >
        <CommentFormElementDialogContent
          preloadedState={
            formElementForEdit && formElementForEdit.type === 'comment'
              ? convertCommentFormElementToPreloadedState(formElementForEdit as CommentFormElement)
              : undefined
          }
          onCancel={() => setFormElementForEdit(undefined)}
          onSubmit={(state) =>
            updateFormElement(
              (id: string): CommentFormElement =>
                convertCommentPreloadedStateToFormElement(state, id),
            )
          }
        />
      </Modal>
      <Modal
        isOpen={
          formElementForEdit && pageIndex !== undefined && isPageFormElement(formElementForEdit)
        }
        title="Edit page"
      >
        <PageFormElementDialogContent
          currentPageIndex={pageIndex}
          pageCount={pageFormElements.length}
          preloadedState={
            formElementForEdit && isPageFormElement(formElementForEdit)
              ? convertPageFormElementToPreloadedState(formElementForEdit as PageFormElement)
              : undefined
          }
          onCancel={() => setFormElementForEdit(undefined)}
          onSubmit={(state) =>
            updateFormElement(
              (id: string): PageFormElement => convertPagePreloadedStateToFormElement(state, id),
            )
          }
        />
      </Modal>
    </>
  );
};

export default FormComposer;
