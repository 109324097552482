import React, { ReactNode } from 'react';
import clsx from 'clsx';

import styles from './PageLayoutSidebar.module.css';
type Props = {
  className?: string;
  children?: ReactNode;
};

const PageLayoutSidebar: React.FC<Props> = ({ className, children }: Props) => {
  const allClassNames = clsx(styles.sidebar, className);
  return <section className={allClassNames}>{children}</section>;
};

export default PageLayoutSidebar;
