import { QdaSample } from '@bloodhound/common/dist/models/sensoryTest';

export const generateRandomInteger = (
  minPossibleValue: number,
  maxPossibleValue: number,
): number => {
  return Math.floor(Math.random() * (maxPossibleValue - minPossibleValue + 1)) + minPossibleValue;
};

export const generateAmountOfSampleIds = (amount: number): number[] => {
  const digitArray = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const createdSampleIds: number[] = [];

  for (let i = 0; i < amount; i += 1) {
    const selectedDigits: number[] = [];

    while (selectedDigits.length < 3) {
      const digitIndex = generateRandomInteger(0, digitArray.length - 1);
      const selectedDigit = digitArray[digitIndex];

      const isNotFirstDigit = selectedDigits.length !== 0;
      const isSelectedDigitNotZero = selectedDigit !== 0;
      const isFirstDigitNotUsedInOtherSampleId = !createdSampleIds.some((sampleId) => {
        const sampleIdFirstDigit = Number(sampleId.toString().substring(0, 1));

        return sampleIdFirstDigit === selectedDigit;
      });
      const isDigitNotAlreadySelected = !selectedDigits.includes(selectedDigit);

      if (
        (isNotFirstDigit || (isSelectedDigitNotZero && isFirstDigitNotUsedInOtherSampleId)) &&
        isDigitNotAlreadySelected
      ) {
        selectedDigits.push(selectedDigit);
      }
    }

    const [hundred, ten, one] = selectedDigits;

    const generatedSampleId = hundred * 100 + ten * 10 + one;

    createdSampleIds.push(generatedSampleId);
  }

  return createdSampleIds;
};

export const generateUniqueQdaSampleId = (existingSamples: QdaSample[]): number => {
  const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const availableStartDigits =
    existingSamples.length > 0
      ? digits.filter((digit) => {
          return !existingSamples.some((sample) => {
            return Math.floor(sample.id / 100) === digit;
          });
        })
      : digits;

  const selectedDigits: number[] = [];

  while (selectedDigits.length < 3) {
    let selectedDigit: number;
    if (selectedDigits.length === 0) {
      const digitIndex = generateRandomInteger(0, availableStartDigits.length - 1);
      selectedDigit = availableStartDigits[digitIndex];
    } else {
      const digitIndex = generateRandomInteger(0, digits.length - 1);
      selectedDigit = digits[digitIndex];
    }

    if (selectedDigits.length !== 0 || selectedDigit !== 0) {
      selectedDigits.push(selectedDigit);
      const selectedDigitIndex = digits.indexOf(selectedDigit);
      digits.splice(selectedDigitIndex, 1);
    }
  }

  const [hundred, ten, one] = selectedDigits;

  return hundred * 100 + ten * 10 + one;
};

export const shuffleArray = <T>(array: T[]): T[] => {
  const shuffleCopy = [...array];

  for (let i = shuffleCopy.length - 1; i > 0; i -= 1) {
    const j = generateRandomInteger(0, i);
    const temp = shuffleCopy[i];
    shuffleCopy[i] = shuffleCopy[j];
    shuffleCopy[j] = temp;
  }

  return shuffleCopy;
};
