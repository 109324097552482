import { useEffect, useState } from 'react';
import { FirebaseError, FirestoreError } from '@bloodhound/common/dist/models/firebase';
import { Panelist } from '@bloodhound/common/dist/models/panelist';

import { useFirebase } from 'components/providers/FirebaseProvider';
import { useAuthentication } from './userService';

export function useAllPanelists(): {
  panelists: Panelist[];
  error?: FirebaseError;
  isLoading: boolean;
} {
  const [panelists, setPanelists] = useState<Panelist[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<FirebaseError>();
  const firebase = useFirebase();
  const { user, isLoading: isAuthLoading } = useAuthentication();

  useEffect(() => {
    setIsLoading(true);

    if (isAuthLoading) {
      return;
    }

    if (!user) {
      setIsLoading(false);
      setError(new FirebaseError('unauthenticated'));
      return;
    }

    const collection = firebase
      .firestore()
      .collection('panelists')
      .where('workspaceId', '==', user.workspaceId)
      .orderBy('lastName', 'desc')
      .orderBy('firstName', 'desc');

    const subscription = collection.onSnapshot(
      (snapshot) => {
        const fetchedPanelists = snapshot.docs.map(
          (doc) => ({ id: doc.id, ...doc.data() } as Panelist),
        );

        setPanelists(fetchedPanelists);
        setError(undefined);
        setIsLoading(false);
      },
      (e: Error) => {
        const exception = e as FirestoreError;

        setPanelists([]);
        setError(new FirebaseError(exception.code));
        setIsLoading(false);
      },
    );

    return subscription;
  }, [firebase, user, isAuthLoading]);

  return { panelists, isLoading, error };
}

export const usePanelistCreator = (): {
  createPanelist: (panelist: Omit<Panelist, 'id' | 'dateCreated'>) => Promise<string>;
  error?: FirebaseError;
  clearError: () => void;
  isCreatePending: boolean;
} => {
  const [error, setError] = useState<FirebaseError>();
  const [isCreatePending, setIsCreatePending] = useState<boolean>(false);
  const firebase = useFirebase();

  const createPanelist = async (
    panelist: Omit<Panelist, 'id' | 'dateCreated'>,
  ): Promise<string> => {
    setError(undefined);
    setIsCreatePending(true);

    const collection = firebase.firestore().collection('panelists');

    try {
      const document = await collection.add({
        ...panelist,
        dateCreated: new Date(),
        email: panelist.email.toLowerCase(),
      });
      return document.id;
    } catch (exception) {
      setError(new FirebaseError((exception as FirestoreError).code));
      return '';
    } finally {
      setIsCreatePending(false);
    }
  };

  const clearError = () => {
    setError(undefined);
  };

  return { createPanelist, error, isCreatePending, clearError };
};
