import React from 'react';
import { Tag } from '@bloodhound/common/dist/models';
import {
  QdaTest,
  SensoryTest,
  TriangleTest,
  TwoFiveTest,
} from '@bloodhound/common/dist/models/sensoryTest';
import { User } from '@bloodhound/common/dist/models/user';
import { FieldArray, Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { Button, FormField, Input, Modal, XCircle } from 'ventura';
import * as Yup from 'yup';

import {
  FormErrors,
  QdaTestForm,
  SensoryTestTagInput,
  TriangleTestForm,
  triangleTestFormValidationSchema,
  TriangleTestFormValues,
} from 'components/molecules';
import {
  qdaTestFormValidationSchema,
  QdaTestFormValues,
} from 'components/molecules/QdaTestForm/QdaTestForm';
import TwoFiveTestForm, {
  twoFiveTestFormValidationSchema,
  TwoFiveTestFormValues,
} from 'components/molecules/TwoFiveTestForm/TwoFiveTestForm';
import { useSensoryTestEditor } from 'services/sensoryTestService';
import { useAuthentication } from 'services/userService';
import { useWorkspace } from 'services/workspaceService';
import { generateAmountOfSampleIds } from 'utils/random';
import { isQdaTest, isTriangleTest, isTwoFiveTest } from 'utils/typeGuards';
import { TagColorBackground, TagColorValues } from 'utils/workspace';

import styles from './SensoryTestEditDialog.module.css';

type SensoryTestFormValues = Omit<
  SensoryTest,
  'id' | 'workspaceId' | 'type' | 'dateCreated' | 'significanceLevel' | 'tags'
>;
type FormValues = SensoryTestFormValues &
  TriangleTestFormValues &
  QdaTestFormValues &
  TwoFiveTestFormValues;

const getFormValidationSchema = (sensoryTest: SensoryTest) => {
  let schema = Yup.object();

  if (isTriangleTest(sensoryTest)) {
    schema = schema.concat(triangleTestFormValidationSchema);
  } else if (isTwoFiveTest(sensoryTest)) {
    schema = schema.concat(twoFiveTestFormValidationSchema);
  } else if (isQdaTest(sensoryTest)) {
    schema = schema.concat(qdaTestFormValidationSchema);
  }

  schema = schema.shape<SensoryTestFormValues>({
    name: Yup.string().label('Sensory test name').required(),
    instructions: Yup.string<string>().label('Participant instructions'),
    purpose: Yup.string<string>().label('The purpose of this test (optional)'),
  });

  return schema;
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
  sensoryTest: SensoryTest;
}

const SensoryTestEditDialog: React.FC<Props> = ({ isOpen, onClose, sensoryTest }: Props) => {
  const { editSensoryTest, error, clearError } = useSensoryTestEditor();
  const { user } = useAuthentication();
  const { workspace } = useWorkspace(user?.workspaceId);

  const submitTriangleTest = async (formValues: FormValues, triangleTest: TriangleTest) => {
    const updatedTriangleTest: TriangleTest = {
      id: triangleTest.id,
      dateCreated: triangleTest.dateCreated,
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      type: 'triangle',
      sampleNameA: formValues.sampleNameA!,
      sampleCharA: formValues.sampleCharA!,
      sampleNameB: formValues.sampleNameB!,
      sampleCharB: formValues.sampleCharB!,
      significanceLevel: Number(formValues.significanceLevel),
      settings: {
        usesSimplifiedSampleIds: formValues.settings.usesSimplifiedSampleIds,
        isCommentFieldRequired: formValues.settings.isCommentFieldRequired,
      },
      sampleIdsA: triangleTest.sampleIdsA,
      sampleIdsB: triangleTest.sampleIdsB,
      tags: formValues.tags,
    };

    if (
      updatedTriangleTest.settings.usesSimplifiedSampleIds &&
      updatedTriangleTest.sampleIdsA.length === 0 &&
      updatedTriangleTest.sampleIdsB.length === 0
    ) {
      const [sampleId1, sampleId2, sampleId3, sampleId4] = generateAmountOfSampleIds(4);

      updatedTriangleTest.sampleIdsA = [sampleId1, sampleId2];
      updatedTriangleTest.sampleIdsB = [sampleId3, sampleId4];
    }

    return editSensoryTest<TriangleTest>(updatedTriangleTest);
  };

  const submitTwoFiveTest = async (formValues: FormValues, twoFiveTest: TwoFiveTest) => {
    const updatedTwoFiveTest: TwoFiveTest = {
      id: twoFiveTest.id,
      dateCreated: twoFiveTest.dateCreated,
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      type: 'twoFive',
      sampleNameA: formValues.sampleNameA!,
      sampleCharA: formValues.sampleCharA!,
      sampleNameB: formValues.sampleNameB!,
      sampleCharB: formValues.sampleCharB!,
      significanceLevel: Number(formValues.significanceLevel),
      settings: {
        usesSimplifiedSampleIds: formValues.settings.usesSimplifiedSampleIds,
        isCommentFieldRequired: formValues.settings.isCommentFieldRequired,
      },
      sampleIdsA: twoFiveTest.sampleIdsA,
      sampleIdsB: twoFiveTest.sampleIdsB,
      tags: formValues.tags,
    };

    if (
      updatedTwoFiveTest.settings.usesSimplifiedSampleIds &&
      updatedTwoFiveTest.sampleIdsA.length === 0 &&
      updatedTwoFiveTest.sampleIdsB.length === 0
    ) {
      const [
        sampleId1,
        sampleId2,
        sampleId3,
        sampleId4,
        sampleId5,
        sampleId6,
      ] = generateAmountOfSampleIds(6);

      updatedTwoFiveTest.sampleIdsA = [sampleId1, sampleId2, sampleId3];
      updatedTwoFiveTest.sampleIdsB = [sampleId4, sampleId5, sampleId6];
    }

    return editSensoryTest<TwoFiveTest>(updatedTwoFiveTest);
  };

  const submitQdaTest = async (formValues: FormValues, qdaTest: QdaTest) => {
    const updatedQdaTest: QdaTest = {
      id: qdaTest.id,
      dateCreated: qdaTest.dateCreated,
      workspaceId: (user as User).workspaceId,
      name: formValues.name,
      instructions: formValues.instructions,
      purpose: formValues.purpose,
      type: 'qda',
      samples: formValues.samples,
      formElements: qdaTest.formElements,
      significanceLevel: Number(formValues.significanceLevel),
      tags: formValues.tags,
    };

    return editSensoryTest<QdaTest>(updatedQdaTest);
  };

  const handleSubmit = async (formValues: FormValues, formikHelpers: FormikHelpers<FormValues>) => {
    let isSensoryTestUpdated = false;

    if (isTriangleTest(sensoryTest)) {
      isSensoryTestUpdated = await submitTriangleTest(formValues, sensoryTest);
    }

    if (isQdaTest(sensoryTest)) {
      isSensoryTestUpdated = await submitQdaTest(formValues, sensoryTest);
    }

    if (isTwoFiveTest(sensoryTest)) {
      isSensoryTestUpdated = await submitTwoFiveTest(formValues, sensoryTest);
    }

    if (isSensoryTestUpdated) {
      formikHelpers.resetForm();
      onClose();
    }
  };

  const handleCancel = (resetForm: () => void) => {
    resetForm();
    clearError();
    onClose();
  };

  const firebaseErrors = error ? [error.message] : [];

  return (
    <Formik
      onSubmit={handleSubmit}
      initialValues={(sensoryTest as unknown) as FormValues}
      validationSchema={getFormValidationSchema(sensoryTest)}
      validateOnChange={false}
      validateOnBlur={false}
      enableReinitialize
    >
      {(formik: FormikProps<FormValues>) => (
        <Modal isOpen={isOpen} title="Update sensory test">
          <Form>
            <div className={styles.firebaseErrors}>
              <FormErrors messages={firebaseErrors} />
            </div>
            <div className={styles.formGroup}>
              <div>
                <FormField label="Sensory test name" errorMessage={formik.errors.name as string}>
                  <Input
                    name="name"
                    placeholder="Sensory test name"
                    isInvalid={Boolean(formik.errors.name)}
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </FormField>
                <FieldArray
                  name="tags"
                  render={(arrayHelpers) => (
                    <div className={styles.tagsContainer}>
                      {formik.values.tags?.map((tag, index) => (
                        <div
                          key={index}
                          className={styles.tag}
                          style={{
                            backgroundColor: `${TagColorBackground[tag.color]}`,
                            color: TagColorValues[tag.color],
                          }}
                        >
                          <span>{tag.name}</span>
                          <span
                            className={styles.tagAction}
                            onClick={() => arrayHelpers.remove(index)}
                          >
                            <XCircle />
                          </span>
                        </div>
                      ))}
                    </div>
                  )}
                />
              </div>
              <SensoryTestTagInput
                options={workspace?.tags || []}
                onTagSelected={(tag: Tag) => {
                  if (formik.values.tags === undefined) {
                    formik.setFieldValue('tags', [tag]);
                  } else if (!formik.values.tags.some((t) => t.id === tag.id)) {
                    formik.setFieldValue('tags', [...formik.values.tags, tag]);
                  }
                }}
              />
            </div>

            {isTriangleTest(sensoryTest) && (
              <TriangleTestForm
                formik={(formik as unknown) as FormikProps<TriangleTestFormValues>}
              />
            )}

            {isQdaTest(sensoryTest) && (
              <QdaTestForm formik={(formik as unknown) as FormikProps<QdaTestFormValues>} />
            )}

            {isTwoFiveTest(sensoryTest) && (
              <TwoFiveTestForm formik={(formik as unknown) as FormikProps<TwoFiveTestFormValues>} />
            )}

            <Modal.Footer>
              <Button name="cancel" type="secondary" onClick={() => handleCancel(formik.resetForm)}>
                Cancel
              </Button>
              <Button name="edit" isLoading={formik.isSubmitting} htmlType="submit">
                Update
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
    </Formik>
  );
};

export default SensoryTestEditDialog;
